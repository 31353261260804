import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import {
  DocumentDeviceAction,
  DocumentDevicePostAction,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Page } from "react-pdf";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { pdfjs } from "react-pdf";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import pdf from "./agent_document_20221105112030.pdf";
import SignatureCanvas from "react-signature-canvas";
import moment from "moment";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Documents = (props) => {
  //console.log(props.device);
  const [pagesize, setpagesize] = useState(10);
  const [signature, setSignature] = useState(null);
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [docurl, setDocurl] = useState("");
  const sigRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(DocumentDeviceAction());
  }, [dispatch]);
  const useStyles = makeStyles({
    grid: {
      fontFamily: "poppins, sans-serif !important",
    },
  });
  const classes = useStyles();

  const handleclick = (e) => {
    console.log(e.row);
    setDocurl(e.row);
    setOpen(true);
  };

  const handleCloseModal = (e) => {
    setOpen(false);
  };

  const handleClose = () => {
    var formdata = new FormData();
    formdata.append("agent_document_mapping_id", docurl.id);
    formdata.append("signature_image", signature);
    formdata.append("consent", 1);
    formdata.append("signed_date", moment().format("MM-DD-YYYY"));
    // var obj = {
    //   agent_document_mapping_id: docurl.id,
    //   signature_image: formdata,
    //   consent: 1,
    //   signed_date: new Date().toLocaleDateString(),
    // };
    console.log(formdata);
    dispatch(DocumentDevicePostAction(formdata))
      .then((res) => {
        setOpen(false);
        setSignature(null);
      })
      .catch((err) => {});
  };

  const handleSignatureEnd = () => {
    var blobBin = atob(sigRef.current.toDataURL().split(",")[1]);
    var array = [];
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)], { type: "image/png" });
    setSignature(file);
  };
  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };
  const columns = [
    {
      field: "document_name",
      headerName: "Name",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "status",
      headerName: "status",
      width: 200,
      sortable: false,
      headerAlign: "center",
      headerClassName: "bold-text",
      align: "center",
      renderCell: (params) => {
        return (
          <span>
            {params.row.status === 1 ? (
              <Chip label="Pending" color="primary" />
            ) : params.row.status === 2 ? (
              <Chip label="Completed" color="primary" />
            ) : null}
          </span>
        );
      },
    },
    {
      field: "created_date",
      headerName: "Date & Time",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 250,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
      renderCell: (cellValues) => {
        return (
          <span>
            <Link
              to="#"
              onClick={() => handleclick(cellValues)}
              className="btn btn-primary shadow btn-xs  me-1"
            >
              View Document
            </Link>
          </span>
        );
      },
    },
  ];
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                <strong>Compliance Document</strong>
              </h4>
            </div>
            <div className="card-body">
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", height: "100%" }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      sx={{
                        ".MuiDataGrid-columnSeparator": {
                          display: "none",
                        },
                      }}
                      autoHeight
                      disableColumnMenu
                      getRowId={(row) => row.id}
                      rows={props.device}
                      className={classes.grid}
                      columns={columns}
                      pageSize={pagesize}
                      rowsPerPageOptions={[pagesize]}
                      disableSelectionOnClick
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {docurl.document_name}
            </Typography>
            {docurl.status === 1 ? (
              <Button autoFocus color="inherit" onClick={handleClose}>
                save
              </Button>
            ) : null}
          </Toolbar>
        </AppBar>
        <div className="container-fluid  bg-night">
          <Document
            file={
              docurl.status === 2
                ? docurl.signed_document_url
                : docurl.document_url
            }
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <div className="pdf-actions d-flex mt-3 mb-3">
            <button
              type="button"
              className="btn btn-light btn-sm"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              <i className="fa fa-2x fa-arrow-circle-left"></i>
            </button>
            <p>
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </p>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              className="btn btn-light btn-sm"
            >
              <i className="fa fa-2x fa-arrow-circle-right"></i>
            </button>
          </div>
          {docurl.status === 1 ? (
            <>
              <p className="text-center mt-3">
                Please Draw Your Signature Black Box
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  required
                  value=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label">
                  I have read and understood the terms and conditions
                </label>
              </div>
              <SignatureCanvas
                penColor="black"
                canvasProps={{ className: "signature" }}
                ref={sigRef}
                onEnd={handleSignatureEnd}
              />
              <div className="text-center">
                <button className="btn btn-primary" onClick={clearSignature}>
                  Clear
                </button>
              </div>
              <span className="mt-5">&nbsp;</span>
            </>
          ) : null}
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    device: state.devicelist.documents,
  };
};

export default connect(mapStateToProps)(Documents);
