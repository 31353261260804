import React, { useState, useEffect } from "react";
import {
  GetInventoryOrderlist,
  createinventoryorder,
  Get_manager_office_list,
  GetInventoryLog,
  OrderApprovalAction,
  OrderStatusAction,
  exportInventoryOrderlist,
  getapproveorder,
  gerteditorder,
  resubmit_request_order,
  viewcommentsuserinventoryaction,
} from "../../../store/actions/DeviceActions";
import moment from "moment";
import {short_us_states} from "../../../store/actions/documentActions";
import {
  EyeOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  ShopOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  UploadOutlined,
  EditOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { connect, useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import {
  Table,
  Button,
  Drawer,
  List,
  Space,
  Input,
  Col,
  Form,
  Row,
  Select,
  Tag,
  Tooltip,
  Modal,
  message,
  Empty,
  Spin,
  Divider,
  DatePicker,
} from "antd";
import axiosInstance from "../../../services/AxiosAdmin";

const { Option } = Select;
const { confirm } = Modal;

const OrderInventory = (props) => {
  //console.log(props.device);
  const [form] = Form.useForm();
  const [Editform] = Form.useForm();
  const [Addform] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isapprove, Setisapprove] = useState(false);
  const [record, Setrecord] = useState(false);
  const [aopen, setAopen] = useState(false);
  const [resubmitopen, setResubmitopen] = useState(false);
  const [data, setData] = useState([]);
  const [Listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState({pageSize: 25});
  const [sortField, setsortField] = useState(null);
  const [sortOrder, setsortOrder] = useState(null);
  const [selectedapprovalstatus, setselectedapprovalstatus] = useState(null);
  const [selectedorderstatus, setselectedorderstatus] = useState(null);
  const [selectedoffice, setselectedoffice] = useState(null);
  const [search_key, setsearch_key] = useState(null);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [selectedItems, setselactedItems] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fetching, setfetching] = useState(false);
  const [managerview, setmanagerview] = useState(false);
  const [inventorymanagerview, setInventorymanagerview] = useState(false);
  const [iboinventorymanager, setIBOInventorymanagerview] = useState(false);
  const dispatch = useDispatch();
  const [cvisible, setcvisible] = useState(false);
  const [Ldata2, setLdata2] = useState([]);
  const [Eopen, setEopen] = useState(false);
  const [shortstate, setshortstate] = useState([]);

  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [endOpen, setEndOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndtDate] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const marketmanager =
      props?.auth?.role_id?.filter((word) => word === 3) ?? [];
    if (marketmanager?.length !== 0) {
      setmanagerview(true);
    }
    const inventorymanager =
      props?.auth?.role_id?.filter((word) => word === 12) ?? [];
    if (inventorymanager?.length !== 0) {
      setInventorymanagerview(true);
    }
    const iboinventorymanager =
      props?.auth?.role_id?.filter((word) => word === 11) ?? [];
    if (iboinventorymanager?.length !== 0) {
      setIBOInventorymanagerview(true);
    }
    fetchData({
      results: 25,
      page: 1,
    });
    dispatch(OrderApprovalAction());
    dispatch(OrderStatusAction());
    dispatch(Get_manager_office_list());
    dispatch(short_us_states()).then((res) => {
      setshortstate(res.data);
    });
    // dispatch(GetInventoryOrderlist())
    //   .then((res) => {
    //     console.log(res);
    //     setData(res.data.data);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //   });
  }, [dispatch]);

  const getRandomuserParams = (params) => ({
    results: 25,
    page: params.pagination?.current,
    ...params,
  });

  const fetchData = (params = {}) => {
    setLoading(true);
    
    dispatch(GetInventoryOrderlist(getRandomuserParams(params)))
     
      .then((res) => {
        console.log(res);
        const paginate = { ...pagination };
        paginate.total = res.data.total;
        paginate.current = res.data.current_page;
        setData(res.data.data);
        setLoading(false);
        setBtnLoading(false);
        console.log(`paginate`, paginate);
        setPagination(paginate);
      })
      .catch((err) => {
        setLoading(false);
        setBtnLoading(false);
      });
  };

  const handleTableChange = (paginate, filter, sorter) => {
    const pager = { ...pagination };
    pager.current = paginate.current;
    const order =
      sorter.order === "ascend"
        ? "ASC"
        : sorter.order === undefined
        ? ""
        : "DESC";
    const fieldName = sorter.order === undefined ? "" : sorter.field;
    setPagination(pager);
    setsortField(fieldName);
    setsortOrder(order);
    searchOrders(fieldName, order, pager);
  };

  const searchOrders = (sortName, order, pager, reset) => {
    setLoading(true);
    setBtnLoading(true);
    var approvalstatus = selectedapprovalstatus;
    var orderstatus = selectedorderstatus;
    var office_id = selectedItems.key;
    var search_text = search_key;
    var sort = sortName ?? sortField;
    var sort_by = order ?? sortOrder;
    var date_range_start = startDate;
    var date_range_end = endDate;
   
    var page = reset === "reset" ? 1 : pager?.current ?? pagination?.current ?? 1;
    fetchData({
      results: 25,
      page: page ?? 1,
      office_id: office_id,
      approval_status: approvalstatus,
      status: orderstatus,
      search_text: search_text,
      sort: sort,
      sort_by: sort_by,
      date_range_start,
      date_range_end,
    });
  };

  const clearSearch = () => {
    setLoading(true);
    setselectedapprovalstatus(undefined);
    setselectedorderstatus(undefined);
    setselectedoffice(undefined);
    setsearch_key(null);
    setsortField(null);
    setsortOrder(null);
    setfdata([]);
    setselactedItems([]);
    setStartValue(null);
    setEndValue(null);
    setStartDate(null);
    setEndtDate(null);
    fetchData({
      results: 25,
      page: 1,
    });
  };

  const onchangeapprovalfilter = (selectedapprovalstatus) => {
    setselectedapprovalstatus(selectedapprovalstatus);
  };

  const onchangeorderfilter = (selectedorderstatus) => {
    setselectedorderstatus(selectedorderstatus);
  };

  const onchangeoffice = (selectedoffice) => {
    setselectedoffice(selectedoffice);
  };

  // order date filter
  const disabledStartDate = (startValue) => {
    // if (!startValue || !endValue) {
    //   return false;
    // }
    return startValue && startValue > moment().endOf("day");
  };
const disabledEndDate = (endValue) => {
    // if (!endValue || !startValue) {
    //   return false;
    // }
    // return endValue.valueOf() <= startValue.valueOf();
    return endValue && endValue > moment().endOf("day");
  };

 const onStartChange = (value, date) => {
    console.log(value);
    setStartValue(value);
    setStartDate(date);
  };

 const onEndChange = (value, date) => {
    setEndValue(value);
    setEndtDate(date);
  };

 const handleStartOpenChange = (open) => {
    if (!open) {
      setEndOpen(true);
    }
  };
 const handleEndOpenChange = (open) => {
    setEndOpen(open);
  };
  const renderOffice = () => {
    return _.map(props.otheroffice, (dep, index) => {
      // console.log(`dep`, dep)
      // dep.map((genderData) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.office_number}
        </Option>
      );
      // });
    });
  };

  const renderorderapproval = () => {
    return _.map(props.orderapprovalstatus, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };

  const renderorderstatus = () => {
    return _.map(props.orderstatus, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };
 const renderStates = () => {
    return _.map(shortstate, (master, index) => {
      return (
        <Option value={master.short_name} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = (params) => {
    console.log(params.id);
    setLoading(true);
    dispatch(viewcommentsuserinventoryaction(params.id))
      .then((res) => {
        const resp = res.data.items;
        console.log(res.data);
        setLdata2(resp);
        setIsModalOpen(true);
        // message.success(res.data.message);
        setcvisible(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: "id",
      key: "id",
      sorter: true,
      width: 80,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (text, record) => (
        <span>
            {record.status === 10 &&  <Tag color="orange"> {record.status_text}</Tag>}
            {record.status === 9 &&  <Tag color="orange"> {record.status_text}</Tag>}
            {record.status === 1 &&  <Tag color="orange"> {record.status_text}</Tag>}
            {record.status === 6 &&  <Tag color="#87d068"> {record.status_text}</Tag>}
            {record.status === 8 &&  <Tag color="green">Admin</Tag>}
            {record.status === 2 &&  <Tag color="geekblue">IBO Manager</Tag>}
            {record.status === 4 &&  <Tag color="blue">Retailer</Tag>}
            {record.status === 7 &&  <Tag color="volcano">Admin</Tag>}
            {record.status === 3 &&  <Tag color="volcano">IBO Manager</Tag>}
            {record.status === 5 &&  <Tag color="volcano">Retailer</Tag>}
          </span>
        // <>
        //   <Tag
        //     color={
        //       record?.status === null ||
        //       record?.status === undefined ||
        //       record?.status === 3
        //         ? "red"
        //         : record?.status === 1
        //         ? "orange"
        //         : record?.status === 2
        //         ? "green"
        //         : record?.status === 4 || record?.status === 5
        //         ? "geekblue"
        //         : "grey"
        //     }
        //   >
        //     {record.status_text}
        //   </Tag>
        // </>
      ),
    },
    {
      title: "IBOs / Markets",
      dataIndex: "ibo_marketer_name",
      key: "ibo_marketer_name",
      width: 150,
      sorter: true,
      hidden: inventorymanagerview === true ? false : true,
    },
    {
      title: "Office Name",
      dataIndex: "office_number",
      key: "office_number",
      sorter: true,
      width: 150,
    },
    
 
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      sorter: true,
    },
    {
      title: "Delivery Type",
      dataIndex: "method_of_delivery_text",
      key: "method_of_delivery_text",
      width: 150,
      sorter: true,
    },
    {
      title: "Tracking Number",
      dataIndex: "tracking_number",
      key: "tracking_number",
      width: 150,
    },
    {
      title: "Comments",
      dataIndex: "admin_comments",
      key: "admin_comments",
      width: 150,
      sorter: true,
    },
    {
      title: "Street Address",
      dataIndex: "address",
      key: "address",
      width: 150,
    },
    {
      title: "Address 2",
      dataIndex: "address2",
      key: "address2",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 150,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 150,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 150,
    },
    {
      title: "Zip code",
      dataIndex: "zip",
      key: "zip",
      width: 150,
    },
    {
      title: "contact phone number ",
      dataIndex: "contact_phone_number",
      key: "contact_phone_number",
      width: 150,
    },
    // {
    //   title: "Approval Status",
    //   dataIndex: "approval_status_text",
    //   key: "approval_status_text",
    //   width: 250,
    //   render: (text, record) => (
    //     <>
    //       <Tag
    //         color={
    //           record?.approval_status === null ||
    //           record?.approval_status === undefined ||
    //           record?.approval_status === 3 ||
    //           record?.approval_status === 5 ||
    //           record?.approval_status === 7
    //             ? "red"
    //             : record?.approval_status === 1
    //             ? "orange"
    //             : record?.approval_status === 6
    //             ? "darkgreen"
    //             : record?.approval_status === 2
    //             ? "lightgreen"
    //             : record?.approval_status === 4
    //             ? "green"
    //             : "geekblue"
    //         }
    //       >
    //         {" "}
    //         {record?.approval_status_text ?? ""}
    //       </Tag>
    //     </>
    //   ),
    // },
   
    // {
    //   title: "Comments Action",
    //   dataIndex: "id",
    //   key: "id",
    //   align: "center",
    //   width: 150,
    //   render: (_, record) => (
    //     <span>
    //       <Button
    //         type="primary"
    //         size="small"
    //         style={{ fontSize: 11 }}
    //         onClick={() => showModal(record)}
    //       >
    //         View Comments
    //       </Button>
    //     </span>
    //   ),
    // },
    
    {
      title: "# Of Tablets Requested",
      dataIndex: "tablets_requested",
      key: "tablets_requested",
      width: 150,
      sorter: true,
    },
    {
      title: "# Of Phones Requested",
      dataIndex: "devices_requested",
      key: "devices_requested",
      width: 150,
      sorter: true,
    },
    {
      title: "# Of Tablets approved",
      dataIndex: "tablets_approved",
      key: "tablets_approved",
      width: 150,
      sorter: true,
    },
    {
      title: "# Of Phones approved",
      dataIndex: "devices_approved",
      key: "devices_approved",
      width: 150,
      sorter: true,
    },
    
    {
      title: "Action",
      key: "status_text",
      width: 270,
      fixed: "right",
      render: (text, record_item) => (
        <span>
           {record_item.comments_exists === 0 ? (
            <>
          <Tooltip title="View Comments">
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
             disabled
            ></Button>
          </Tooltip>
          <Divider type="vertical" />
          </>
           ):( <>
            <Tooltip title="View Comments">
              <Button
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => showModal(record_item)}
              ></Button>
            </Tooltip>
            <Divider type="vertical" />
            </>)}
          <Tooltip title="View log">
            <Button
              type="primary"
              shape="circle"
              icon={<ClockCircleOutlined />}
              onClick={() => rendercanlog(record_item.id)}
              // type="primary"
            ></Button>
          </Tooltip>
          {managerview !== true ? (
            <>
              <Divider type="vertical" />
              <Tooltip title="Approve Order">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<ShopOutlined />}
                  disabled={
                    !(
                      (record_item?.status === undefined ||
                        record_item?.status === null ||
                        record_item?.status < 5) &&
                      ((inventorymanagerview &&
                        (record_item?.approval_status === null ||
                          record_item?.approval_status === undefined ||
                          record_item?.approval_status < 3)) ||
                        (iboinventorymanager &&
                          (record_item?.approval_status === null ||
                            record_item?.approval_status === undefined ||
                            record_item?.approval_status === 1)))
                    )
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => verifyorderaction(record_item, true)}
                ></Button>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title="Reject Order">
                <Button
                  type="danger"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  disabled={
                    !(
                      (record_item?.status === undefined ||
                        record_item?.status === null ||
                        record_item?.status < 5) &&
                      ((inventorymanagerview &&
                        (record_item?.approval_status === null ||
                          record_item?.approval_status === undefined ||
                          record_item?.approval_status < 3)) ||
                        (iboinventorymanager &&
                          (record_item?.approval_status === null ||
                            record_item?.approval_status === undefined ||
                            record_item?.approval_status === 1)))
                    )
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => verifyorderaction(record_item)}
                ></Button>
              </Tooltip>
            </>
          ) : null}

            <Divider type="vertical" />
            {/* {console.log("Status",record_item)} */}
              {record_item?.approval_status === 7 
              || record_item?.approval_status === 3 
              || record_item?.approval_status === 5 ? (
                  <Tooltip title="Resubmit Order">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<SyncOutlined />}
                      // icon={<EditOutlined />}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        showDrawer2(record_item);
                      }}
                    ></Button>
                  </Tooltip>
               ) : (
                <>
                  <Tooltip title="Resubmit Order">
                    <Button
                      type="primary"
                      shape="circle"
                      disabled
                      // icon={<EditOutlined />}
                      icon={<SyncOutlined />}
                      style={{ cursor: "pointer" }}
                      // onClick={() => {
                      //   showDrawer2(record_item);
                      // }}
                    ></Button>
                  </Tooltip>
                </>
               )}  
        </span>
      ),
    },
  ].filter((item) => !item.hidden);

  //Edit Orders
  // const setOrderStatusE = (
  //   stat,
  //   {
  //     id,
  //     // devices_approved,
  //     // tablets_approved,
  //     admin_comments,
  //     ship_to_name,
  //     address,
  //     city,
  //     state,
  //     zip,
  //     address2,
  //     address3,
  //     company_name,
  //     contact_name,
  //     contact_phone_number,
  //   }
  // ) => {
  //   var obj_arr = {
  //     req_action: stat === true ? 2 : 3,
  //     admin_comments: admin_comments,
  //     ship_to_name,
  //     address,
  //     city,
  //     state,
  //     zip,
  //     address2,
  //     address3,
  //     company_name,
  //     contact_name,
  //     contact_phone_number,
  //   };
  //   // if (stat) {
  //   //   obj_arr.devices_approved = devices_approved ?? 0;
  //   //   obj_arr.tablets_approved = tablets_approved ?? 0;
  //   // }
  //   console.log(`obj_arr`, obj_arr);
  //   dispatch(gerteditorder(id, obj_arr))
  //     .then((res) => {
  //       console.log(res);
  //       form.resetFields();
  //       message.success(`Success order ${stat ? "Accepted" : "Rejected"}`);
  //       setBtnLoading(false);
  //       setLoading(false);
  //       Setrecord(null);
  //       setEopen(false);
  //       fetchData({
  //         results: 10,
  //         page: 1,
  //       });
  //     })
  //     .catch((err) => {
  //       message.error(err);
  //       console.log(err);
  //       setBtnLoading(false);
  //       setLoading(false);
  //     });
  // };

  const onFinishFailedApproveE = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // setOpen(false);
  };
  const onFinishApproveE = (values) => {
    console.log(values);
    let devices_requested = values.devices2 === "" ? 0 : parseInt( values.devices2) ?? 0;
    let tablets_requested = values.tablets2 === "" ? 0 : parseInt(values.tablets2) ?? 0;
  
    // Ensure at least one device is requested
   if (devices_requested === 0 && tablets_requested === 0) {
      message.error("You must request at least one Phone or Tablet."); // Or use any other error handling method
      return;
   }
  
    setBtnLoading(true);
    setLoading(true);
    let post = {
      id: record.id,
      devices_approved: devices_requested,
      tablets_approved: tablets_requested,
      admin_comments: values.admin_comments1,
      ship_to_name: values.ship_to_name2,
      address: values.address12,
      city: values.city2,
      state: values.state2,
      zip: values.zip2,
      address2: values.address22 !== undefined ? values.address22 : "",
      company_name: values.company_name2,
      //contact_name: values.contact_name2,
      contact_phone_number: values.contact_phone_number1,
      email : values.email2,
    };
    dispatch(resubmit_request_order(post.id, post))
      .then((res) => {
        message.success(res.data.message);
        console.log(res);
        setBtnLoading(false);
        setLoading(false);
        form.resetFields();
        searchOrders();
        Setrecord(null);
        setResubmitopen(false);

      })
      .catch((err) => {
        message.error(err);
        console.log(err);
        setBtnLoading(false);
        setLoading(false);
      });
  };
  const onCloseApproveE = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Are you sure you want to cancel Resubmit order?",
      // content: "You want to cancel placing order?",
      onOk: () => {
        setResubmitopen(false);
        resetform();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
   
  //  const showDrawer2 = (e) => {
  //   console.log(e);
  //   setResubmitopen(true);
  //   Setrecord(e);

  // };

  // Re submit drawer
  const showDrawer2 = (e, stat) => {
    console.log(e);
    // setEopen(true);
    // Setisapprove(stat);
    setResubmitopen(true);
    Setrecord(e);

    
    Editform.setFieldsValue({
      devices2: e.devices_approved,
    tablets2: e.tablets_approved,
      // devices2:
      //   // e.devices_approved !== undefined && e.devices_approved !== null
      //   //   ? e.devices_approved
      //   //   : e.devices_requested,
      //   e.devices_requested !== undefined && e.devices_requested !== null
      //   ? e.devices_requested
      //   : e.devices_requested,
      // tablets2:
      //   // e.tablets_approved !== undefined && e.tablets_approved !== null
      //   //   ? e.tablets_approved
      //   //   : e.tablets_requested,
      //   e.tablets_requested !== undefined && e.tablets_requested !== null
      //   ? e.tablets_requested
      //   : e.tablets_requested,

      ship_to_name2: e?.ship_to_name ?? "",
      address12: e?.address ?? "",
      address22: e?.address2 ?? "",
      company_name2: e?.company_name ?? "",
      //contact_name2: e?.contact_name ?? "",
      city2: e?.city ?? "",
      state2: e?.state ?? "",
      zip2: e?.zip ?? "",
      contact_phone_number1: e?.contact_phone_number ?? "",
      email2:e?.email ?? "",
    });
  };
  //---------------------------------
  // rowSelection object indicates the need for row selection
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Are you sure you want to cancel placing order?",
      // content: "You want to cancel placing order?",
      onOk: () => {
        setOpen(false);
        resetform();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const onCloseApprove = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Are you sure you want to cancel placing order?",
      // content: "You want to cancel placing order?",
      onOk: () => {
        setAopen(false);
        resetform();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
 
  const verifyorderaction = (e, stat) => {
    console.log(e);
    if (stat) {
      setAopen(true);
      Setisapprove(stat);
      Setrecord(e);

     

      form.setFieldsValue({
        devices1:e?.devices_approved ?? "",
          // e.devices_approved !== undefined && e.devices_approved !== null
          //   ? e.devices_approved
          //   : e.devices_requested,
        tablets1:e?.tablets_approved ?? "",
          // e.tablets_approved !== undefined && e.tablets_approved !== null
          //   ? e.tablets_approved
          //   : e.tablets_requested,
        ship_to_name1: e?.ship_to_name ?? "",
        address11: e?.address ?? "",
        address21: e?.address2 ?? "",
        company_name1: e?.company_name ?? "",
        //contact_name1: e?.contact_name ?? "",
        city1: e?.city ?? "",
        state1: e?.state ?? "",
        zip1: e?.zip ?? "",
        contact_phone_number: e?.contact_phone_number ?? "",
        email1:e?.email ?? "",
      });
    } else {
      setAopen(true);
      Setisapprove(stat);
      Setrecord(e);
      form.setFieldsValue({
        devices:
          e.devices_approved !== undefined && e.devices_approved !== null
            ? e.devices_approved
            : e.devices_requested,
        tablets:
          e.tablets_approved !== undefined && e.tablets_approved !== null
            ? e.tablets_approved
            : e.tablets_requested,
      });

      // confirm({
      //   icon: <Icon type="exclamation-circle" />,
      //   content: "Are you sure to do this task?",
      //   onOk: () => {
      //     this.setOrderStatus(stat, { id: e.id, devices_approved: e.devices_approved !== undefined && e.devices_approved !== null && e.devices_approved !== 0 ? e.devices_approved : e.devices_requested, tablets_approved: e.tablets_approved !== undefined && e.tablets_approved !== null && e.tablets_approved !== 0 ? e.tablets_approved : e.tablets_requested, admin_comments: '' });
      //   },
      //   onCancel() {
      //     console.log("Cancel");
      //   },
      // });
    }
    // confirm({
    //   icon: <ExclamationCircleOutlined />,
    //   title: "Are you sure",
    //   content: " To approve this order?",
    //   onOk: () => {
    //     setAopen(true);
    //   },
    //   onCancel() {
    //     console.log("Cancel");
    //   },
    // });
  };
  const onFinish = (values) => {
    setBtnLoading(true);
    setLoading(true);
    console.log("Success:", values);
    
  let devices_requested = values.phones === "" ? 0 : parseInt( values.phones) ?? 0;
  let tablets_requested = values.tablets === "" ? 0 : parseInt(values.tablets) ?? 0;

  // Ensure at least one device is requested
 if (devices_requested === 0 && tablets_requested === 0) {
    setLoading(false);
    setBtnLoading(false);
    message.error("You must request at least one Phone or Tablet."); // Or use any other error handling method
    return;
 }

    var obj_arr = {
      // devices_requested: values.phones === "" ? 0 : values.phones ?? 0,
      // tablets_requested: values.tablets  === "" ? 0 : values.tablets ?? 0,
      devices_requested: devices_requested,
      tablets_requested: tablets_requested,
      method_of_delivery: values.Delivery,
      address: values.address1 !== undefined ? values.address1 : "",
      address2: values.address2 !== undefined ? values.address2 : "",
      city: values.city !== undefined ? values.city : "",
      state: values.state !== undefined ? values.state : "",
      zip: values.zip !== undefined ? values.zip : "",
      office_id: values.office,
      comments: values.description,
      ship_to_name: values.ship_to_name,
      company_name: values.company_name,
      contact_phone_number: values.contact_phone_number,
      email : values.email,
      //contact_name: values.contact_name,
    };

    console.log(obj_arr);

    dispatch(createinventoryorder(obj_arr))
      .then((res) => {
        console.log(res);
        Addform.resetFields();
        setOpen(false);
        setLoading(false);
        setBtnLoading(false);
        searchOrders();
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
        setLoading(false);
        setBtnLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // setOpen(false);
  };

  const resetform = () => {
    form.resetFields();
    Addform.resetFields();
    Editform.resetFields();
    setselactedItems([]);
    setfdata([]);
  };

  const rendercanlog = (params) => {
    setLoading(true);
    dispatch(GetInventoryLog(params))
      .then((res) => {
        console.log(res.data.items);
        setListdata(res.data.items);
        // message.success(res.data.message);
        setLoading(false);
        setVisible(true);
      })
      .catch((err) => {
        console.log(err);
        // message.error(err.data.message);
        setLoading(false);
      });
  };

  const onCloseLog = () => {
    setVisible(false);
  };

  const validatePhone = (rule, value, callback) => {
    const form = props.form;
    if (!form.getFieldValue("phones") && !form.getFieldValue("tablets")) {
      return callback(
        "At least one of number of phones or tablets is required!"
      );
    } else {
      if (value < 0) {
        callback(`Phones approved has to be greater than or equal to 1`);
      } else {
        callback();
      }
    }
  };

  const validateTablet = (rule, value, callback) => {
    const form = props.form;
    console.log(
      `form.getFieldValue('devices')`,
      form.getFieldValue("phones"),
      form.getFieldValue("tablets")
    );
    if (!form.getFieldValue("phones") && !form.getFieldValue("tablets")) {
      return callback(
        "At least one of number of phones or tablets is required!"
      );
    } else {
      try {
        if (value < 0) {
          callback(`Tablets approved has to be greater than or equal to 1`);
        } else {
          callback();
        }
      } catch (ex) {
        console.log(`error validating`, ex);
      }
    }
  };
  const fetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    if (value.length > 1) {
      setfdata([]);
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata1 = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdata(fdata1);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };
  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var approvalstatus = selectedapprovalstatus;
    var orderstatus = selectedorderstatus;
    var office_id = selectedItems.key;
    var search_text = search_key;
    var date_range_start = startDate;
    var date_range_end = endDate;
    const obj = {
      approval_status: approvalstatus,
      office_id: office_id,
      status: orderstatus,
      search_text: search_text,
      date_range_start,
      date_range_end,
    };
    console.log(obj);
    console.log(">>>>>>>>>>>", search_text);
    dispatch(exportInventoryOrderlist(obj))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        console.log("export response", res.url);
        if (res.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  const onFinishFailedApprove = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // setOpen(false);
  };
  const onFinishApprove = (values) => {
    setBtnLoading(true);
    setLoading(true);
    setOrderStatus(isapprove, {
      id: record.id,
      devices_approved: values.devices1,
      tablets_approved: values.tablets1,
      admin_comments: values.admin_comments,
      ship_to_name: values.ship_to_name1,
      address: values.address11,
      city: values.city1,
      state: values.state1,
      zip: values.zip1,
      address2: values.address21 !== undefined ? values.address21 : "",
      company_name: values.company_name1,
      // contact_name: values.contact_name1,
      contact_phone_number: values.contact_phone_number,
      email:values.email1,
    });
  };
  const setOrderStatus = (
    stat,
    {
      id,
      devices_approved,
      tablets_approved,
      admin_comments,
      ship_to_name,
      address,
      city,
      state,
      zip,
      address2,
      address3,
      company_name,
      //contact_name,
      contact_phone_number,
      email,
    }
  ) => {

    let devices_requested = devices_approved === "" ? 0 : devices_approved ?? 0;
    let tablets_requested = tablets_approved === "" ? 0 : devices_approved ?? 0;
    // if (stat) {
    //   obj_arr.devices_approved = devices_approved ?? 0;
    //   obj_arr.tablets_approved = tablets_approved ?? 0;
    // }
    if(stat){
    if (parseInt(devices_requested) === 0 && parseInt(tablets_requested) === 0) {
      setBtnLoading(false);
      setLoading(false);
      message.error("You must request at least one Phone or Tablet.");
      return;
    }
  }


    var obj_arr = {
      req_action: stat === true ? 2 : 3,
      devices_approved: devices_requested,
      tablets_approved: tablets_requested,
      admin_comments: admin_comments,
      ship_to_name,
      address,
      city,
      state,
      zip,
      address2,
      address3,
      company_name,
      // contact_name,
      contact_phone_number,
      email,
    };
    
    console.log(`obj_arr`, obj_arr);
    dispatch(getapproveorder(id, obj_arr))
      .then((res) => {
        console.log(res);
        form.resetFields();
        message.success(`Success order ${stat ? "Accepted" : "Rejected"}`);
        setBtnLoading(false);
        setLoading(false);
        Setrecord(null);
        setAopen(false);
        searchOrders();
      })
      .catch((err) => {
        message.error(err);
        console.log(err);
        setBtnLoading(false);
        setLoading(false);
      });
  };
  return (
    <>
      <div className="card">
        <div className="row">
          <div className="col align-self-end">
            <div className="card-header">
              <h4 className="card-title">
                {/* <strong>RMA Status</strong> */} Order Management{" "}
                {pagination.total && `(${pagination.total})`}
              </h4>
              <div className="px-3 float-end">
                <Button
                  type="primary"
                  className="mr5"
                  loading={loadingEPbtn}
                  onClick={getexportbtn}
                  style={{ marginRight: 25 }}
                >
                  <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
                </Button>

                <Button
                  type="primary"
                  onClick={() => showDrawer()}
                  className="mr5"
                >
                  Request Order
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div style={{ width: "100%" }}>
            <div className="row bg-white padd0">
              <div className="col-sm-12 col-md-3">
                <label for="exampleFormControlInput1">Select Office</label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  labelInValue
                  placeholder="Type 2 characters to search..!"
                  notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                  filterOption={false}
                  onSearch={fetchUser}
                  defaultActiveFirstOption={false}
                  value={selectedItems}
                  onChange={onchangeRole}
                >
                  {fdata?.map((d) => (
                    <Option key={d.value}>{d.text}</Option>
                  ))}
                </Select>
                {/* <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select office"
                  value={selectedoffice}
                  onChange={onchangeoffice}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                > */}
                {/* <Option value="0">Any</Option> */}
                {/* {renderOffice()}
                </Select> */}
              </div>
              {/* <div className="col">
                <label for="exampleFormControlInput1">
                  Select approval status
                </label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select approval status"
                  value={selectedapprovalstatus}
                  onChange={onchangeapprovalfilter}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderorderapproval()}
                </Select>
              </div> */}
              <div className="col-sm-12 col-md-3">
                <label for="exampleFormControlInput1">
                  Select Order Status
                </label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select order status"
                  value={selectedorderstatus}
                  onChange={onchangeorderfilter}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderorderstatus()}
                </Select>
              </div>
              <div className="col-sm-12 col-md-3  ">
                <label style={{ font: "caption" }}>Order From</label>
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledStartDate}
                  value={startValue}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={onStartChange}
                  onOpenChange={handleStartOpenChange}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label style={{ font: "caption" }}>Order To</label>
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledEndDate}
                  value={endValue}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={onEndChange}
                  open={endOpen}
                  onOpenChange={handleEndOpenChange}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-3">
                <label for="exampleFormControlInput1">Search by keyword</label>
                <Input
                  placeholder="Search by keyword"
                  value={search_key}
                  onChange={(e) => setsearch_key(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              <div className="col-sm-12 col-md-4 mt-5 ">
                <Button
                  type="primary"
                  className="mr5"
                  loading={btnLoading}
                  onClick={() => searchOrders("", null, null, "reset")}
                >
                  Search
                </Button>
                <Button
                  type="default"
                  style={{ marginLeft: "5px" }}
                  onClick={() => clearSearch()}
                >
                  Clear
                </Button>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-xl-12">
              {/* <div className="card-body"> */}
              <Table
                columns={columns}
                loading={loading}
                dataSource={data}
                pagination={pagination}
                onChange={handleTableChange}
                scroll={{
                  x: 1400,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        width={isMobile ? "100%" : "50%"}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          form={Addform}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          name="basic"
          layout="vertical"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="office"
                label="Office"
                rules={[
                  {
                    required: true,
                    message: "Please select office",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select Office"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={(props.otheroffice || []).map((d) => ({
                    value: d.id,
                    label: d.office_number,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Delivery"
                label="Delivery Method"
                rules={[
                  {
                    required: true,
                    message: "Please select delivery method",
                  },
                ]}
              >
                <Select placeholder="Please choose delivery method">
                  <Option value="3">Shipping to FedEx Location</Option>
                  <Option value="4">Shipping to Business Location</Option>
                  <Option value="2">Pickup</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="phones"
                label="No of Phones"
                rules={[
                  {
                    required: false,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please input no. of phones",
                  },
                ]}
              >
                <Input type={"number"}
                 min="0"
                  />
              </Form.Item>
            </Col>
            {/* </Row>
          <Row gutter={16}> */}
            <Col span={12}>
              <Form.Item
                name="tablets"
                label="No of Tablets"
                rules={[
                  {
                    required: false,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please input no. of tablets",
                  },
                ]}
              >
                <Input type={"number"}
                 min="0" 
                 />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Comments"
                rules={[
                  {
                    required: false,
                    message: "please enter comment",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="please enter comment" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Ship To"
                name="ship_to_name"
                rules={[{ required: true, message: "Please input ship name" }]}
              >
                <Input placeholder="name" />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label="Contact Name"
                name="contact_name"
                rules={[
                  { required: false, message: "Please input contact name" },
                ]}
              >
                <Input placeholder="name" />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                label="Company Name"
                name="company_name"
                rules={[
                  { required: false, message: "Please input company name" },
                ]}
              >
                <Input placeholder="Company Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Street Address"
                name="address1"
                rules={[
                  {
                    required: true,
                    message: "Please input Address",
                  },
                ]}
              >
                <Input placeholder="Address" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Street Address 1"
                name="address2"
                rules={[
                  {
                    required: false,
                    message: "Please input Address",
                  },
                ]}
              >
                <Input placeholder="Address" />
              </Form.Item>
            </Col>

            {/* </Row>
          <Row gutter={16}> */}
            <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please input City",
                  },
                ]}
              >
                <Input placeholder="City" />
              </Form.Item>
            </Col>

           
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="State"
                name="state"
                rules={[{ required: true, message: "Please input State" }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select State"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderStates()}
                </Select>
              </Form.Item>
            </Col>
            {/* </Row>
          <Row gutter={16}> */}
            <Col span={12}>
              <Form.Item
                label="ZIP"
                name="zip"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please input ZIP",
                  },
                ]}
              >
                <Input maxLength={5} placeholder="ZIP" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            
            <Col span={12}>
              <Form.Item
                label="Contact Number"
                name="contact_phone_number"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]{10,10}$/),
                    message: "Please input mobile number",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Mobile Number"
                  minLength={10}
                  style={{ width: "100%" }}
                  maxLength={10}
                  min={0}
                  step={0}
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input Email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 16,
            }}
          >
            <Space>
              {" "}
              <Button type="primary" loading={btnLoading} htmlType="submit">
                Submit
              </Button>
              <Button type="dashed" onClick={resetform}>
                Clear
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
      <Drawer
        title="View Log"
        placement="right"
        closable={true}
        width={isMobile ? "100%" : "50%"}
        onClose={onCloseLog}
        open={visible}
      >
        <List
          itemLayout="horizontal"
          dataSource={Listdata}
          renderItem={(item) => (
            <List.Item>
              <div className="row">
                <div className="col-12">
                  <List.Item.Meta
                    title={`Order Id : ${
                      item?.inventory_request_order_id ?? ""
                    }`}
                    // description={item.comments ?? ""}
                  />
                </div>
                <div className="col-4 mt-2y">
                  <Tag>{item.created_at}</Tag>
                </div>
                {/* </div> */}
                {/* <div className="row"> */}
                {item.description !== undefined &&
                item.description !== null &&
                item.description !== "" ? (
                  <div className="col-12">
                    Description: {item.description ?? ""}
                  </div>
                ) : null}
                {item.requested_phones_count !== undefined &&
                item.requested_phones_count !== null &&
                item.requested_phones_count !== "" ? (
                  <div className="col-12">
                    Requested number of Phones:{" "}
                    {item.requested_phones_count ?? ""}
                  </div>
                ) : null}
                {item.updated_phones_count !== undefined &&
                item.updated_phones_count !== null &&
                item.updated_phones_count !== "" ? (
                  <div className="col-12">
                    Approved number of Phones: {item.updated_phones_count ?? ""}
                  </div>
                ) : null}
                {item.requested_tablets_count !== undefined &&
                item.requested_tablets_count !== null &&
                item.requested_tablets_count !== "" ? (
                  <div className="col-12">
                    Requested number of Tablets:
                    {item.requested_tablets_count ?? ""}
                  </div>
                ) : null}
                {item.updated_tablets_count !== undefined &&
                item.updated_tablets_count !== null &&
                item.updated_tablets_count !== "" ? (
                  <div className="col-12">
                    Approved number of Tablets:{" "}
                    {item.updated_tablets_count ?? ""}
                  </div>
                ) : null}
                {item.ship_to_name !== undefined &&
                item.ship_to_name !== null &&
                item.ship_to_name !== "" ? (
                  <div className="col-12">
                    Ship to Name:
                    {item.ship_to_name ?? ""}
                  </div>
                ) : null}
                {item.updated_address !== undefined &&
                item.updated_address !== null &&
                item.updated_address !== "" ? (
                  <div className="col-12">
                    Address: {item.updated_address ?? ""}
                  </div>
                ) : null}
                 {item.email !== undefined &&
                  item.email !== null &&
                  item.email !== "" ? (
                    <div className="col-12">
                      Email: {item.email ?? ""}
                    </div>
                  ) : null}
                   {item.contact_phone_number !== undefined &&
                  item.contact_phone_number !== null &&
                  item.contact_phone_number !== "" ? (
                    <div className="col-12">
                      Contact Phone Number: {item.contact_phone_number ?? ""}
                    </div>
                  ) : null}
                {item.approved_user_name !== undefined &&
                item.approved_user_name !== null &&
                item.approved_user_name !== "" ? (
                  <div className="col-12">
                    Action taken by: {item.approved_user_name ?? ""}
                  </div>
                ) : null}
                {item.comments !== undefined &&
                item.comments !== null &&
                item.comments !== "" ? (
                  <div className="col-12">
                    Approver comments: {item.comments ?? ""}
                  </div>
                ) : null}
                {item.status_text !== undefined &&
                item.status_text !== null &&
                item.status_text !== "" ? (
                  <div className="col-12">
                    <Tag> Order status: {item.status_text ?? "NA"}</Tag>
                  </div>
                ) : null}
              </div>
              {/* <span>
                <b>Created At:</b> {item.created_at}<br />
                <b>Order Id:</b> {item?.inventory_request_order_id ?? ""}<br />
                <b>Requested number of Phones:</b> {item.requested_phones_count ?? ""}<br />
                <b>Approved number of Phones:</b> {item.updated_phones_count ?? ""}<br />
                <b>Requested number of Tablets:</b> {item.requested_tablets_count ?? ""}<br />
                <b>Approved number of Tablets:</b> {item.updated_tablets_count ?? ""}<br />
                <b>Approved by:</b> {item.approved_user_name ?? ""}<br />
                <b>Approver comments:</b> {item.comments ?? ""}<br />
                <b>Order status:</b> {item.status_text ?? "NA"}<br />
              </span> */}
            </List.Item>
          )}
        />
      </Drawer>
      <Drawer
        width={isMobile ? "100%" : "50%"}
        onClose={onCloseApprove}
        open={aopen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          form={form}
          onFinish={onFinishApprove}
          onFinishFailed={onFinishFailedApprove}
          autoComplete="off"
          name="basic"
          layout="vertical"
        >
          {isapprove === true ? (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="devices1"
                    label="No of Phones"
                    initialValue={record?.devices_approved ?? ""}
                    rules={[
                      {
                        required: false,
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input no. of phones",
                      },
                    ]}
                  >
                    <Input type={"number"}
                    //  min="1"
                     />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="tablets1"
                    label="No of Tablets"
                    initialValue={record?.tablets_approved ?? ""}
                    rules={[
                      {
                        required: false,
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input no. of tablets",
                      },
                    ]}
                  >
                    <Input type={"number"}
                    //  min="1" 
                     />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Ship To"
                    name="ship_to_name1"
                    rules={[
                      { required: true, message: "Please input ship name" },
                    ]}
                  >
                    <Input placeholder="name" />
                  </Form.Item>
                </Col>
                {/* <Col span={12}>
                  <Form.Item
                    label="Contact Name"
                    name="contact_name1"
                    rules={[
                      { required: false, message: "Please input contact name" },
                    ]}
                  >
                    <Input placeholder="name" />
                  </Form.Item>
                </Col> */}
                <Col span={24}>
                  <Form.Item
                    label="Company Name"
                    name="company_name1"
                    rules={[
                      { required: false, message: "Please input company name" },
                    ]}
                  >
                    <Input placeholder="Company Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Street Address"
                    name="address11"
                    rules={[
                      {
                        required: true,
                        message: "Please input Address",
                      },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Street Address 1"
                    name="address21"
                    rules={[
                      {
                        required: false,
                        message: "Please input Address",
                      },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                </Col>

                {/* </Row>
          <Row gutter={16}> */}
                <Col span={12}>
                  <Form.Item
                    label="City"
                    name="city1"
                    rules={[
                      {
                        required: true,
                        message: "Please input City",
                      },
                    ]}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                </Col>

               
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="State"
                    name="state1"
                    rules={[{ required: true, message: "Please input State" }]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select State"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {renderStates()}
                    </Select>
                  </Form.Item>
                </Col>
                {/* </Row>
          <Row gutter={16}> */}
                <Col span={12}>
                  <Form.Item
                    label="ZIP"
                    name="zip1"
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input ZIP",
                      },
                    ]}
                  >
                    <Input maxLength={5} placeholder="ZIP" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Contact Number"
                name="contact_phone_number"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]{10,10}$/),
                    message: "Please input mobile number",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Mobile Number"
                  minLength={10}
                  style={{ width: "100%" }}
                  maxLength={10}
                  min={0}
                  step={0}
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email1"
                rules={[
                  {
                    required: true,
                    message: "Please input Email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>
            </>
          ) : null}
          
         

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="admin_comments"
                label="Comments"
                rules={[
                  {
                    required: false,
                    message: "please enter comment",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="please enter comment" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 16,
            }}
          >
            <Space>
              {" "}
              <Button type="primary" loading={btnLoading} htmlType="submit">
                Submit
              </Button>
              <Button type="dashed" onClick={resetform}>
                Clear
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>

      {/* resubit Order */}
      <Drawer
        width={isMobile ? "100%" : "50%"}
        onClose={onCloseApproveE}
        open={resubmitopen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          form={Editform}
          onFinish={onFinishApproveE}
          onFinishFailed={onFinishFailedApproveE}
          autoComplete="off"
          name="basic"
          layout="vertical"
        >
          {/* {isapprove === true ? (
            <> */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="devices2"
                    label="No of Phones"
                    initialValue={record?.devices_requested ?? ""}
                    rules={[
                      {
                        required: false,
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input no. of phones",
                      },
                    ]}
                  >
                    <Input type={"number"} min="0"/>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="tablets2"
                    label="No of Tablets"
                    initialValue={record?.tablets_requested ?? ""}
                    rules={[
                      {
                        required: false,
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input no. of tablets",
                      },
                    ]}
                  >
                    <Input type={"number"} min="0"/>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Ship To"
                    name="ship_to_name2"
                    initialValue={record?.ship_to_name ?? ""}
                    rules={[
                      { required: true, message: "Please input ship name" },
                    ]}
                  >
                    <Input placeholder="name" />
                  </Form.Item>
                </Col>
                {/* <Col span={12}>
                  <Form.Item
                    label="Contact Name"
                    name="contact_name2"
                    initialValue={record?.contact_name ?? ""}
                    rules={[
                      { required: false, message: "Please input contact name" },
                    ]}
                  >
                    <Input placeholder="name" />
                  </Form.Item>
                </Col> */}
                <Col span={24}>
                  <Form.Item
                    label="Company Name"
                    name="company_name2"
                    initialValue={record?.company_name ?? ""}
                    rules={[
                      { required: false, message: "Please input company name" },
                    ]}
                  >
                    <Input placeholder="Company Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Street Address"
                    name="address12"
                    initialValue={record?.address ?? ""}
                    rules={[
                      {
                        required: true,
                        message: "Please input Address",
                      },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Street Address 1"
                    name="address22"
                    initialValue={record?.address2 ?? ""}
                    rules={[
                      {
                        required: false,
                        message: "Please input Address",
                      },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                </Col>

                {/* </Row>
          <Row gutter={16}> */}
                <Col span={24}>
                  <Form.Item
                    label="City"
                    name="city2"
                    initialValue={record?.city ?? ""}
                    rules={[
                      {
                        required: true,
                        message: "Please input City",
                      },
                    ]}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="State"
                    name="state2"
                    rules={[{ required: true, message: "Please input State" }]}
                    initialValue={record?.state ?? ""}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select State"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {renderStates()}
                    </Select>
                  </Form.Item>
                </Col>
                {/* </Row>
          <Row gutter={16}> */}
                <Col span={12}>
                  <Form.Item
                    label="ZIP"
                    name="zip2"
                    initialValue={record?.zip ?? ""}
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input ZIP",
                      },
                    ]}
                  >
                    <Input maxLength={5} placeholder="ZIP" />
                  </Form.Item>
                </Col>
              </Row>
            {/* </>
          ) : null} */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Contact Number"
                name="contact_phone_number1"
                initialValue={record?.contact_phone_number ?? ""}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]{10,10}$/),
                    message: "Please input mobile number",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Mobile Number"
                  minLength={10}
                  style={{ width: "100%" }}
                  maxLength={10}
                  min={0}
                  step={0}
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email2"
                rules={[
                  {
                    required: true,
                    message: "Please input Email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="admin_comments1"
                label="Comments"
                rules={[
                  {
                    required: true,
                    message: "please enter comment",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="please enter comment" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 16,
            }}
          >
            <Space>
              {" "}
              <Button type="primary" loading={btnLoading} htmlType="submit">
              Resubmit
              </Button>
              <Button type="dashed"
              //  onClick={resetform}>
              onClick={onCloseApproveE}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>

      <div>
        <Modal
          title="View Comments"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button type="primary" onClick={handleCancel}>
              Close
            </Button>,
          ]}
        >
          {Ldata2.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <>
              {Ldata2.map((items, index) => {
                return (
                  <List.Item key={index}>
                    <List.Item.Meta
                      title={`Created at:${items.created_at}`}
                      description={`Comments:${items.comments}`}
                    />
                    commented by :
                    <Tag color="orange">{items.commented_by_name}</Tag>
                  </List.Item>
                );
              })}
            </>
          )}
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    transfer: state.devicelist.transfer,
    otheroffice: state.devicelist.otheroffice,
    orderapprovalstatus: state.devicelist.orderapprovalstatus,
    orderstatus: state.devicelist.orderstatus,
  };
};

export default connect(mapStateToProps)(OrderInventory);
