import axiosRootInstance from "../../services/AxiosRootInstance";

import { GET_DOCUMENTS } from "./DocumentTypes";

export function getmarketerdoclist(user_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`user_id`, user_id);
      // resolve({
      //   "data":
      //     [
      //       {
      //         "form_name": "Application 1", "field_1": "Name 1", "field_2": "Designation 1",
      //         "field_3": "Address 1", "field_4": "City 1", "update_at": "Today", "status": "Active"
      //       },
      //       {
      //         "form_name": "Application 2", "field_1": "Name 2", "field_2": "Designation 2",
      //         "field_3": "Address 2", "field_4": "City 2", "update_at": "Today", "status": "Active"
      //       },
      //       {
      //         "form_name": "Application 3", "field_1": "Name 3", "field_2": "Designation 3",
      //         "field_3": "Address 3", "field_4": "City 3", "update_at": "Today", "status": "Active"
      //       },
      //       {
      //         "form_name": "Application 4", "field_1": "Name 4", "field_2": "Designation 4",
      //         "field_3": "Address 4", "field_4": "City 4", "update_at": "Today", "status": "Active"
      //       },
      //     ]
      // })
      axiosRootInstance
        .get(`/api/v1/get_document_list/${user_id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_DOCUMENTS,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Search Ofiice List
export function searchofficelist({
  status,
  ibo_marketer,
  search_text,
  page,
  sort,
  sort_by,
}) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/offices?status=${status ?? ""}&search_text=${
        search_text ?? ""
      }&ibo_marketer=${ibo_marketer ?? ""}&sort=${sort ?? ""}&sort_by=${
        sort_by ?? ""
      }&page=${page ?? 1}`;
      console.log(`offices url: ${url}`);
      axiosRootInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// IBO Market List new
export function getibomarketlistnew(retailer) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/reference/ibo_marketers/" + retailer)
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_IBO_MARKET,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function getsortlistoffice() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/reference/office_sort_keys")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Office Deselection Action
export function officedeleteaction(id, stat) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(id);
      var obj_arr = {
        status: stat,
      };
      axiosRootInstance
        .post(`/api/v1/offices/action/${id}`, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Send Agent/IBO Marketer/Office Documents
export function sendform(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`sendform`, params);
      // resolve("Success");
      axiosRootInstance
        .post("/api/v1/senddocument", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Download Agent/Marketer/Office Forms
export function downloadform(user_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`user_id`, user_id);
      // resolve("<html><head><title>My header</title></head><body>Hello World</body></html>")
      // const config = {
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded'
      //   }
      // }
      axiosRootInstance
        .get(`/api/v1/get_document/${user_id}`)
        .then((response) => {
          console.log(`response`, response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Update Agent/Marketer/Office Forms status
export function updateformstatus(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`updateformstatus`, params);
      axiosRootInstance
        .post(`/api/v1/accept_reject`, params)
        .then((response) => {
          console.log(`response`, response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Download Manager Forms
export function downloadmanagerform(user_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`user_id`, user_id);
      // resolve("<html><head><title>My header</title></head><body>Hello World</body></html>")
      // const config = {
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded'
      //   }
      // }
      axiosRootInstance
        .get(`/api/v1/get_document/${user_id}`)
        .then((response) => {
          console.log(`response`, response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function getRolelist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/roles")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get Offices list
export function getOfficelist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/offices")
        .then(function (response) {
          console.log(response);
          const newArr1 = response.data.items.data.map((v) => ({
            ...v,
            key: v.id,
          }));
          // console.log(`newArr1`, newArr1)
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function createEmployee(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      axiosRootInstance
        .post("/api/v1/users", values)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function editEmployee(values, id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      axiosRootInstance
        .post(`/api/v1/users/${id}`, values)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get IBO Marketers list
export function getMarketerslist(role, id) {
  console.log(role, id);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/get_ibo_marketer_info/" + role + "/" + id)
        .then(function (response) {
          //console.log("ibo_marketers", response);
          const newArr1 = response.data.map((v) => ({
            ...v,
            key: v.id,
          }));
          console.log(newArr1);
          resolve(newArr1);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function getibomarketlist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/reference/ibo_marketers")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
// Get sorce list
export function onboarding_status_sorce() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/reference/onboarding_source")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function getretailers() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/reference/retailers")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Office Export
export function office_export(search_text, status) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          `/api/v1/office_export?search_text=${search_text ?? ""}&status=${
            status ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get Distributors
export function getdistributers() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/reference/distributors")
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_DISTRIBUTOR,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get Master
export function getmaster() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/reference/master_agents")
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_MASTER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Update Office
export function updateoffice(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      var obj_arr = {
        name: values.officename,
        ibo_marketer_id:
          values.ibo_marketer_id === undefined ? "" : values.ibo_marketer_id,
        _method: "PUT",
        manager_id: values?.manager_id ?? "",
        supervisor_id:
          values.supervisor_id === undefined ? "" : values.supervisor_id,
        agent_id: [],
      };
      console.log(obj_arr);
      axiosRootInstance
        .post("/api/v1/offices/" + values.id, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Short Acroymn on US States
export function short_us_states(id, stat) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/reference/short_us_states")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function us_states_lifeline(id, stat) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/reference/us_states_lifeline")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get Inventory Count
export function get_inventory_count(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(`/api/v1/offices/get_inventory_count/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Office Supervisor
export function office_supervisor() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/userlist_based_on_role/office_supervisor")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Office Manager Name
export function office_manager_name(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        //.get("/api/v1/userlist_based_on_role/office_manager")
        .get(`/api/v1/get_office_info/${params}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Create Office
export function createoffice(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      var obj_arr = {
        state_short_name: values.state_short_name,
        ibo_marketer_id:
          values.ibo_marketer_id === undefined ? "" : values.ibo_marketer_id,
        manager_id:
          Array.isArray(values.manager_id) || values.manager_id === undefined
            ? ""
            : values.manager_id,
        supervisor_id:
          values.supervisor_id === undefined ? "" : values.supervisor_id,
      };
      console.log(obj_arr);
      axiosRootInstance
        .post("/api/v1/offices", obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// IBO Manager
export function ibo_manager() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/userlist_based_on_role/ibo_manager")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// IBO Supervisor
export function ibo_supervisor() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/userlist_based_on_role/ibo_supervisor")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Create IBO Market
export function createibomarket(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      var obj_arr = {
        name: values.iboname,
        retailer_id: values?.RetailerName ?? "",
        manager_id: values?.manager_id ?? "",
        supervisor_id: values?.supervisor_id ?? "",
      };
      axiosRootInstance
        .post("/api/v1/ibo_marketers", obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Update IBO Market
export function updateibomarket(values, market) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      var obj_arr = {
        name: values.iboname,
        retailer_id: values?.RetailerName ?? "",
        _method: "PUT",
        manager_id: values?.manager_id ?? "",
        supervisor_id: values?.supervisor_id ?? "",
      };
      axiosRootInstance
        .post("/api/v1/ibo_marketers/" + market.id, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get IBO Market
export function getibomarket(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/ibo_marketers?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_IBO_MARKET,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Search IBO Market
export function searchibomarket({
  status,
  search_text,
  retailer,
  page,
  sort,
  sort_by,
}) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/ibo_marketers?status=${status ?? ""}&search_text=${
        search_text ?? ""
      }&retailer=${retailer ?? ""}&sort=${sort ?? ""}&sort_by=${
        sort_by ?? ""
      }&page=${page ?? 1}`;
      axiosRootInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// IBO Market Export
export function ibo_market_export(search_text) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(`/api/v1/ibo_market_export?search_text=${search_text ?? ""}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function getsortlistmarket() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/reference/ibo_marketer_sort_keys")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getagedinventoryagent(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          params?.url
            ? params?.url
            : "/api/v1/aged_inventory_summary_reports/agent?page=" + params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Aged Inventory Agent
export function getagedinventoryagentsearch(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/aged_inventory_summary_reports/agent?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&agent_id=${params.agent ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}
export function aged_by_agent_export({ agent }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/agent?agent_id=${
            agent ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function getagedinventorymanager(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          "/api/v1/aged_inventory_summary_reports/manager?page=" + params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Aged Inventory Manager
export function getagedinventorymanagersearch(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/aged_inventory_summary_reports/manager?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&manager_id=${params.manager ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}
export function aged_by_manager_export({ manager }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/manager?manager_id=${
            manager ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function getagedinventorymarket(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          params?.url
            ? params?.url
            : "/api/v1/aged_inventory_summary_reports/ibo_marketer?page=" +
                params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
// Aged Inventory Market
export function getagedinventorymarketsearch(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/aged_inventory_summary_reports/ibo_marketer?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&ibo_marketer_id=${params.market ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}
export function aged_by_market_export({ market }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/ibo_marketer?ibo_marketer_id=${
            market ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function getagedinventoryoffice(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          params?.url
            ? params?.url
            : "/api/v1/aged_inventory_summary_reports/office?page=" +
                params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Aged Inventory Office
export function getagedinventoryofficesearch(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/aged_inventory_summary_reports/office?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&office_id=${params.summary ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function aged_by_office_export({ summary }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/office?office_id=${
            summary ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getofficelistname(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get(`/api/v1/reference/offices/${params}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function office_manager() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/userlist_based_on_role/office_manager")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function get_ibo_marketlist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosRootInstance
        .get("/api/v1/reference/ibo_marketers")
        .then(function (response) {
          console.log(response);
          resolve(response);
         
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
