/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getHomeStatAction } from "../../../store/actions/PostActions";
import { inventory_dashboard, expired_inventory_dashboard,inventory_dashboard_agent_summary_count } from "../../../store/actions/DeviceActions";
import Dialog from "@mui/material/Dialog";
import Chart from "react-apexcharts";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { forcepassword, logout } from "../../../store/actions/AuthActions";

import { FunnelChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";
import { Spin, Empty, Typography,Badge } from "antd";
const { Text, Link } = Typography;

const Home = (props) => {
  const { user } = props;
  let history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAgent, setLoadingAgent] = useState(true);
  const [data, setData] = React.useState([]);
  const [onboarding, setonboarding] = React.useState([]);
  const [agentsummary, setagentsummary] = React.useState([]);
  const [agent_label, setagent_label] = React.useState([]);
  const [agent_value, setagent_value] = React.useState([]);
  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [expired_onboarding_name, setexpired_onboarding_name] = useState([]);
  const [expired_onboarding_label, setexpired_onboarding_label] = useState([]);

  let errorsObj = { email: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    dispatch(inventory_dashboard())
      .then((res) => {
        console.log(res);
        // setagent_label(res.data.items.agent_label);
        // setagent_value(res.data.items.agent_value);
        setonboarding(res.data.items.onboarding_summary);
      })
      .catch((err) => {});
      
      dispatch(expired_inventory_dashboard())
      .then((res) => {
 
        setexpired_onboarding_name (res.data.items.onboarding_summary.map(item => item.name));
        setexpired_onboarding_label (res.data.items.onboarding_summary.map(item => item.value));
        
      }).catch((err) => {});

      dispatch(inventory_dashboard_agent_summary_count())
      .then((res) => {
        console.log(res);
        setagent_label(res.data.items.agent_label);
        setagent_value(res.data.items.agent_value);
        setLoadingAgent(false);
        // setonboarding(res.data.items.onboarding_summary);
      })
      .catch((err) => {});

    dispatch(getHomeStatAction(props.history)).then((res) => {
      setData(props.dashdata);
      console.log(props.dashdata.activation_count);
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    console.log(user);
    if (user.password_changed === 0) {
      setOpen(true);
    }
  }, [user]);

  console.log(props);

  const onSubmit = (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (newpass !== oldpass) {
      console.log(oldpass);
      errorObj.email = "Password Mismatch";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    console.log(oldpass, newpass, user.idToken);
    dispatch(forcepassword(newpass, user.idToken))
      .then((res) => {
        console.log(res);
        setOpen(false);
        dispatch(logout(props.history));
      })
      .catch((err) => {
        console.log(err);
      });
    // history.push("/login");
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    //setOpen(false);
  };

  const options = {
    labels: agent_label,
    legend: {
      position: "top",
    },
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const indexid = config.w.config.labels[config.dataPointIndex];
          console.log(indexid);
          if (indexid === "Dynamic Agent") {
            history.push({
              // no need
              pathname: "/agentreport",
              state: {
                urlpath:
                  "/api/v1/agent_summary_report?redirect_key=active_agent",
              },
            });
          }
          if (indexid === "Passive Agent") {
            history.push({
              // no need
              pathname: "/agentreport",
              state: {
                urlpath:
                  "/api/v1/agent_summary_report?redirect_key=passive_agent",
              },
            });
          }
          if (indexid === "Dormant Agent") {
            history.push({
              // no need
              pathname: "/agentreport",
              state: {
                urlpath:
                  "/api/v1/agent_summary_report?redirect_key=inactive_agent",
              },
            });
          }
          if (indexid === "Onboarded Agent") {
            history.push({
              // no need
              pathname: "/agentreport",
              state: {
                urlpath: "/api/v1/agent_summary_report?redirect_key=onboarded",
              },
            });
          }
        },
      },
    },
  };
  const series = agent_value;
  const options1 = {
    labels: expired_onboarding_name,
    legend: {
      position: "top",
    },
    colors: [
      "#008FFB",
      "#00E396",
      "#FEB019",
      "#FF4560",
      "#775DD0",
      "#546E7A",
      "#26a69a",
      '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
		  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
      ],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {},
            total: {
              show: true, // default false
              showAlways: true, // default false
              label: "Total",
              fontSize: "22px",
              fontFamily: "Heebo, Arial, sans-serif",
              fontWeight: 600,
              color: "#000000",
             
          },

          }
        }
      }
    },
    chart: {
      type: 'donut',
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const indexid = config.w.config.labels[config.dataPointIndex];
          history.push({
            // no need
            pathname: "/onboardingreport",
            state: {
              urlpath:
                `/api/v1/onboarding_summary_report?redirect_key=${20}`,
            },
          });
        },
      },
      // events: {
      //   dataPointSelection: (event, chartContext, config) => {
      //     const indexid = config.w.config.labels[config.dataPointIndex];
      //     if (indexid === "Dynamic Agent") {
      //       this.props.history.push({
      //         // no need
      //         pathname: "/agentsummaryreport",
      //         state: {
      //           urlpath:
      //             "/api/v1/agent_summary_report?redirect_key=active_agent",
      //         },
      //       });
      //     }
      //     if (indexid === "Passive Agent") {
      //       this.props.history.push({
      //         // no need
      //         pathname: "/agentsummaryreport",
      //         state: {
      //           urlpath:
      //             "/api/v1/agent_summary_report?redirect_key=passive_agent",
      //         },
      //       });
      //     }
      //     if (indexid === "Dormant Agent") {
      //       this.props.history.push({
      //         // no need
      //         pathname: "/agentsummaryreport",
      //         state: {
      //           urlpath:
      //             "/api/v1/agent_summary_report?redirect_key=inactive_agent",
      //         },
      //       });
      //     }
      //     if (indexid === "Onboarded Agent") {
      //       this.props.history.push({
      //         // no need
      //         pathname: "/agentsummaryreport",
      //         state: {
      //           urlpath:
      //             "/api/v1/agent_summary_report?redirect_key=onboarded",
      //         },
      //       });
      //     }
      //   },
      // },
    },
  };
  const series1 = expired_onboarding_label;
  return (
    <>
      <Spin spinning={loading} style={{ zIndex: -1 }}>
        <div className="row">
          <div className="col-xl-12">
            <h4 class="card-title">
              <strong>Sales Summary</strong>
            </h4>

            <hr className="mb-5"></hr>
          </div>

          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body  p-3">
                <div className="media">
                  <div className="media-body">
                    <h5 className="mb-3">Daily Activations</h5>
                    <h3 className="mb-3">
                      {props.dashdata?.activation_count?.daily}
                    </h3>
                  </div>
                  <span>
                    <i class="fa fa-lg fa-chart-line text-info"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body  p-3">
                <div className="media">
                  <div className="media-body">
                    <h5 className="mb-3">WTD Activations</h5>
                    <h3 className="mb-3">
                      {" "}
                      {props.dashdata?.activation_count?.WTD}
                    </h3>
                  </div>
                  <span>
                    <i class="fa fa-lg fa-chart-line text-info"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body  p-3">
                <div className="media">
                  <div className="media-body">
                    <h5 className="mb-3">MTD Activations</h5>
                    <h3 className="mb-3">
                      {" "}
                      {props.dashdata?.activation_count?.MTD}
                    </h3>
                  </div>
                  <span>
                    <i class="fa fa-lg fa-chart-line text-info"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body  p-3">
                <div className="media">
                  <div className="media-body">
                    <h5 className="mb-3">Last 7 Days</h5>
                    <h3 className="mb-3">
                      {props.dashdata?.activation_count?.Last_7_Days}
                    </h3>
                  </div>
                  <span>
                    <i class="fa fa-lg fa-chart-line text-info"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body  p-3">
                <div className="media">
                  <div className="media-body">
                    <h5 className="mb-3">Last 30 Days</h5>
                    <h3 className="mb-3">
                      {props.dashdata?.activation_count?.Last_30_Days}
                    </h3>
                  </div>
                  <span>
                    <i class="fa fa-lg fa-chart-line text-info"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h4 class="card-title">
          <strong>Inventory Summary </strong>
        </h4>

        <hr className="mb-5"></hr>
        <div className="row mb-5">
          <div class="col-xl-2 col-xxl-2 col-lg-6 col-sm-6">
            <div
              class="card text-white"
              style={{ borderRadius: "-2.625rem", border: "0px" }}
            >
              <div
                class="card-header card-header-stat"
                style={{ backgroundColor: "#0eacf3" }}
              >
                <h5 class="card-title text-center text-white">1-15 DAYS</h5>
              </div>
              <div class="card-body mb-0">
                <h2 class="card-text text-center">
                  {props.dashdata?.inventory_aging?.LT15}
                </h2>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-xxl-2 col-lg-6 col-sm-6">
            <div
              class="card text-white"
              style={{ borderRadius: "-2.625rem", border: "0px" }}
            >
              <div
                class="card-header card-header-stat"
                style={{ backgroundColor: "#0eacf3" }}
              >
                <h5 class="card-title text-center text-white">16-30 DAYS</h5>
              </div>
              <div class="card-body mb-0">
                <h2 class="card-text text-center">
                  {" "}
                  {props.dashdata?.inventory_aging?.LT30}
                </h2>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-xxl-2 col-lg-6 col-sm-6">
            <div
              class="card text-white"
              style={{ borderRadius: "-2.625rem", border: "0px" }}
            >
              <div
                class="card-header card-header-stat"
                style={{ backgroundColor: "#0eacf3" }}
              >
                <h5 class="card-title text-center text-white">31-45 DAYS</h5>
              </div>
              <div class="card-body mb-0">
                <h2 class="card-text text-center">
                  {props.dashdata?.inventory_aging?.LT45}
                </h2>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-xxl-2 col-lg-6 col-sm-6">
            <div
              class="card text-white"
              style={{ borderRadius: "-2.625rem", border: "0px" }}
            >
              <div
                class="card-header card-header-stat"
                style={{ backgroundColor: "#0eacf3" }}
              >
                <h5 class="card-title text-center text-white">46-90 DAYS</h5>
              </div>
              <div class="card-body mb-0">
                <h2 class="card-text text-center">
                  {props.dashdata?.inventory_aging?.LT90}
                </h2>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-xxl-2 col-lg-6 col-sm-6">
            <div
              class="card text-white"
              style={{ borderRadius: "-2.625rem", border: "0px" }}
            >
              <div
                class="card-header card-header-stat"
                style={{ backgroundColor: "#0eacf3" }}
              >
                <h5 class="card-title text-center text-white"> {"\u003E"}90 DAYS</h5>
              </div>
              <div class="card-body mb-0">
                <h2 class="card-text text-center">
                  {" "}
                  {props.dashdata?.inventory_aging?.GT90}
                </h2>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-xxl-2 col-lg-6 col-sm-6">
            <div
              class="card text-white"
              style={{ borderRadius: "-2.625rem", border: "0px" }}
            >
              <div
                class="card-header card-header-stat"
                style={{ backgroundColor: "grey" }}
              >
                <h5 class="card-title text-center text-white">
                  Total Inventory
                </h5>
              </div>
              <div class="card-body mb-0">
                <h2 class="card-text text-center">
                  {props.dashdata?.inventory_aging?.Grand_Total}
                </h2>
              </div>
            </div>
          </div>
          <div className="col-xl-6 mb-5">
            <h4 class="card-title">
              <strong>Onboarding Summary</strong>
            </h4>

            <hr className="mb-5"></hr>
              <div className="text-center mb-3">    
                <Badge color="#f0a80d" text="Agent" />      
              </div>
            <FunnelChart
              data={onboarding}
              getToolTip={(row) => {
                return row.name;
              }}
              getRowStyle={(row) => { return row.name === "Invite Sent" || row.name === "BGC Pending"   ? { backgroundColor: '#808080'} :  row.name === "BGC Denied" ? {backgroundColor:'red'} : row.name === "Onboarded" ? {backgroundColor: '#111f44'}
               : row.name === "Invite Sent" ? { backgroundColor: '#808080'}
                : row.name === "Profile In Progress" || row.name === "Verification Failed" || row.name === "Training & BGC Pending" || row.name === "Training Pending" || row.name === "RAD Link Failed" ? { backgroundColor: '#f0a80d'}
                 :  row.name === "BGC Review" ? { backgroundColor: '#808080'}
                  : row.name === "Expired" ? { backgroundColor: '#808080'}
                   : row.name === "Telgoo ID" ? { backgroundColor: '#808080'}
                   : row.name === "Rejected"? { backgroundColor: "red" } 
                   : row.name === "RAD Updated" ? { backgroundColor: '#808080'} : { backgroundColor: '#808080'}; }}

              onRowClick={(row) => {
                
                history.push({
                  pathname: `/onboardingreport`,
                  state: {
                    urlpath: `/api/v1/onboarding_summary_report?redirect_key=${
                      row.name === "Invite Sent"
                      ? "1"
                      : row.name === "Profile In Progress"
                      ? "2"
                      : row.name === "Verification Failed"
                      ? "24"
                      : row.name === "Training & BGC Pending"
                      ? "4"
                      : row.name === "BGC Pending"
                      ? "5"
                      : row.name === "BGC Review"
                      ? "6"
                      : row.name === "BGC Denied"
                      ? "22"
                      : row.name === "Training Pending"
                      ? "9"
                      : row.name === "Expired"
                      ? "20"
                      : row.name === "Telgoo ID"
                      ? "12"
                      : row.name === "Onboarded"
                      ? "13"
                      : row.name === "Profile Updated"
                      ? "25"
                      : row.name === "Link RAD ID"
                      ? "23"
                      : row.name === "RAD Link Failed"
                      ? ""
                      : row.name === "Profile Completed"
                      ? "3"
                      : row.name === "Rejected"
                      ? "27"
                      : ""
                    }`,
                  },
                });
              }}
            />
          </div>
          <div className="col-xl-6">
          <div className="row">
          <div className="col-xl-12">
            <h4 class="card-title">
              <strong>Expired Status Distribution </strong>
            </h4>
            <hr className="mb-5"></hr>
            <div id="chart">
              <Chart
                options={options1}
                series={series1}
                type="donut"
                width="100%"
              />
            </div>
         
          </div>
          <Spin spinning={loadingAgent} style={{ zIndex: -1 }}>
            <div className="col-xl-12">
              <h4 class="card-title">
                <strong>Agents Summary </strong>
              </h4>
              <hr className="mb-5"></hr>
              <div id="chart">
                <Chart
                  options={options}
                  series={series}
                  type="pie"
                  width="100%"
                />
              </div>
              <div className="text-center">
                <Text code>Dynamic Agent (1-14 Days)</Text>

                <Text code>Passive Agent (15-30 Days)</Text>

                <Text code>Dormant Agent (31-60 Days)</Text>
              </div>
            </div>
          </Spin>
          </div>
          </div>
         
        
        </div>

        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter your new password below.
            </DialogContentText>
            <form onSubmit={(e) => onSubmit(e)}>
              <label className="text-label mt-4">New Password *</label>
              <div className="input-group transparent-append mb-2">
                <input
                  required
                  type={`${showPassword1 ? "text" : "password"}`}
                  className="form-control"
                  placeholder="Type Your Password"
                  onChange={(e) => setOldpass(e.target.value)}
                />
                <div
                  className="input-group-text"
                  onClick={() => setShowPassword1(!showPassword1)}
                >
                  <i
                    className={`${
                      showPassword1 ? "fa fa-eye" : "fa fa-eye-slash"
                    }`}
                  />
                </div>
              </div>
              <label className="text-label">Confirm Password *</label>
              <div className="input-group transparent-append mb-2">
                <input
                  required
                  type={`${showPassword2 ? "text" : "password"}`}
                  className="form-control"
                  placeholder="Type Your Password"
                  onChange={(e) => setNewpass(e.target.value)}
                />
                <div
                  className="input-group-text"
                  onClick={() => setShowPassword2(!showPassword2)}
                >
                  <i
                    className={`${
                      showPassword2 ? "fa fa-eye" : "fa fa-eye-slash"
                    }`}
                  />
                </div>
                <div
                  id="val-username1-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                >
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
              </div>

              <div className="text-center mt-5">
                <button type="submit" className="btn btn-primary btn-block">
                  SUBMIT
                </button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </Spin>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.auth,
    dashdata: state.posts.stats,
    device: state.devicelist.transaction,
  };
};

export default connect(mapStateToProps)(Home);
