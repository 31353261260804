import React, { useState } from "react";
// FAQ DATA
import FAQ_data from "./faq.json";
// ANTD
import { Input } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
const { Search } = Input;

export const FAQ = () => {
  const [faqs, setFaqs] = useState(FAQ_data);
  const [search_text, setSearch_text] = useState("");

  // Search Module
  const handleSearch = (e) => {
    var value = e.target.value;
    setSearch_text(value);
    const reg = new RegExp(value, "gi");
    const filteredFaq = FAQ_data.filter((record) => {
      const questionMatch = reg.test(record.question);
      const answerMatch = reg.test(record.answer);
      return questionMatch || answerMatch;
    });
    setFaqs(value ? filteredFaq : FAQ_data);
  };

  // Expand FAQ based on ID
  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          // Toggle the 'isCollapsed' property for the clicked FAQ
          return { ...faq, isCollapsed: !faq.isCollapsed };
        } else {
          // Close other FAQs
          return { ...faq, isCollapsed: true };
        }
      })
    );
  };

  return (
    <div>
      <section className="section mb-5 pt-4 pb-4 bg-dark support_banner_faq">
        <div className="container">
          <div className="row justify-content-center">
            {/* <div className="col-lg-8 col-xl-7 text-center desc">
                <div
                  className=" mb-1 text-white"
                  style={{ fontSize: "1.5rem" }}
                >
                  Frequently Asked Questions
                </div>
              </div> */}
            <Search
              placeholder="What we can help you with?"
              value={search_text}
              onChange={(value) => handleSearch(value)}
              style={{ height: 42, fontSize: 18 }}
              className="col-lg-8 col-xl-7 text-center"
              size="large"
            />
          </div>
        </div>
      </section>
      <div className="container faqs">
        {/* Map Questions and Answers */}
        {faqs.map((faq, index) => (
          <div
            className={"faq " + (faq.isCollapsed === false ? "open" : "")}
            key={index}
            onClick={() => toggleFAQ(index)}
          >
            <section
              className="text-primary-emphasis faq-question d-flex align-items-start gap-2"
              style={{ fontSize: "1.4rem" }}
            >
              <FileTextOutlined className="mt-1" />
              {faq.question}
            </section>
            {faq.question === "How do I login to the WiES Portal?" ? (
              <section
                className="mt-2 faq-answer"
                style={{ fontSize: "1.4rem" }}
              >
                <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </section>
            ) : (
              <section
                className="mt-2 faq-answer"
                style={{ fontSize: "1.4rem" }}
              >
                {faq.answer}
              </section>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
