import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Select,
  Button,
  Input,
  Form,
  message,
  InputNumber,
  Radio,
  Row,
  Col,
  Spin,
} from "antd";
import _ from "lodash";
import {
  getretailers,
  getibomarketlist,
  getRolelist,
  getMarketerslist,
  getOfficelist,
  createEmployee,
  short_us_states,
} from "../../../store/actions/documentActions";
import { getofficelistname } from "../../../store/actions/documentActions";
import moment from "moment";
const { Option } = Select;

class AddEmployee extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // state
    this.state = {
      offices: [],
      officeview: false,
      disableoffice: true,
      filteredoffices: [],
      roles: [],
      filteredroles: [],
      marketers: [],
      filteredmarketers: [],
      loading: false,
      disable: false,
      marketview: false,
      distributor: [],
      master: [],
      retailer: [],
      masterview: false,
      distributorview: false,
      retailerrole: false,
      marketrole: false,
      superrole: false,
      superadmin: false,
      iboofficeview: false,
      shortstate: [],
    };
  }

  onChange(value) {
    console.log(`selected ${value}`);
  }

  mobilevalidation = (value) => {
    console.log(value);
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (value.test(phoneno)) {
      console.log(value.match(phoneno));
      this.props.form.setFieldsValue({
        MobileNo: value,
      });
    }
  };

  componentWillMount() {
    this.getRolelist();
    this.getMarketerslist();
    this.props.short_us_states().then((res) => {
      console.log(res);
      this.setState({
        shortstate: res.data,
      });
    });

    const Retailer = this.props.user.role_id.filter((word) => word === 12);
    const marketer = this.props.user.role_id.filter((word) => word === 3);
    const ibpmarketer = this.props.user.role_id.filter((word) => word === 11);
    console.log("Retailerview", Retailer);
    console.log("marketer", marketer);
    if (ibpmarketer.length !== 0) {
      this.setState({
        iboofficeview: true,
      });
    }
    if (Retailer.length !== 0) {
      this.setState({
        retailerrole: true,
      });
    }
    if (marketer.length !== 0) {
      this.setState({
        marketrole: true,
      });
    }
  }

  genderOnChange = (e) => {
    var fdesgnd = _.filter(this.state.gender, ["Gender", e]);
    this.setState({
      filteredgender: fdesgnd,
    });
    console.log(e, fdesgnd);
  };
  disabledDOB(current) {
    return moment().add(0, "days") <= current;
  }
  disabledDOJ(current) {
    return moment().add(0, "days") <= current;
  }

  handleSubmit = (values) => {
    // e.preventDefault();
    console.log(`values`, values);
    // if (this.state.emailexists) {
    // if (this.state.vehicleexists) {
    this.formRef.current
      .validateFields({ validateOnly: true })
      .then(() => {
        console.log(`values`, values);
        this.setState({
          loading: true,
        });
        var employee = {
          first_name: values.first_name,
          middle_name: values?.middle_name ?? "",
          last_name: values.last_name,
          rad: values.rad,
          promo_code: values.promo_code,
          excess_code: values.excess_code,
          mobile_number: values.mobile_number.toString(),
          gender_id: values.gender_id, //this.state.filteredgender.id,
          office_id: values?.office_id ?? [],
          ibo_marketer_id:
            values.ibo_marketer_id !== undefined
              ? values?.ibo_marketer_id.toString() ?? ""
              : "",
          dob: moment(this.state.dob).format("YYYY-MM-DD"),
          application_date: moment(this.state.application_date).format(
            "YYYY-MM-DD"
          ),
          address: values.address !== undefined ? values.address : "",
          city: values.city !== undefined ? values.city : "",
          state: values.state !== undefined ? values.state : "",
          zip: values.zip !== undefined ? values.zip : "",
          email: values.email,
          role_id: [values.role_id] , //[9, 2],  
          retailer_id:
            values.RetailerName !== undefined ? values.RetailerName : "",
          distributor_id:
            values.DistributorName !== undefined ? values.DistributorName : "",
          master_agent_id: values.Master !== undefined ? values.Master : "",
        };
        console.log(`employee`, employee);

        this.props
          .createEmployee(employee)
          .then((res) => {
            console.log("res", res);
            message.success("User created successfully");
            this.formRef.current.resetFields();
            this.setState({
              // fileList: [],
              loading: false,
              // pwdstatus: "",
            });
            this.props.history.push("/employee");
          })
          .catch((err) => {
            message.error(err);
            this.setState({
              // fileList: [],
              loading: false,
              // pwdstatus: "",
            });
            console.log(err);
          });
      })
      .catch((err) => {
        this.setState({
          // fileList: [],
          loading: false,
          // pwdstatus: "",
        });
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  renderOffice() {
    return _.map(this.state.offices, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  }

  renderGender() {
    return _.map(this.state.gender, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  }

  renderRole() {
    // console.log('this.state.roles', this.state.roles)
    return _.map(this.state.roles, (dep, index) => {
      if (this.state.iboofficeview === true) {
        if (dep.id === 3 || dep.id === 9) {
          // return (
          //   <Col span={24} key={index}>
          //     <Radio value={dep.id}>{dep.name}</Radio>
          //   </Col>
          // );
        }
      }
      if (this.state.retailerrole === true) {
        if ( dep.id === 11) {
          return (
            <Col span={24} className="mb-3" key={index}>
              <Radio value={dep.id}>{dep.name}</Radio>
            </Col>
          );
        }
      }
    });
  }

  renderMarketers() {
    return _.map(this.state.marketers, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  }

  onChangeRole = (e) => {
    var checkedValues = [e.target.value];
    console.log("checkvalue", checkedValues);
    console.log("checked = ", checkedValues);
    if (e.target.value === 3 || e.target.value === 11) {
      this.getMarketerslistoff();
    }
    if (e.target.value === 3) {
      this.formRef.current.setFieldsValue({
        office_id: [],
      });
      this.setState({
        officeview: true,
        disableoffice: true,
      });
    } else {
      this.setState({
        officeview: false,
      });
    }

    var OffManagerIndex = this.state.roles.findIndex(
      (role) => role.name === "Office Manager"
    );
    var IBOManagerIndex = this.state.roles.findIndex(
      (role) => role.name === "IBO Manager"
    );
    var retaileusers = checkedValues.filter(
      (obj) =>
        obj === this.state.roles[OffManagerIndex].id ||
        this.state.roles[IBOManagerIndex].id
    );

    console.log(retaileusers);

    if (retaileusers.length !== 0) {
      this.setState({
        retailerview: true,
      });
    } else {
      this.setState({
        retailerview: false,
      });
    }
  };

  roleOnChange = (e) => {
    var fdesgnd = _.filter(this.state.roles, ["Role", e]);
    this.setState({
      filteredroles: fdesgnd,
    });
    console.log(e, fdesgnd);
  };

  marketerOnChange = (e) => {
    this.setState({
      loading: true,
    });
    console.log(e);
    this.props
      .getofficelistname(e)
      .then((res) => {
        console.log("marketers", res);
        this.formRef.current.setFieldsValue({
          office_id: [],
        });
        this.setState({
          offices: res.data,
          disableoffice: false,
          loading: false,
        });
      })
      .catch((err) => {
        this.formRef.current.setFieldsValue({
          office_id: [],
        });
        console.log(err);
        this.setState({
          offices: [],
          disableoffice: true,
          loading: false,
        });
      });
  };

  getMarketerslist(role) {
    console.log(role);
    this.props
      .getMarketerslist(role === 4 ? "M" : "IM", 0)
      .then((res) => {
        this.formRef.current.setFieldsValue({
          ibo_marketer_id: [],
        });
        console.log("marketers", res);
        this.setState({
          marketers: res,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          marketers: [],
        });
      });
  }

  getMarketerslistoff() {
    this.setState({
      marketers: [],
      loading: true,
    });
    this.props
      .getibomarketlist()
      .then((res) => {
        this.formRef.current.setFieldsValue({
          ibo_marketer_id: [],
        });
        console.log("marketers", res);
        this.setState({
          marketers: res.data,
          loading: false,
        });
        // nprogress.done();
      })
      .catch((err) => {
        this.setState({
          marketers: [],
          loading: false,
        });
        // nprogress.done();
      });
  }

  getRolelist() {
    if (this.state.roles === undefined || this.state.roles.length <= 0) {
      // nprogress.start();
      this.props
        .getRolelist()
        .then((res) => {
          console.log("getRolelist", res);
          this.setState({
            roles: _.sortBy(res.data.items, (o) => o.level),
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            roles: [],
          });
          // nprogress.done();
        });
    }
  }
  renderMaster() {
    return _.map(this.state.master, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderDistributor() {
    return _.map(this.state.distributor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderstateshort() {
    return _.map(this.state.shortstate, (master, index) => {
      return (
        <Option value={master.short_name} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  render() {
    // eslint-disable-next-line

    const { loading } = this.state;

    return (
      <Spin spinning={loading}>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  <strong>Add User</strong>
                </h4>
                <Link to="/employee" className="float-right">
                  <Button type="default">Back</Button>
                </Link>
              </div>
              <div className="card-body">
                <Form
                  name="basic"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  onFinish={this.handleSubmit}
                  onFinishFailed={this.onFinishFailed}
                  ref={this.formRef}
                  className="addbookform row"
                  requiredMark
                >
                  <Form.Item
                    name="first_name"
                    label="First Name"
                    className="col-md-4 col-sm-6 col-xs-12"
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                        message: "Please input first name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter First Name" />
                  </Form.Item>
                  <Form.Item
                    name="middle_name"
                    label="Middle Name"
                    className="col-md-4 col-sm-6 col-xs-12"
                    rules={[
                      {
                        required: false,
                        pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                        message: "Please input middle name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Middle Name" />
                  </Form.Item>
                  <Form.Item
                    name="last_name"
                    label="Last Name"
                    className="col-md-4 col-sm-6 col-xs-12"
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                        message: "Please input last name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Last Name" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                        ),
                        message: "Enter valid email address",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      placeholder="Enter Email Address"
                      autoComplete="new-password"
                      // onBlur={(e) => this.checkEmail(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="mobile_number"
                    label="Mobile Number"
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^[0-9]{10,10}$/),
                        message: "Please input mobile number",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Enter Mobile Number"
                      minLength={10}
                      maxLength={10}
                      min={0}
                      step={0}
                      autoComplete="new-password"
                    />
                  </Form.Item>

                  {this.state.iboofficeview ? null :(
                  <Form.Item
                    name="role_id"
                    label="Role"
                    className="col-md-12 col-sm-6 col-xs-12 mt-2"
                    rules={[
                      {
                        required: true,
                        message: "Please input Role",
                      },
                    ]}
                  >
                    <Radio.Group onChange={this.onChangeRole} defaultChecked>
                      <Row> {this.renderRole()}</Row>
                    </Radio.Group>
                  </Form.Item>
                  )}

                  {this.state.retailerview && !this.state.marketrole ? (
                    <Form.Item
                      name="ibo_marketer_id"
                      label="IBO / Market"
                      className="col-md-6 col-sm-6 col-xs-12"
                      rules={[
                        {
                          required: true,
                          message: "Please input IBO / Market",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select IBO / Market"
                        onChange={this.marketerOnChange}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.renderMarketers()}
                      </Select>
                    </Form.Item>
                  ) : null}
                  {this.state.retailerview &&
                  this.state.officeview &&
                  !this.state.marketrole ? (
                    <Form.Item
                      name="office_id"
                      label="Office"
                      className="col-md-6 col-sm-6 col-xs-12"
                      rules={[
                        {
                          required: true,
                          message: "Please select office",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select office"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={this.state.disableoffice}
                      >
                        {this.renderOffice()}
                      </Select>
                    </Form.Item>
                  ) : null}
                  <Form.Item
                    name="address"
                    label="Street Address"
                    className="col-md-4 col-sm-6 col-xs-12 "
                    rules={[
                      { required: false, message: "Please input Address" },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                  <Form.Item
                    name="city"
                    label="City"
                    className="col-md-3 col-sm-6 col-xs-12"
                    rules={[{ required: false, message: "Please input City" }]}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                  <Form.Item
                    name="state"
                    label="State"
                    className="col-md-3 col-sm-6 col-xs-12 "
                    rules={[{ required: false, message: "Please input State" }]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select State"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                       {this.renderstateshort()}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="zip"
                    label="ZIP"
                    className="col-md-3 col-sm-6 col-xs-12 "
                    rules={[
                      {
                        required: false,
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input 	ZIP",
                      },
                    ]}
                  >
                    <Input maxLength={5} placeholder="ZIP" />
                  </Form.Item>
                  <br />
                  <br />
                  <div className="col-md-12 col-sm-12 col-xs-12 padd15">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={loading}
                    >
                      Create User
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.auth.auth, data: state.data };
}

export default connect(mapStateToProps, {
  getretailers,
  getibomarketlist,
  getRolelist,
  getMarketerslist,
  getOfficelist,
  createEmployee,
  getofficelistname,
  short_us_states
})(AddEmployee);
