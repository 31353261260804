/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { isMobile } from "react-device-detect";

/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Inventory from "../../components/Inventory/Inventory";
import InventoryByManager from "../../components/Inventory/InventoryByManager";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const [marketmanagerview, setMarketmanagerview] = useState(false);
  const [officemanagerview, setofficemanagerview] = useState(false);
  const [inventorymanagerview, setInventorymanagerview] = useState(false);
  const [iboinventorymanager, setIBOInventorymanagerview] = useState(false);
  const [currentPath, setcurrentPath] = useState("");
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  let location = useLocation();
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    console.log(` props?.auth?.role_id?`, props?.auth?.role_id);
    const marketmanager =
      props?.auth?.role_id?.filter((word) => word === 3) ?? [];
    if (marketmanager?.length !== 0) {
      setMarketmanagerview(true);
    }
    const inventorymanager =
      props?.auth?.role_id?.filter((word) => word === 11 || word === 12) ?? [];
    if (inventorymanager?.length !== 0) {
      setInventorymanagerview(true);
    }
    const officemanager =
      props?.auth?.role_id?.filter((word) => word === 3) ?? [];
    if (officemanager?.length !== 0) {
      setofficemanagerview(true);
    }
    const iboinventorymanager =
      props?.auth?.role_id?.filter((word) => word === 11) ?? [];
    if (iboinventorymanager?.length !== 0) {
      setIBOInventorymanagerview(true);
    }
  }, []);

  let scrollPosition = useScrollPosition();
  /// Path

  useEffect(() => {
    let path = location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    setcurrentPath(path);
  }, [location.pathname]);

  /// Active menu
  let deshBoard = [""],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    support = ["support"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-media-object",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
    redux = [
      "summary",
      "ReceiveInventory",
      // "Inventory",
      // "Agent",
      // "Office",
      "assignagent",
      "transferdevice",
      "TransferRequest",
    ],
    ReceiveInventory = ["ReceiveInventory"],
    widget = ["Inventory"],
    Agent = ["Agent"],
    Office = ["Office"],
    AssignAgent = ["assignagent"],
    TransferDevice = ["transferdevice"],
    TransferRequest = ["TransferRequest"],
    carton = ["IMEI"],
    compliance = ["compliancedocument"],
    transaction = ["Transaction"],
    softcount = ["SoftCount"],
    forms = [
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
    ],
    table = ["table-bootstrap-basic", "table-datatable-basic"],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ],
    manageagent = ["sendinvite", "onboardingreport", "agentreport"],
    Menusales = [
      "salesreport",
      "salesreportoffice",
      "salesreportagent",
      "salesreportmanagers",
      "salesreportmarkets",
      "LegacyData",
    ],
    Menudocument = ["documents"],
    InventoryReport = [
      "InventoryDetailedReport",
      "InventoryByIbo",
      "Inventory",
      "Agent",
      "InventoryByManager",
      "Office",
    ],
    AgedInventoryReport = [
      "agedbyagent",
      "agedbymanager",
      "agedinventorymarket",
      "agedinventoryoffice",
    ],
    Administration = [
      "employee",
      "editemployee",
      "addemployee",
      "marketers",
      "editmarketers",
      "addmarketers",
      "offices",
      "editoffice",
      "addoffice",
    ],
    RMAorder = [
      "rmapendingrequest",
      "rmaapprovedrequest",
      "rmasubmitrequest",
      "approvedrma",
      "rmarequest",
      "orderinventory",
      "rmainitiaterequest",
      "rmarequeststatus",
    ],
    widgett = ["support"],
    announce = ["announcement"],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];
  const handleclick = () => {
    if (isMobile) {
      var aaa = document.querySelector("#main-wrapper");
      console.log(aaa);
      function toggleFunc() {
        return aaa.classList.toggle("menu-toggle");
      }
      toggleFunc();
    }
  };
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li
            onClick={handleclick}
            className={`${deshBoard.includes(currentPath) ? "mm-active" : ""}`}
          >
            <Link className="ai-icon" to="">
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li
            onClick={handleclick}
            className={`${announce.includes(currentPath) ? "mm-active" : ""}`}
          >
            <Link to="announcement" className="ai-icon">
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text">Announcements</span>
            </Link>
          </li>
          <li
            className={`${Menusales.includes(currentPath) ? "mm-active" : ""}`}
          >
            <Link className="has-arrow ai-icon" to="/salesreport">
              <i className="flaticon-041-graph"></i>
              <span className="nav-text">Sales </span>
            </Link>
            <ul
              className={`mm-collapse ${
                Menusales.includes(currentPath) ? "mm-show" : ""
              }`}
            >
              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "salesreport" ? "mm-active" : ""
                  }`}
                  to="/salesreport"
                >
                  Detailed Report
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "LegacyData" ? "mm-active" : ""
                  }`}
                  to="/LegacyData"
                >
                  Legacy Data
                </Link>
              </li>
              {iboinventorymanager === true ||
              officemanagerview === true ? null : (
                <li>
                  <Link
                    onClick={handleclick}
                    className={`${
                      currentPath === "salesreportmarkets" ? "mm-active" : ""
                    }`}
                    to="/salesreportmarkets"
                  >
                    By IBOs/Markets
                  </Link>
                </li>
              )}
              
              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "salesreportoffice" ? "mm-active" : ""
                  }`}
                  to="/salesreportoffice"
                >
                  By Offices
                </Link>
              </li>
              {officemanagerview === true ? null : (
                <li>
                  <Link
                    onClick={handleclick}
                    className={`${
                      currentPath === "salesreportmanagers" ? "mm-active" : ""
                    }`}
                    to="/salesreportmanagers"
                  >
                    By Managers
                  </Link>
                </li>
              )}

              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "salesreportagent" ? "mm-active" : ""
                  }`}
                  to="/salesreportagent"
                >
                  By Agents
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${redux.includes(currentPath) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="">
              <i className="flaticon-041-graph"></i>
              <span className="nav-text">Inventory</span>
            </Link>
            <ul
              className={`mm-collapse ${
                redux.includes(currentPath) ? "mm-show" : ""
              }`}
            >
              <li>
                <Link
                  onClick={handleclick}
                  className={`${currentPath === "summary" ? "mm-active" : ""}`}
                  to="/summary"
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "ReceiveInventory" ? "mm-active" : ""
                  }`}
                  to="/ReceiveInventory"
                >
                  Receive Inventory
                </Link>
              </li>

              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "assignagent" ? "mm-active" : ""
                  }`}
                  to="/assignagent"
                >
                  Assign Inventory
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "transferdevice" ? "mm-active" : ""
                  }`}
                  to="/transferdevice"
                >
                  Transfer Inventory
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "TransferRequest" ? "mm-active" : ""
                  }`}
                  to="/TransferRequest"
                >
                  Accept Transfer
                </Link>
              </li>
            </ul>
          </li>
          <li
            className={`${
              InventoryReport.includes(currentPath) ? "mm-active" : ""
            }`}
          >
            <Link className="has-arrow ai-icon" to="">
              <i className="flaticon-041-graph"></i>
              <span className="nav-text">Inventory Reports </span>
            </Link>
            <ul
              className={`mm-collapse ${
                InventoryReport.includes(currentPath) ? "mm-show" : ""
              }`}
            >
              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "InventoryDetailedReport" ? "mm-active" : ""
                  }`}
                  to="/InventoryDetailedReport"
                >
                  Inventory Detailed Report
                </Link>
              </li>
              {inventorymanagerview === true ? null : (
              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "Inventory" ? "mm-active" : ""
                  }`}
                  to="/Inventory"
                >
                  My Inventory
                </Link>
              </li>
              )}
              {iboinventorymanager === true ||
              officemanagerview === true ? null : (
                <li>
                  <Link
                    onClick={handleclick}
                    className={`${
                      currentPath === "InventoryByIbo" ? "mm-active" : ""
                    }`}
                    to="/InventoryByIbo"
                  >
                    By IBOs/Markets
                  </Link>
                </li>
              )}

              <li>
                <Link
                  onClick={handleclick}
                  className={`${currentPath === "Office" ? "mm-active" : ""}`}
                  to="/Office"
                >
                  By Offices
                </Link>
              </li>

              {officemanagerview === true ? null : (
                <li>
                  <Link
                    onClick={handleclick}
                    className={`${
                      currentPath === "InventoryByManager" ? "mm-active" : ""
                    }`}
                    to="/InventoryByManager"
                  >
                    By Managers
                  </Link>
                </li>
              )}
              <li>
                <Link
                  onClick={handleclick}
                  className={`${currentPath === "Agent" ? "mm-active" : ""}`}
                  to="/Agent"
                >
                  By Agents
                </Link>
              </li>
            </ul>
          </li>
          {/* {inventorymanagerview === true ? ( */}
            <>
              <li
                onClick={handleclick}
                className={`${
                  AgedInventoryReport.includes(currentPath) ? "mm-active" : ""
                }`}
              >
                <Link className="has-arrow ai-icon" to="/agedinventoryoffice">
                  <i className="flaticon-041-graph"></i>
                  <span className="nav-text">Aged Inventory </span>
                </Link>
                <ul
                  className={`mm-collapse ${
                    AgedInventoryReport.includes(currentPath) ? "mm-show" : ""
                  }`}
                >
                  {iboinventorymanager === true || officemanagerview === true  ? null : (
                    <li>
                      <Link
                        onClick={handleclick}
                        className={`${
                          currentPath === "agedinventorymarket"
                            ? "mm-active"
                            : ""
                        }`}
                        to="/agedinventorymarket"
                      >
                        Aged By IBOs /Markets
                      </Link>
                    </li>
                  )}
                  {/* {officemanagerview === true ? null : ( */}
                  <li>
                    <Link
                      onClick={handleclick}
                      className={`${
                        currentPath === "agedinventoryoffice" ? "mm-active" : ""
                      }`}
                      to="/agedinventoryoffice"
                    >
                      Aged By Office
                    </Link>
                  </li>
                  {/* )} */}
                   {officemanagerview === true ? null : (
                  <li>
                    <Link
                      onClick={handleclick}
                      className={`${
                        currentPath === "agedbymanager" ? "mm-active" : ""
                      }`}
                      to="/agedbymanager"
                    >
                      Aged By Managers
                    </Link>
                  </li>
                   )}
                  <li>
                    <Link
                      onClick={handleclick}
                      className={`${
                        currentPath === "agedbyagent" ? "mm-active" : ""
                      }`}
                      to="/agedbyagent"
                    >
                      Aged By Agents
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          {/* ) : null} */}
          <li
            className={`${RMAorder.includes(currentPath) ? "mm-active" : ""}`}
          >
            <Link className="has-arrow ai-icon" to="">
              <i className="flaticon-041-graph"></i>
              <span className="nav-text">RMA & Orders </span>
            </Link>
            <ul
              className={`mm-collapse ${
                RMAorder.includes(currentPath) ? "mm-show" : ""
              }`}
            >
              {marketmanagerview === true ? (
                <>
                  <li>
                    <Link
                      onClick={handleclick}
                      className={`${
                        currentPath === "rmainitiaterequest" ? "mm-active" : ""
                      }`}
                      to="/rmainitiaterequest"
                    >
                      Initiate RMA
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={handleclick}
                      className={`${
                        currentPath === "rmasubmitrequest" ? "mm-active" : ""
                      }`}
                      to="/rmasubmitrequest"
                    >
                      Submit RMA
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={handleclick}
                      className={`${
                        currentPath === "rmaapprovedrequest" ? "mm-active" : ""
                      }`}
                      to="/rmaapprovedrequest"
                    >
                      RMA Status
                    </Link>
                  </li>
                </>
              ) : null}

              {inventorymanagerview === true ? (
                <>
                  <li>
                    <Link
                      onClick={handleclick}
                      className={`${
                        currentPath === "rmainitiaterequest" ? "mm-active" : ""
                      }`}
                      to="/rmainitiaterequest"
                    >
                      Initiate RMA
                    </Link>
                  </li>

                  <li>
                    <Link
                      onClick={handleclick}
                      className={`${
                        currentPath === "rmasubmitrequest" ? "mm-active" : ""
                      }`}
                      to="/rmasubmitrequest"
                    >
                      Submit RMA
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={handleclick}
                      className={`${
                        currentPath === "rmaapprovedrequest" ? "mm-active" : ""
                      }`}
                      to="/rmaapprovedrequest"
                    >
                      RMA Status
                    </Link>
                  </li>
                </>
              ) : null}
              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "orderinventory" ? "mm-active" : ""
                  }`}
                  to="/orderinventory"
                >
                  Order Management
                </Link>
              </li>
            </ul>
          </li>
          <li
            className={`${
              manageagent.includes(currentPath) ? "mm-active" : ""
            }`}
          >
            <Link className="has-arrow ai-icon" to="">
              <i className="flaticon-041-graph"></i>
              <span className="nav-text">Onboarding</span>
            </Link>
            <ul
              className={`mm-collapse ${
                manageagent.includes(currentPath) ? "mm-show" : ""
              }`}
            >
              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "sendinvite" ? "mm-active" : ""
                  }`}
                  to="/sendinvite"
                >
                  Send Invite
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "onboardingreport" ? "mm-active" : ""
                  }`}
                  to="/onboardingreport"
                >
                  Onboarding Summary
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleclick}
                  className={`${
                    currentPath === "agentreport" ? "mm-active" : ""
                  }`}
                  to="/agentreport"
                >
                  Agent Summary
                </Link>
              </li>
            </ul>
          </li>

          {inventorymanagerview === true ? (
            <>
              <li
                onClick={handleclick}
                className={`${
                  Administration.includes(currentPath) ? "mm-active" : ""
                }`}
              >
                <Link className="has-arrow ai-icon" to="/employee">
                  <i className="flaticon-041-graph"></i>
                  <span className="nav-text">Administration </span>
                </Link>
                <ul
                  className={`mm-collapse ${
                    Administration.includes(currentPath) ? "mm-show" : ""
                  }`}
                >
                  {iboinventorymanager === true ? null : (
                    <li>
                      <Link
                        onClick={handleclick}
                        className={`${
                          currentPath === "marketers" ? "mm-active" : ""
                        }`}
                        to="/marketers"
                      >
                        IBOs /Markets
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link
                      onClick={handleclick}
                      className={`${
                        currentPath === "offices" ? "mm-active" : ""
                      }`}
                      to="/offices"
                    >
                      Offices
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={handleclick}
                      className={`${
                        currentPath === "employee" ? "mm-active" : ""
                      }`}
                      to="/employee"
                    >
                      Users
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          ) : null}

          {/* {marketmanagerview === true ? (
            <li
              onClick={handleclick}
              className={`${
                Menudocument.includes(currentPath) ? "mm-active" : ""
              }`}
            >
              <Link className="ai-icon" to="/documents">
                <i className="flaticon-041-graph"></i>
                <span className="nav-text">Documents</span>
              </Link>
            </li>
          ) : null} */}

          <li
            onClick={handleclick}
            className={`${widgett.includes(currentPath) ? "mm-active" : ""}`}
          >
            <Link to="support" className="ai-icon">
              <i className="flaticon-041-graph"></i>
              <span className="nav-text">Support</span>
            </Link>
          </li>
          {/* <li
            onClick={handleclick}
            className={`${support.includes(currentPath) ? "mm-active" : ""}`}
          >
            <Link to="support" className="ai-icon">
              <i className="flaticon-381-smartphone-4"></i>
              <span className="nav-text">Support</span>
            </Link>
          </li> */}
          {/* <li
            onClick={handleclick}
            className={`${ReceiveInventory.includes(currentPath) ? "mm-active" : ""}`}
          >
            <Link to="ReceiveInventory" className="ai-icon">
              <i className="flaticon-381-smartphone-4"></i>
              <span className="nav-text">Receive Inventory</span>
            </Link>
          </li>
          <li
            onClick={handleclick}
            className={`${widget.includes(path) ? "mm-active" : ""}`}
          >
            <Link to="Inventory" className="ai-icon">
              <i className="flaticon-381-smartphone-4"></i>
              <span className="nav-text">My Inventory</span>
            </Link>
          </li>
          <li
            onClick={handleclick}
            className={`${Agent.includes(path) ? "mm-active" : ""}`}
          >
            <Link to="Agent" className="ai-icon">
              <i className="flaticon-381-user-7"></i>
              <span className="nav-text">My Agent</span>
            </Link>
          </li>
          <li
            onClick={handleclick}
            className={`${Office.includes(path) ? "mm-active" : ""}`}
          >
            <Link to="Office" className="ai-icon">
              <i className="flaticon-381-user-7"></i>
              <span className="nav-text">My Office</span>
            </Link>
          </li>
          <li
            onClick={handleclick}
            className={`${AssignAgent.includes(path) ? "mm-active" : ""}`}
          >
            <Link to="assignagent" className="ai-icon">
              <i className="flaticon-381-smartphone-4"></i>
              <span className="nav-text">Assign Agent</span>
            </Link>
          </li>
          <li
            onClick={handleclick}
            className={`${TransferDevice.includes(path) ? "mm-active" : ""}`}
          >
            <Link to="transferdevice" className="ai-icon">
              <i className="flaticon-381-smartphone-4"></i>
              <span className="nav-text">Transfer Device </span>
            </Link>
          </li>
          <li
            onClick={handleclick}
            className={`${TransferRequest.includes(path) ? "mm-active" : ""}`}
          >
            <Link to="TransferRequest" className="ai-icon">
              <i className="flaticon-381-smartphone-4"></i>
              <span className="nav-text">Transfer Request</span>
            </Link>
          </li> */}
          {/* <li
            onClick={handleclick}
            className={`${carton.includes(path) ? "mm-active" : ""}`}
          >
            <Link to="IMEI" className="ai-icon">
              <i className="flaticon-381-smartphone-4"></i>
              <span className="nav-text">Scan Inventory</span>
            </Link>
          </li>
          <li
            onClick={handleclick}
            className={`${compliance.includes(path) ? "mm-active" : ""}`}
          >
            <Link to="compliancedocument" className="ai-icon">
              <i className="flaticon-381-notebook-5"></i>
              <span className="nav-text">Document</span>
            </Link>
          </li> */}
          {/* <li
            onClick={handleclick}
            className={`${transaction.includes(path) ? "mm-active" : ""}`}
          >
            <Link to="Transaction" className="ai-icon">
              <i className="flaticon-381-smartphone-4"></i>
              <span className="nav-text">Transaction History</span>
            </Link>
          </li> */}
          {/* <li className={`${softcount.includes(path) ? "mm-active" : ""}`}>
            <Link to="SoftCount" className="ai-icon">
              <i className="flaticon-013-checkmark"></i>
              <span className="nav-text">Soft Count</span>
            </Link>
          </li> */}

          {/* <ul >
              <li><Link className={`${path === "" ? "mm-active" : "dashboard"}`} to="/dashboard"> Dashboard Light</Link></li>
				<li>
					<Link className={`${path === "dashboard-dark" ? "mm-active" : ""}`} to="/dashboard-dark"> 
						Dashboard Dark
					</Link>
				</li>
				
				<li><Link className={`${path === "guest-list" ? "mm-active" : ""}`} to="/guest-list">Guest</Link></li>
				<li><Link className={`${path === "guest-detail" ? "mm-active" : ""}`} to="/guest-detail">Guest Detail</Link></li>
				<li><Link className={`${path === "concierge" ? "mm-active" : ""}`} to="/concierge">Concierge</Link></li>
				<li><Link className={`${path === "room-list" ? "mm-active" : ""}`} to="/room-list">Room</Link></li>
				<li><Link className={`${path === "reviews" ? "mm-active" : ""}`} to="/reviews">Reviews</Link></li>
				<li><Link className={`${path === "task" ? "mm-active" : ""}`} to="/task">Task</Link></li>
            </ul> */}

          {/* <li className={`${app.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-050-info"></i>
              <span className="nav-text">Apps</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "app-profile" ? "mm-active" : ""}`}
                  to="/app-profile"
                >
                  Profile
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "post-details" ? "mm-active" : ""}`}
                  to="/post-details"
                >
                  Post Details
                </Link>
              </li>
              <li className={`${email.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="#">
                  Email
                </Link>
                <ul className={`${email.includes(path) ? "mm-show" : ""}`}>
                  <li>
                    <Link
                      className={`${
                        path === "email-compose" ? "mm-active" : ""
                      }`}
                      to="/email-compose"
                    >
                      Compose
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${path === "email-inbox" ? "mm-active" : ""}`}
                      to="/email-inbox"
                    >
                      Inbox
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${path === "email-read" ? "mm-active" : ""}`}
                      to="/email-read"
                    >
                      Read
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  className={`${path === "app-calender" ? "mm-active" : ""}`}
                  to="/app-calender"
                >
                  Calendar
                </Link>
              </li>
              <li className={`${shop.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="#">
                  Shop
                </Link>
                <ul className={`${shop.includes(path) ? "mm-show" : ""}`}>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-product-grid" ? "mm-active" : ""
                      }`}
                      to="/ecom-product-grid"
                    >
                      Product Grid
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-product-list" ? "mm-active" : ""
                      }`}
                      to="/ecom-product-list"
                    >
                      Product List
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-product-detail" ? "mm-active" : ""
                      }`}
                      to="/ecom-product-detail"
                    >
                      Product Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-product-order" ? "mm-active" : ""
                      }`}
                      to="/ecom-product-order"
                    >
                      Order
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-checkout" ? "mm-active" : ""
                      }`}
                      to="/ecom-checkout"
                    >
                      Checkout
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-invoice" ? "mm-active" : ""
                      }`}
                      to="/ecom-invoice"
                    >
                      Invoice
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-customers" ? "mm-active" : ""
                      }`}
                      to="/ecom-customers"
                    >
                      Customers
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className={`${charts.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-041-graph"></i>
              <span className="nav-text">Charts</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "chart-rechart" ? "mm-active" : ""}`}
                  to="/chart-rechart"
                >
                  RechartJs
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "chart-chartjs" ? "mm-active" : ""}`}
                  to="/chart-chartjs"
                >
                  Chartjs
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "chart-chartist" ? "mm-active" : ""}`}
                  to="/chart-chartist"
                >
                  Chartist
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "chart-sparkline" ? "mm-active" : ""}`}
                  to="/chart-sparkline"
                >
                  Sparkline
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "chart-apexchart" ? "mm-active" : ""}`}
                  to="/chart-apexchart"
                >
                  Apexchart
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-086-star"></i>
              <span className="nav-text">Bootstrap</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "ui-accordion" ? "mm-active" : ""}`}
                  to="/ui-accordion"
                >
                  Accordion
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-alert" ? "mm-active" : ""}`}
                  to="/ui-alert"
                >
                  Alert
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-badge" ? "mm-active" : ""}`}
                  to="/ui-badge"
                >
                  Badge
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-button" ? "mm-active" : ""}`}
                  to="/ui-button"
                >
                  Button
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-modal" ? "mm-active" : ""}`}
                  to="/ui-modal"
                >
                  Modal
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-button-group" ? "mm-active" : ""}`}
                  to="/ui-button-group"
                >
                  Button Group
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-list-group" ? "mm-active" : ""}`}
                  to="/ui-list-group"
                >
                  List Group
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-card" ? "mm-active" : ""}`}
                  to="/ui-card"
                >
                  Cards
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-carousel" ? "mm-active" : ""}`}
                  to="/ui-carousel"
                >
                  Carousel
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-dropdown" ? "mm-active" : ""}`}
                  to="/ui-dropdown"
                >
                  Dropdown
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-popover" ? "mm-active" : ""}`}
                  to="/ui-popover"
                >
                  Popover
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-progressbar" ? "mm-active" : ""}`}
                  to="/ui-progressbar"
                >
                  Progressbar
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-tab" ? "mm-active" : ""}`}
                  to="/ui-tab"
                >
                  Tab
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-typography" ? "mm-active" : ""}`}
                  to="/ui-typography"
                >
                  Typography
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-pagination" ? "mm-active" : ""}`}
                  to="/ui-pagination"
                >
                  Pagination
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-grid" ? "mm-active" : ""}`}
                  to="/ui-grid"
                >
                  Grid
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-045-heart"></i>
              <span className="nav-text">Plugins</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "uc-select2" ? "mm-active" : ""}`}
                  to="/uc-select2"
                >
                  Select 2
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "uc-nestable" ? "mm-active" : ""}`}
                  to="/uc-nestable"
                >
                  Nestedable
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "uc-noui-slider" ? "mm-active" : ""}`}
                  to="/uc-noui-slider"
                >
                  Noui Slider
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "uc-sweetalert" ? "mm-active" : ""}`}
                  to="/uc-sweetalert"
                >
                  Sweet Alert
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "uc-toastr" ? "mm-active" : ""}`}
                  to="/uc-toastr"
                >
                  Toastr
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "map-jqvmap" ? "mm-active" : ""}`}
                  to="/map-jqvmap"
                >
                  Jqv Map
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "uc-lightgallery" ? "mm-active" : ""}`}
                  to="/uc-lightgallery"
                >
                  Light Gallery
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${redux.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-087-stop"></i>
              <span className="nav-text">Redux</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "todo" ? "mm-active" : ""}`}
                  to="/todo"
                >
                  Todo
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "redux-form" ? "mm-active" : ""}`}
                  to="/redux-form"
                >
                  Redux Form
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "redux-wizard" ? "mm-active" : ""}`}
                  to="/redux-wizard"
                >
                  Redux Wizard
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
            <Link to="widget-basic" className="ai-icon">
              <i className="flaticon-013-checkmark"></i>
              <span className="nav-text">Widget</span>
            </Link>
          </li>
          <li className={`${forms.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-072-printer"></i>
              <span className="nav-text forms">Forms</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "form-element" ? "mm-active" : ""}`}
                  to="/form-element"
                >
                  Form Elements
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "form-wizard" ? "mm-active" : ""}`}
                  to="/form-wizard"
                >
                  Wizard
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "form-editor-summernote" ? "mm-active" : ""
                  }`}
                  to="/form-editor-summernote"
                >
                  Summernote
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "form-pickers" ? "mm-active" : ""}`}
                  to="/form-pickers"
                >
                  Pickers
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "form-validation-jquery" ? "mm-active" : ""
                  }`}
                  to="/form-validation-jquery"
                >
                  Form Validate
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${table.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-043-menu"></i>
              <span className="nav-text">Table</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "table-filtering" ? "mm-active" : ""}`}
                  to="/table-filtering"
                >
                  Table Filtering
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "table-sorting" ? "mm-active" : ""}`}
                  to="/table-sorting"
                >
                  Table Sorting
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "table-bootstrap-basic" ? "mm-active" : ""
                  }`}
                  to="/table-bootstrap-basic"
                >
                  Bootstrap
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "table-datatable-basic" ? "mm-active" : ""
                  }`}
                  to="/table-datatable-basic"
                >
                  Datatable
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${pages.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-022-copy"></i>
              <span className="nav-text">Pages</span>
            </Link>
            <ul>
              <li className={`${error.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="#">
                  Error
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${
                        path === "page-error-404" ? "mm-active" : ""
                      }`}
                      to="/page-error-404"
                    >
                      Error 404
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li> */}
        </MM>
        {/* <div className="copyright"> */}
        {/* <p>
            <strong>CJ Grant Sales Solutions</strong> © 2022 All Rights Reserved
          </p> */}
        {/* <p className="fs-12">
            Made with <span className="heart"></span> by Infinitelinked Solutions Pvt Ltd
          </p> */}
        {/* </div> */}
      </PerfectScrollbar>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return { auth: state.auth.auth };
}

export default connect(mapStateToProps, {})(SideBar);
