import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";
import { Link } from "react-router-dom";
import { Spin,Button } from "antd";

import logo from "../../images/logo-full.png";
import loginbg from "../../images/pic2.svg";

function Login(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setEmail(localStorage.getItem("Agent_Email"));
  }, []);

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoading(true);
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props.history))
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const handleEmail = (e) => {
    setEmail(e);
    localStorage.setItem("Agent_Email", e);
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="login-aside text-center  d-flex flex-column flex-row-auto">
            <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
              <div className="text-center mb-4 pt-5">
                <img
                  src={logo}
                  alt=""
                  className="img-fluid"
                  style={{ height: 220 }}
                ></img>
              </div>
              <h3 className="mb-2">Welcome Manager!</h3>
              <p>
                Work together, no matter . <br />
                where you are
              </p>
            </div>
            <div
              className="aside-image"
              style={{ backgroundImage: "url(" + loginbg + ")" }}
            ></div>
          </div>
          <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
            <div className="d-flex justify-content-center h-100 align-items-center">
              <div className="authincation-content style-2">
                <div className="row no-gutters">
                  <div className="col-xl-12 tab-content">
                    <div id="sign-in" className="auth-form   form-validation">
                      {props.errorMessage && (
                        <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                          {props.errorMessage}
                        </div>
                      )}
                      {props.successMessage && (
                        <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                          {props.successMessage}
                        </div>
                      )}
                      <form onSubmit={onLogin} className="form-validate">
                        <h3 className="text-center mb-4 text-black">
                          Sign in your Manager Application
                        </h3>
                        <div className="form-group mb-3">
                          <label className="mb-1" htmlFor="val-email">
                            <strong>Email</strong>
                          </label>
                          <div>
                            <input
                              type="email"
                              className="form-control"
                              value={email}
                              onChange={(e) => handleEmail(e.target.value)}
                              placeholder="Type Your Email"
                            />
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.email && (
                              <div className="text-danger fs-12">
                                {errors.email}
                              </div>
                            )}
                          </div>
                        </div>
                        <label className="text-label">Password *</label>
                        <div className="input-group transparent-append mb-2">
                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            value={password}
                            placeholder="Type Your Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div
                            className="input-group-text"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <i
                              className={`${
                                showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                              }`}
                            />
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.password && (
                              <div className="text-danger fs-12">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-row d-flex justify-content-between mt-4 mb-2">
                          <div className="form-group mb-3">
                            <div className="custom-control custom-checkbox ml-1">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="basic_checkbox_1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="basic_checkbox_1"
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            {" "}
                            <div className="new-account">
                              <p>
                                <Link
                                  className="text-primary"
                                  to="/page-forgot-password"
                                >
                                  Forgot Password ?
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="text-center form-group mb-3">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Sign In
                          </button>
                        </div>
                        <div className="mt-2 row d-flex justify-content-center">
                          <div className="col-12 text-center">Click the button below to access the agent application</div>
                          <div className="col-6 mt-2 text-center"><a alt="" href="https://agent.wiessolution.com"><Button size="small" type="primary">Click Here</Button></a></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
