/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table,
  Tag,
  Button,
  DatePicker,
  Input,
  Select,
  message,
  Empty,
  Spin,
  Modal,
  List,
  Divider,
  Tooltip,
  Drawer,
  Form,
  Row,
  Col,
  Avatar,
} from "antd";  
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  Link,
} from "react-router-dom";
import moment from "moment";
import _, { set } from "lodash";
import {
  onboarding_email_status,
  resendinviteusernew,
  resendreminderuser,
  onboardinglog,
  onboarding_summary_report,
  onboarding_status,
  searchonboardingreportlist,
  resendinviteuser,
  getofficelistname,
  exportsearchonboardingreportlist,
  viewcomments_user_Onboarding_candidates_action,
  send_custom_email_user,
  send_invite_delete_action,
  rad_pii_issue_status,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import {
  UploadOutlined,
  DownloadOutlined,
  EyeOutlined,
  NotificationOutlined,
  EditOutlined,
  MailOutlined,
  MobileOutlined,
  HistoryOutlined,
  DeleteOutlined ,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { CheckCircle, CloseCircle } from '@ant-design/icons';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "antd/dist/antd.min.css";
import axiosInstance from "../../../services/AxiosAdmin";
import { getibomarketlist,onboarding_status_sorce } from "../../../store/actions/documentActions";

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = "MM-DD-YYYY";

const OnboardingReport = (props) => {
  let location = useLocation();
  let onboarding_data = JSON.parse(sessionStorage.getItem("onboarding_data"));
  const [form] = Form.useForm();
  const [office, setoffice] = useState();
  const [emailstatus, setemailstatus] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [btnloading, setbtnLoading] = useState(false);
  const [selectedstatus, setselectedstatus] = useState([]);
  const [selectedoffice, setselectedoffice] = useState([]);
  const [keyword, setkeyword] = useState("");
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [endOpen, setEndOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndtDate] = useState(null);
  const [onboarding, setonboarding] = useState([]);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [loadingSBTbtn, setloadingSBTbtn] = useState(false);
  const [selectedItems, setselactedItems] = useState([]);
  const [selectedItems1, setselactedItems1] = useState([]);
  const [manager,setManager] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fetching, setfetching] = useState(false);
  const [cvisible, setcvisible] = useState(false);
  const [lvisible, setlvisible] = useState(false);
  const [Listdata, setListdata] = useState([]);
  const [Listdata1, setListdata1] = useState([]);
  const [sendmailview, setsendmailview] = useState(false);
  const [activeedit, setactiveedit] = useState([]);
  const [managerview, setmanagerview] = useState(false);
  const [inventorymanagerview, setInventorymanagerview] = useState(false);
  const [iboinventorymanager, setIBOInventorymanagerview] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pickerKey, setPickerKey] = useState(0);
  const [rad_issues, setrad_issues]=useState([]);
  const [selectedsorcestatus, setSelectedsorcestatus] = useState([]);
  const [onboardingSorce, setOnboardingSorce] = useState([]);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: onboarding_data === null ? 1 : onboarding_data.page ?? 1,
      pageSize: 10,
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getibomarketlist()).then((res) => {
      console.log(res.data);
     setManager(res.data);
    });
    dispatch(onboarding_status_sorce()).then((res) => {
      console.log(res.data);
      setOnboardingSorce(res.data);
    });
    dispatch(onboarding_status()).then((res) => {
      console.log(res);
      setonboarding(res.data);
    });
    dispatch(rad_pii_issue_status())
    .then((res) => {
      setrad_issues(res.data)
    })
    .catch((err) => {
      setrad_issues([])
    });
    const marketmanager =
      props?.auth?.role_id?.filter((word) => word === 3) ?? [];
    if (marketmanager?.length !== 0) {
      setmanagerview(true);
    }
    const inventorymanager =
      props?.auth?.role_id?.filter((word) => word === 12) ?? [];
    if (inventorymanager?.length !== 0) {
      setInventorymanagerview(true);
    }
    const iboinventorymanager =
      props?.auth?.role_id?.filter((word) => word === 11) ?? [];
    if (iboinventorymanager?.length !== 0) {
      setIBOInventorymanagerview(true);
    }
    const urlString = location?.state?.urlpath ?? "";
    const urlParams = urlString
      ?.split("?")[1]
      ?.split("&")
      ?.map((v) => v?.split("="));
    const paramsobj = {};
    urlParams?.forEach((element) => {
      paramsobj[element[0]] = element[1];
    });

    dispatch(getofficelistname())
      .then((res) => {
        console.log(res.data);
        setoffice(res.data);
      })
      .catch((err) => {});
    dispatch(onboarding_email_status())
      .then((res) => {
        console.log(res.data);
        setemailstatus(res.data);
      })
      .catch((err) => {});

    console.log(
      parseInt(paramsobj["redirect_key"]) || []
    );

    setselectedstatus(
      parseInt(paramsobj["redirect_key"]) || []
    );
    setkeyword(
      onboarding_data === null ? "" : onboarding_data.search_text ?? ""
    );
    setStartValue(
      onboarding_data === null || onboarding_data.date_range_start === undefined
        ? null
        : onboarding_data.date_range_start === null
        ? null
        : moment(onboarding_data.date_range_start) ?? null
    );
    setEndValue(
      onboarding_data === null || onboarding_data.date_range_end === undefined
        ? null
        : onboarding_data.date_range_end === null
        ? null
        : moment(onboarding_data.date_range_end) ?? null
    );
    setselectedoffice(
      onboarding_data === null ? [] : onboarding_data.office_id ?? []
    );
    fetchData({
      url: urlString ?? "",
      results: 10,
      page: onboarding_data === null ? 1 : onboarding_data.currentpage ?? 1,
      selectedstatus: onboarding_data === null
      ? parseInt(paramsobj["redirect_key"]) || []
        : onboarding_data.status,
      name: onboarding_data === null ? "" : onboarding_data.search_text ?? "",
      startValue:
        onboarding_data === null ||
        onboarding_data.date_range_start === undefined
          ? null
          : onboarding_data.date_range_start === null
          ? null
          : moment(onboarding_data.date_range_start) ?? null,
      endValue:
        onboarding_data === null || onboarding_data.date_range_end === undefined
          ? null
          : onboarding_data.date_range_end === null
          ? null
          : moment(onboarding_data.date_range_end) ?? null,
      selectedoffice:
        onboarding_data === null ? [] : onboarding_data.office_id ?? [],
      sortField: onboarding_data === null ? "" : onboarding_data.sort ?? "",
      sortOrder: onboarding_data === null ? "" : onboarding_data.sort_by ?? "",
    });
    if (!onboarding_data) {
      onboarding_data = {};
    }
    // Update the values in the onboarding_data object
    onboarding_data.status = parseInt(paramsobj["redirect_key"]) || [];

    // Save the updated onboarding_data back to sessionStorage
    sessionStorage.setItem("onboarding_data", JSON.stringify(onboarding_data));
  }, []);

  const resendoption = (params) => {
    setLoading(true);
    dispatch(resendinviteuser(params))
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        setLoading(false);
      });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = (params) => {
    console.log(params.id);
    setLoading(true);
    dispatch(viewcomments_user_Onboarding_candidates_action(params.id))
      .then((res) => {
        const resp = res.data.items;
        console.log(res.data);
        setListdata(resp);
        setIsModalOpen(true);
        // message.success(res.data.message);
        setcvisible(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        setLoading(false);
      });
  };

  const sendemailuser = (params) => {
    setsendmailview(true);
    setactiveedit(params.id);
  };
  const onCloseEmail = () => {
    setsendmailview(false);
  };

  const resendoptionnew = (params) => {
    setLoading(true);
    dispatch(resendinviteusernew(params))
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
        setLoading(false);
      });
  };

  
  
 

  const resendreminder = (params) => {
    setLoading(true);
    dispatch(resendreminderuser(params))
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
        setLoading(false);
      });
  };
  const rendercanlog = (params) => {
    setLoading(true);
    dispatch(onboardinglog(params))
      .then((res) => {
        console.log(res.data.items);
        setListdata1(res.data.items);
        message.success(res.data.message);
        setLoading(false);
        setlvisible(true);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        setLoading(false);
      });
  };
  const onClose = () => {
    setlvisible(false);
  };
  const handleSendEmail = (values) => {
    setLoading(true);
    setloadingSBTbtn(true);

    var obj_arr = {
      onboarding_status: values.email_type,
      email_text: values?.emailcontent ?? "",
    };

    dispatch(send_custom_email_user(obj_arr, activeedit))
      .then((res) => {
        console.log("res", res.data.message);
        message.success(res.data.message);
        setsendmailview(false);
        setLoading(false);
        setloadingSBTbtn(false);
        setactiveedit("");
        form.resetFields();
        fetchData({
          results: 10,
          page: 1,
        });
      })
      .catch((error) => {
        message.error(error.data.message);
        console.log(error);
        setsendmailview(false);
        setLoading(false);
        setloadingSBTbtn(false);
        setactiveedit("");
      });
  };
  const onFinishFailed = (e) => {
    console.log(e);
  };
  const renderonboardingemail = () => {
    return _.map(emailstatus, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };
  const columns = [
    {
      title: "RAD ID",
      dataIndex: "rad_id",
      key: "rad_id",
    },
    {
      title: "IBOs / Markets",
      dataIndex: "ibo_market_name",
      key: "ibo_market_name",
      hidden: inventorymanagerview === true ? false : true,
    },
    {
      title: "RAD / PII Issues",
      dataIndex: "rad_pii_issue_names",
      key: "rad_pii_issue_names",
      width:150,
     align: "center",
      render: (tags) => (
      <>
      {tags !== null &&  (  
      <span>
        {rad_issues.map(function(x){ 
     var result=tags.filter(a1=> a1.id === x.id);
     if(result.length>0)
     {
      return (
        <Tooltip key={x.id} title= {x.name}>
        <CloseCircleFilled  style={{color:"red", marginRight:5, fontSize: '17px', verticalAlign:"middle" }} type="close-circle" theme="filled"   />
        </Tooltip>
      );  
     } 
     else
     {
      return (
        <Tooltip key={x.id} title= {x.name}>
        <CheckCircleFilled  style={{color:"green",fontSize: '17px',  marginRight:5, verticalAlign:"middle"}} type="close-circle" theme="filled"  />
        </Tooltip>
      );  
     }
    })}
       
    </span>) }
      
        </>
      ),
    },
    {
      title: "Office",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Status",
      dataIndex: "status_name",
      key: "status_name",
      width: 320,
      render: (text, record) => (
        <span>
          <Tag color="orange">{record.status_name}</Tag>
        </span>
      ),
    },

    {
      title: "Source",
      dataIndex: "onboarding_source_name",
      key: "onboarding_source_name",
      width: 130,
      render: (text, record) => (
        <span>
          <Tag color="orange">{record.onboarding_source_name}</Tag>
        </span>
      ),
    },
    {
      title: "ETC Code",
      dataIndex: "telgoo_id",
      key: "telgoo_id",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: true,
    },
    {
      title: "Middle Name",
      dataIndex: "middle_name",
      key: "middle_name",
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "SSN Number",
      dataIndex: "masked_ssn_number",
      key: "masked_ssn_number",
      sorter: true,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      sorter: true,
    },
    {
      title: "Address1",
      dataIndex: "street_address1",
      key: "street_address1",
    },
    {
      title: "Address2",
      dataIndex: "street_address2",
      key: "street_address2",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Zip",
      dataIndex: "zip",
      key: "zip",
    },
    {
      title: "Shirt Size",
      dataIndex: "shirt_size",
      key: "shirt_size",
    },
    {
      title: "Profile Picture",
      dataIndex: "photo_copy_url",
      key: "photo_copy_url",
      render: (_, record) => (
        <span>
          {record.photo_copy_url !== null && (
            <a
              href={record?.photo_copy_url ?? ""}
              target="_blank"
              rel="noreferrer"
            >
              <Avatar
                shape="square"
                size={64}
                src={record?.photo_copy_url ?? ""}
              />
            </a>
          )}
        </span>
      ),
    },
    {
      title: "Government ID",
      dataIndex: "government_document_url",
      key: "government_document_url",
      width: 150,
      align: "center",
      render: (_, record) => (
        <span>
          {record.government_document_url !== null && (
            <a
              href={record?.government_document_url ?? ""}
              target="_blank"
              rel="noreferrer"
            >
              <Avatar
                shape="square"
                size={64}
                src={record?.government_document_url ?? ""}
              />
            </a>
          )}
        </span>
      ),
    },
    // {
    //   title: "Comments",
    //   dataIndex: "status_change_comments",
    //   key: "status_change_comments",
    // },
    // {
    //   title: "BGC Description",
    //   dataIndex: "bgc_status",
    //   key: "bgc_status",
    //   width: 150,
    // },
    {
      title: "BGC Acceptance Form",
      dataIndex: "candidate_signed_bgc_url",
      key: "candidate_signed_bgc_url",
      headerAlign: "center",
      width: 150,
      align: "center",
      render: (_, record) => (
        <span>
          {record.candidate_signed_bgc_url !== null ? (
            <span>
              <Tooltip title="Click Here Download">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DownloadOutlined />}
                  onClick={(e) => {
                    window.open(
                      record?.candidate_signed_bgc_url ?? "",
                      "_blank"
                    );
                  }}
                />
              </Tooltip>
            </span>
          ) : (
            ""
          )}
        </span>
      ),
    },
    {
      title: "Retailer Agent Agreement",
      dataIndex: "retailer_agent_agreement_url",
      key: "retailer_agent_agreement_url",
      headerAlign: "center",
      align: "center",
      render: (_, record) => (
        <span>
          {record.retailer_agent_agreement_url !== null ? (
            <span>
              <Tooltip title="Click Here Download">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DownloadOutlined />}
                  onClick={(e) => {
                    window.open(
                      record?.retailer_agent_agreement_url ?? "",
                      "_blank"
                    );
                  }}
                />
              </Tooltip>
            </span>
          ) : (
            ""
          )}
        </span>
      ),
    },
    {
      title: "A01",
      dataIndex: "course_one",
      key: "course_one",
    },

    {
      title: "A02",
      dataIndex: "course_two",
      key: "course_two",
    },

    {
      title: "A03",
      dataIndex: "course_three",
      key: "course_three",
    },

    {
      title: "A04",
      dataIndex: "course_four",
      key: "course_four",
    },

    {
      title: "A05",
      dataIndex: "course_five",
      key: "course_five",
    },
    {
      title: "Lifeline ACP Combo Training",
      dataIndex: "course_six",
      key: "course_six",
    },
    {
      title: "Last Update",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: true,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by_name",
      key: "updated_by_name",
      sorter: true,
    },
    {
      title: "Is Candidate Updated",
      dataIndex: "last_updated_by_name",
      key: "last_updated_by_name",
    },

    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
    },

    // {
    //   title: "Comments Action",
    //   dataIndex: "id",
    //   key: "id",
    //   align: "center",
    //   render: (_, record) => (
    //     <span>
    //       <Button
    //         type="primary"
    //         icon={<EyeOutlined />}
    //         shape="circle"
    //         style={{ fontSize: 11 }}
    //         onClick={() => showModal(record)}
    //       ></Button>
    //     </span>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      fixed: "right",
      width: 300,
      render: (_, record) => (
        <span>
          {record.comments_exists === 0 ? (
            <>
              <Tooltip title="View Comments">
                <Button
                  type="primary"
                  icon={<EyeOutlined />}
                  shape="circle"
                  style={{ fontSize: 11 }}
                  disabled
                ></Button>
              </Tooltip>
              <Divider type="vertical" />
            </>
          ) : (
            <>
              <Tooltip title="View Comments">
                <Button
                  type="primary"
                  icon={<EyeOutlined />}
                  shape="circle"
                  style={{ fontSize: 11 }}
                  onClick={() => showModal(record)}
                ></Button>
              </Tooltip>
              <Divider type="vertical" />
            </>
          )}
          {record.status >= 3 ? (
            record.status !== 13 ? (
              <Tooltip title="Edit Candidate">
                <Link
                  to={{
                    pathname: "/editagent",
                    agent_info: record,
                  }}
                >
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    type="primary"
                  ></Button>
                </Link>
              </Tooltip>
            ) : (
              <Tooltip title="Edit Candidate">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  type="primary"
                  disabled
                ></Button>
              </Tooltip>
            )
          ) : (
            <Tooltip title="Edit Candidate">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                type="primary"
                disabled
              ></Button>
            </Tooltip>
          )}

          <Divider type="vertical" />
          {record.status === 1 ? (
            <Tooltip title="Resend Invite">
              <Button
                shape="circle"
                icon={<MailOutlined />}
                onClick={() => resendoption(record)}
                type="primary"
              ></Button>
            </Tooltip>
          ) : (
            <Tooltip title="Email Reminder">
              <Button
                shape="circle"
                icon={<MailOutlined />}
                onClick={() => resendoptionnew(record)}
                type="primary"
              ></Button>
            </Tooltip>
          )}
          <Divider type="vertical" />
          {record.status !== 13 ? (
            <Tooltip title="SMS Reminder">
              <Button
                shape="circle"
                icon={<MobileOutlined />}
                onClick={() => resendreminder(record)}
                type="primary"
              ></Button>
            </Tooltip>
          ) : (
            <Tooltip title="SMS Reminder">
              <Button
                shape="circle"
                icon={<MobileOutlined />}
                disabled
                type="primary"
              ></Button>
            </Tooltip>
          )}
          <Divider type="vertical" />
          <Tooltip title="View log">
            <Button
              shape="circle"
              icon={<HistoryOutlined />}
              onClick={() => rendercanlog(record)}
              type="primary"
            ></Button>
          </Tooltip>
              <Divider type="vertical" />
                 {record.status === 1  ? (
                  <Tooltip title="Delete Candidate">
                    <Button
                      type="danger"
                      shape="circle"
                      icon={<DeleteOutlined  />}
                      className="padd5 mr5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        console.log(record.id);
                        Modal.confirm({
                          title:
                            "Are you sure you want to Delete Candidate?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: () => {
                            console.log(`Ok`);
                              dispatch(send_invite_delete_action (record.id))
                              .then((res) => {
                                fetchData();
                                // searchonboardingreport();
                                clearSearch();
                                message.success(
                                  "Candidate Deleted Successfully"
                                );
                              })
                              .catch((err) => {
                                message.error(err);
                                console.log(err);
                              });
                          },
                        });
                      }}
                    ></Button>
                  </Tooltip>
                ):( null )}
        </span>
      ),
    },
  ].filter((item) => !item.hidden);

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(onboarding_summary_report(params))
      .then((res) => {
        setLoading(false);
        console.log(res.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // console.log(pagination, filters, sorter);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setTimeout(() => {
      searchonboardingreport("sort", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };

  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
    setselectedstatus(value);
  };
  const onChangeOffice = (value) => {
    console.log(`selected ${value}`);
    setselectedoffice(value);
  };
  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnLoading(true);
    // var sort_by = sortOrder;
    console.log(params);
    var retailer = selectedItems1.value;
    var status = selectedstatus.toString();
    var search_text = keyword;
    var sort = params.order === undefined ? "" : params.field;
    var sort_by =
      params.order === "ascend"
        ? "ASC"
        : params.order === undefined
        ? ""
        : "DESC";
    var date_range_start = startValue;
    var date_range_end = endValue;
    var office_id = selectedItems.key;
    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;
    var source_status = selectedsorcestatus;
    sessionStorage.setItem(
      "onboarding_data",
      JSON.stringify({
        retailer:retailer,
        status: status,
        search_text: search_text,
        page: page ?? 1,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
        office_id,
        source_status,
      })
    );
    if ((date_range_start && date_range_end) || (!date_range_start && !date_range_end))
      {
    dispatch(
      searchonboardingreportlist({
        retailer:retailer,
        status: status,
        search_text: search_text,
        page: page,
        office_id,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
        source_status,
      })
    )
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setbtnLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setbtnLoading(false);
      });
    }
    else
    {
      message.error("Please select both created from and to date");
      setLoading(false);
      setbtnLoading(false);
    }
  };
  const renderOffice = ()=> {
    return manager.map((master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
   // onboarding source status
   const renderonboarding_source_status=() => {
    return onboardingSorce.map( (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };
  const clearSearch = () => {
    sessionStorage.removeItem("onboarding_data");
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    setPickerKey((prevKey) => prevKey + 1);
    setLoading(true);
    setStartValue(null);
    setEndValue(null);
    setStartDate(null);
    setEndtDate(null);
    setkeyword("");
    setselectedstatus([]);
    setselectedoffice([]);
    setselactedItems([]);
    fetchData();
    setfdata([]);
    setselactedItems1([]);
    setSelectedsorcestatus([]);
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var retailer = selectedItems1.value;
    var status = selectedstatus.toString();
    var search_text = keyword;
    var date_range_start = startValue;
    var date_range_end = endValue;
    var office_id = selectedItems.key;
    var source_status = selectedsorcestatus;
    dispatch(
      exportsearchonboardingreportlist({
        retailer:retailer,
        status: status,
        search_text: search_text,
        office_id,
        date_range_start,
        date_range_end,
        source_status,
      })
    )
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        console.log(res);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  const fetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    if (value.length > 1) {
      setfdata([]);
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata1 = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdata(fdata1);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };
  const disabledStartDate = (startValue) => {
    return startValue && startValue > moment().endOf("day");
  };
  const disabledEndDate = (endValue) => {
    return endValue && endValue > moment().endOf("day");
  };
  const onStartChange = (value, date) => {
    console.log(value);
    setStartValue(value);
    setStartDate(date);
  };
  const onEndChange = (value, date) => {
    setEndValue(value);
    setEndtDate(date);
  };
  const handleStartOpenChange = (open) => {
    if (!open) {
      setEndOpen(true);
    }
  };
  const handleEndOpenChange = (open) => {
    setEndOpen(open);
  };

  const onchangeRole1 = (selectedItems1) => {
    console.log(selectedItems1);
    setselactedItems1(selectedItems1);
  };
  const onChangeSorceFilter = (selectedsorcestatus) => {
    console.log(selectedItems1);
    setSelectedsorcestatus(selectedsorcestatus);
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong>Onboarding Summary</strong> */} Onboarding Summary{" "}
              {tableParams.pagination.total &&
                `(${tableParams.pagination.total})`}
            </h4>
            <div className="col-4 d-flex justify-content-end ">
              <Button
                type="primary"
                className="mr5"
                loading={loadingEPbtn}
                onClick={getexportbtn}
              >
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
            </div>
          </div>
          <div className="row mt-2" style={{ padding: "15px" }}>
            { inventorymanagerview && (
              <div className="col-4">
                  <label>Search IBO</label>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    labelInValue
                    placeholder="Select Option"
                   
                    defaultActiveFirstOption={false}
                    value={selectedItems1}
                    onChange={onchangeRole1}
                  >
                   {renderOffice()}
                   </Select>
                </div>
                )}
            <div className="col-sm-12 col-md-4">
              <label>Select Office</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                labelInValue
                placeholder="Type 2 characters to search..!"
                notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={fetchUser}
                defaultActiveFirstOption={false}
                value={selectedItems}
                onChange={onchangeRole}
              >
                {fdata?.map((d) => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
            </div>
            <div className="col-sm-12 col-md-4">
              <label>Select Status</label>
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="Select status"
                value={selectedstatus}
                optionFilterProp="children"
                onChange={onChangeSelect}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={(onboarding || []).map((d) => ({
                  value: d.id,
                  label: d.name,
                }))}
              ></Select>
            </div>
            <div className="col-sm-12 col-md-4  mt-3 ">
              <label style={{ font: "caption" }}>Created From </label>
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={disabledStartDate}
                value={startValue}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={onStartChange}
                onOpenChange={handleStartOpenChange}
              />
            </div>
            <div className="col-sm-12 col-md-4 mt-3">
              <label style={{ font: "caption" }}>Created To</label>
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={disabledEndDate}
                value={endValue}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={onEndChange}
                open={endOpen}
                onOpenChange={handleEndOpenChange}
              />
            </div>
            <div className="col-sm-12 col-md-4 mt-3">
              <label for="exampleFormControlInput1">
                Select Source Status
              </label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={selectedsorcestatus}
                onChange={onChangeSorceFilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {renderonboarding_source_status()}
              </Select>
            </div>   

            <div className="col-sm-12 col-md-4 mt-2">
              <label>Search by keyword</label>
              <Input
                placeholder="Search by keyword"
                value={keyword}
                onChange={(e) => setkeyword(e.target.value)}
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col-sm-12 col-md-12 mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={btnloading}
                onClick={() => searchonboardingreport("reset", "")}
              >
                Search
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                type="default"
                onClick={clearSearch}
              >
                Clear
              </Button>
            </div>
          </div>
          <div className="card-body">
            <Table
              columns={columns}
              size="middle"
              rowKey={(record) => record.key}
              dataSource={data}
              scroll={{ x: 4500 }}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
          <div>
            <Modal
              title="View Comments"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <Button type="primary" onClick={handleCancel}>
                  Close
                </Button>,
              ]}
            >
              {Listdata.length === 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <>
                  {Listdata.map((items, index) => {
                    return (
                      <List.Item key={index}>
                        <List.Item.Meta
                          title={
                            <Tag color="orange">
                              {items.created_at}-{items.commented_by_name}
                            </Tag>
                          }
                          description={items.comments}
                        />
                      </List.Item>
                    );
                  })}
                </>
              )}
            </Modal>
            <Drawer
              title="Send Email Candidate"
              placement="right"
              closable={true}
              width={540}
              onClose={onCloseEmail}
              open={sendmailview}
              bodyStyle={{ paddingBottom: 80 }}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSendEmail}
                onFinishFailed={onFinishFailed}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Email Type"
                      name="email_type"
                      rules={[
                        {
                          required: true,
                          message: "Please select email type",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select type"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {renderonboardingemail()}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16} className="mt-2">
                  <Col span={24}>
                    <Form.Item
                      label="Email Content"
                      name="emailcontent"
                      rules={[
                        {
                          required: true,
                          message: "Please select office",
                        },
                      ]}
                    >
                      <ReactQuill theme="snow" />
                    </Form.Item>
                  </Col>
                </Row>

                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    width: "100%",
                    borderTop: "1px solid #e9e9e9",
                    padding: "10px 16px",
                    background: "#fff",
                    textAlign: "right",
                  }}
                >
                  <Button onClick={onCloseEmail} style={{ marginRight: 8 }}>
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={loadingSBTbtn}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Drawer>
            <Drawer
              title="View Log"
              placement="right"
              closable={true}
              width={420}
              onClose={onClose}
              open={lvisible}
            >
              <List
                itemLayout="horizontal"
                dataSource={Listdata1}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.created_at}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(OnboardingReport);
