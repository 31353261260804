import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getibomarket,
  searchibomarket,
  sendform,
  downloadformapp,
  updateformstatus,
  ibo_market_export,
  marketerdeleteaction,
} from "../../../store/actions/assetAction";
import { UploadOutlined } from "@ant-design/icons";
import {
  ClockCircleOutlined,
  DownloadOutlined,
  EditOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import {
  Button,
  Table,
  Tag,
  Input,
  Select,
  message,
  Modal,
  Divider,
  Tooltip,
  Form,
  Radio,
} from "antd";
const { Option } = Select;
const RadioGroup = Radio.Group;

class Marketer extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      loadingEPbtn: false,
      retailer: [],
      selectedItems: [],
      superrole: "",
      selectedfieldname: [],
      selectedfilter: [],
      sort_list: [],
      selectedRowKeys: [],
      // marketersList: [],
      hasSelected: false,
      retailview: false,
      downloadLoading: false,
      visible: false,
      record: null,
    };
  }

  componentWillMount() {
    const Retailer =
      this.props?.user?.role_id?.filter((word) => word === 12) ?? [];
    if (Retailer?.length !== 0) {
      this.setState({
        retailview: true,
      });
    }

    this.getdesignation({
      results: 10,
      page: 1,
    });
  }

  getdesignation(params = {}) {
    this.setState({ loading: true });
    this.props.getibomarket(params).then((res) => {
      console.log(res);
      const pagination = { ...this.state.pagination };
      pagination.total = res.data.items.total;
      pagination.current = res.data.items.current_page;
      this.setState({
        data: res.data.items.data,
        loading: false,
        pagination,
      });
    });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(sorter.order);
    var status = filter?.status ?? [];
    pager.current = pagination.current;

    this.setState({
      pagination: pager,
      selectedstatus: status.toString(),
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchMarketer();
    }, 100);
  };

  searchMarketer = (reset) => {
    this.setState({ loading: true, loadingbtn: true });
    var status = this.state.selectedstatus;
    var search_text = this.state.name;
    var retailer = this.state.selectedItems;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;

    this.props
      .searchibomarket({
        status: status,
        search_text: search_text,
        retailer: retailer,
        page: reset ? 1 : this.state.pagination?.current ?? 1,
        sort,
        sort_by,
      })
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page;
        this.setState({
          data: res.data.items.data,
          loading: false,
          loadingbtn: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({ loadingbtn: false });
      });
  };

  clearSearch = () => {
    this.setState({ loading: true });
    this.setState({
      status: undefined,
      name: "",
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };

  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
  };
  getrefreshbtn = () => {
    this.clearSearch();
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  onchangefieldname = (selectedfieldname) => {
    this.setState({ selectedfieldname });
    console.log(this.state.selectedfieldname);
  };
  onchangefilter = (selectedfilter) => {
    this.setState({ selectedfilter });
    console.log(this.state.selectedfilter);
  };
  renderSorter() {
    return _.map(this.state.sort_list, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  getexportbtn = () => {
    this.setState({ loading: true, loadingEPbtn: true });
    var search_text = this.state.name;
    var status = this.state.selectedstatus;
    this.props
      .ibo_market_export(search_text,status)
      .then((res) => {
        console.log("res", res);
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };

  onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    if (
      newSelectedRowKeys.length === this.state.data.length &&
      this.state.hasSelected
    ) {
      this.setState({
        selectedRowKeys: [],
        hasSelected: false,
      });
    } else {
      const tempdata = this.state.data;
      var selRow = [];
      // var tempMarketersList = [];
      newSelectedRowKeys.map((selectedRow) => {
        var index = tempdata.findIndex((res) => res.id === selectedRow);
        if (index >= 0) {
          if (
            tempdata[index].form_status === undefined ||
            tempdata[index].form_status === null
          ) {
            selRow.push(selectedRow);
            // tempMarketersList.push(tempdata[index].get_manager_info[0].user_id);
          }
        }
        return selRow;
      });
      this.setState({
        selectedRowKeys: selRow,
        // marketersList: tempMarketersList,
        hasSelected: selRow.length > 0,
      });
    }
  };

  handleSendDocument = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    var ids = this.state.selectedRowKeys?.map(
      (selectedRow, index) => selectedRow
    );
    const params = { type: "ibo_marketer", ids: ids };
    console.log(`params`, params, "\nids", ids);
    this.props
      .sendform(params)
      .then((res) => {
        // console.log(`res`, res)
        message.success(res.data?.message ?? "Document sent successfully");
        this.setState({
          loading: false,
          selectedRowKeys: [],
          hasSelected: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        message.error(err);
      });
  };

  handleDownload = (e, record) => {
    e.preventDefault();
    console.log(`Marketer record`, record);
    this.setState({ downloadLoading: true });
    this.props
      .downloadformapp(record.get_manager_info[0].user_id)
      .then((response) => {
        console.log("download form", response);
        window.open(response?.data?.url ?? "", "_blank");
        this.setState({ downloadLoading: false });
      })
      .catch((ex) => {
        console.log(`error downloading form`, ex);
        this.setState({ downloadLoading: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields, setFields },
    } = this.props;
    console.log(`record`, this.state.record, "form.values", this.props.form);
    validateFields((err, values) => {
      if (!err) {
        const isAccept = this.props.form.getFieldValue("IsAccept");
        const reason = this.props.form.getFieldValue("reason");
        console.log(
          `reason`,
          this.props.form.getFieldValue("IsAccept"),
          this.props.form.getFieldValue("reason")
        );
        var params = {
          id: this.state.record.get_manager_info[0].user_id,
          status: isAccept ? 4 : 3,
        };
        if (!isAccept) {
          params.reason = reason;
        }
        this.props.updateformstatus(params).then((response) => {
          console.log("update form", response);
          message.success(
            response.data?.message ?? "Form updated successfully"
          );
          this.setState({
            loading: false,
            selectedRowKeys: [],
            hasSelected: false,
            visible: false,
          });
          this.clearSearch();
        });
      } else {
        setFields({
          reason: {
            value: this.state.reason,
            errors: [new Error("Enter reason for rejecting")],
          },
        });
      }
    });
  };

  showModal = ({ record }) => {
    this.setState({
      visible: true,
      record: record,
    });
    this.props.form.resetFields();
  };

  // ======================================================

  render() {
    const {
      superrole,
      selectedRowKeys,
      hasSelected,
      retailview,
      downloadLoading,
    } = this.state;

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
        this.onSelectChange(selectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: record.get_manager_info.length === 0, // Column configuration not to be checked
      }),
    };

    const columns = [
      {
        title: "Master",
        dataIndex: "master_agent_name",
        key: "master_agent_name",
        sorter: true,
        hidden: superrole === true ? false : true,
      },
      {
        title: "Distributor",
        dataIndex: "distributor_name",
        key: "distributor_name",
        sorter: true,
        hidden: superrole === true ? false : true,
      },
      {
        title: "Retailer",
        dataIndex: "retailer_name",
        key: "retailer_name",
        sorter: true,
        hidden: superrole === true ? false : true,
      },
      {
        title: "IBOs / Markets",
        dataIndex: "name",
        key: "name",
        sorter: true,
      },
      {
        title: "Manager",
        dataIndex: "get_manager_info",
        key: "get_manager_info",
        sorter: true,
        render: (tags) => (
          <span>
            {tags.map((tag) => {
              return (
                <Tag color="volcano" key={tag}>
                  {tag.name}
                </Tag>
              );
            })}
          </span>
        ),
      },
      // {
      //   title: "IBO Add on Manager",
      //   dataIndex: "get_supervisor_info",
      //   key: "get_supervisor_info",
      //   sorter: true,
      //   render: (tags) => (
      //     <span>
      //       {tags.map((tag) => {
      //         return (
      //           <Tag color="volcano" key={tag}>
      //             {tag.name}
      //           </Tag>
      //         );
      //       })}
      //     </span>
      //   ),
      // },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        filters: [
          { text: "Active", value: 1 },
          { text: "Inactive", value: 0 },
        ],
        render: (tag) => (
          <span>
            <Tag color={tag === 1 ? "green" : "orange"}>
              {tag ? "Active" : "Inactive"}
            </Tag>
          </span>
        ),
      },
      // {
      //   title: "Form Status",
      //   dataIndex: "form_status",
      //   key: "form_status",

      //   fixed: "right",
      //   width: 100,
      //   hidden: !retailview,
      //   render: (text, record) => (
      //     <span>
      //       <Tag
      //         color={
      //           record.form_status === undefined || record.form_status === null
      //             ? "red"
      //             : record.form_status?.toLowerCase() === "completed"
      //             ? "green"
      //             : "orange"
      //         }
      //       >
      //         {record.form_status === undefined || record.form_status === null
      //           ? "Not-Started"
      //           : record.form_status}
      //       </Tag>
      //     </span>
      //   ),
      // },
      {
        title: "Action",
        key: "action",
        width: retailview ? 200 : 150,
        fixed: "right",
        render: (text, record) => (
          <div>
            <div>
              <span>
                {this.state.superrole !== true ? (
                  <>
                    <Link
                      className="padd5 mr5"
                      style={{ marginRight: 10 }}
                      to={{ pathname: "/editmarketers", market: record }}
                    >
                      <Tooltip title="Edit IBOs / Markets">
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<EditOutlined />}
                        />
                      </Tooltip>
                    </Link>
                    <Divider type="vertical" />
                  </>
                ) : null}

                {record.status === 1 ? (
                  <Tooltip title="Delete IBOs / Markets">
                    <Button
                      type="danger"
                      shape="circle"
                      icon={<ShopOutlined />}
                      className="padd5 mr5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        console.log(record.id);
                        Modal.confirm({
                          title:
                            "Are you sure you want to deactivate the IBOs / Market?",
                          // content:
                          //   "Do you want to  inactivate IBOs / Markets ?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: () => {
                            console.log(`Ok`);
                            this.props
                              .marketerdeleteaction(record.id, "0")
                              .then((res) => {
                                this.getdesignation({
                                  results: 10,
                                  page: 1,
                                });
                                message.success(
                                  "IBOs / Markets inactivate successfully"
                                );
                              })
                              .catch((err) => {
                                message.error(err);
                                console.log(err);
                              });
                          },
                        });
                      }}
                    ></Button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Activate IBOs / Markets">
                    <Button
                      type="primary"
                      className="padd5 mr5"
                      shape="circle"
                      icon={<ShopOutlined />}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        console.log(record.id);
                        Modal.confirm({
                          title:
                            "Are you sure you want to activate the IBOs / Market?",
                          // content: "Do you want to active IBOs / Markets ?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: () => {
                            console.log(`Ok`);
                            this.props
                              .marketerdeleteaction(record.id, "1")
                              .then((res) => {
                                this.getdesignation({
                                  results: 10,
                                  page: 1,
                                });
                                message.success(
                                  "IBO/Market updated successfully"
                                );
                              })
                              .catch((err) => {
                                message.error(err);
                                console.log(err);
                              });
                          },
                        });
                      }}
                    ></Button>
                  </Tooltip>
                )}
                {/* {retailview ? (
                  <>
                    <Divider type="vertical" />
                    <Link className="padd5 mr5" to="#">
                      <Tooltip title="Download form">
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<DownloadOutlined />}
                          // disabled={downloadLoading}
                          disabled={
                            record.form_status === undefined ||
                            record.form_status === null ||
                            downloadLoading ||
                            (record.form_status?.toLowerCase() !== "rejected" &&
                              record.form_status?.toLowerCase() !==
                                "accepted" &&
                              record.form_status?.toLowerCase() !== "completed")
                          }
                          onClick={(e) => {
                            if (
                              record.form_status !== undefined &&
                              record.form_status !== null &&
                              (record.form_status?.toLowerCase() ===
                                "rejected" ||
                                record.form_status?.toLowerCase() ===
                                  "accepted" ||
                                record.form_status?.toLowerCase() ===
                                  "completed")
                            ) {
                              this.handleDownload(e, record);
                            }
                          }}
                        />
                      </Tooltip>
                    </Link>
                    {record.form_status !== undefined &&
                    record.form_status !== null &&
                    record.form_status?.toLowerCase() === "completed" ? (
                      <>
                        <Divider type="vertical" />
                        <Tooltip title="Accept/Reject forms">
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<ShopOutlined />}
                            disabled={
                              record.form_status === undefined ||
                              record.form_status === null
                            }
                            onClick={(e) => {
                              if (
                                record.form_status !== undefined &&
                                record.form_status !== null
                              ) {
                                // this.handleReject(e, record)
                                this.showModal({ record: record });
                                console.log(record.id);
                              }
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : null}
                  </>
                ) : null} */}
              </span>
            </div>
          </div>
        ),
      },
    ].filter((item) => !item.hidden);

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                IBOs / Markets{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-end">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5 mx-2"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                {/* <i className="uil uil-export" /> Export Report */}
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
              <Button
                type="default"
                className="mr5 mx-2"
                onClick={() => this.getrefreshbtn()}
              >
                <i className="uil uil-sync" /> Refresh
              </Button>
              {this.state.superrole !== true ? (
                <Link to="/addmarketers">
                  <Button type="primary">Add IBOs / Markets</Button>
                </Link>
              ) : null}
            </div>
          </div>
          <div className="row padd20 bg-grey">
            <div className="col-4">
              <label for="exampleFormControlInput1">Search By Keyword</label>
              <Input
                placeholder="Type Keyword"
                value={this.state.name}
                onChange={(e) =>
                  this.setState({
                    name: e.target.value,
                  })
                }
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5 mx-2"
                loading={this.state.loadingbtn}
                onClick={() => this.searchMarketer("reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
              {hasSelected ? (
                <Button
                  type="primary"
                  className="login-form-button"
                  loading={this.state.loading}
                  onClick={this.handleSendDocument}
                  style={{ marginLeft: 30 }}
                >
                  Send Document
                </Button>
              ) : null}
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // rowSelection={retailview ? rowSelection : undefined}
              // size="small"
            />
          </div>
        </div>
        <div className="row padd20 bg-grey">
          <Modal
            title={"Accept/Reject documents"}
            visible={this.state.visible}
            // onOk={this.handleSubmit}
            onCancel={() => {
              this.setState({
                visible: false,
                record: null,
              });
            }}
            footer={null}
          >
            <Form ref={this.formRef} onSubmit={this.handleSubmit}>
              <Form.Item
                name="IsAccept"
                label="Accept/Reject"
                className="col-md-2 col-sm-3 col-xs-12 paddlr15"
                rules={[
                  {
                    required: true,
                    message: "Please input is accept!",
                  },
                ]}
              >
                <RadioGroup>
                  <Radio value={true}>Accept</Radio>
                  <Radio value={false}>Reject</Radio>
                </RadioGroup>
              </Form.Item>
              <Form.Item
                label="Enter reason to reject"
                name="reason"
                rules={[
                  {
                    required: true ? false : true,
                    message: "Please enter reason!",
                  },
                ]}
              >
                <Input placeholder="Enter reason to reject" />
              </Form.Item>
              <div className="paddlr15">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  // loading={loading}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.auth.auth,
    ibomarket: state.data,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  getibomarket,
  searchibomarket,
  ibo_market_export,
  sendform,
  downloadformapp,
  updateformstatus,
  marketerdeleteaction,
})(Marketer);
