/* eslint-disable react-hooks/exhaustive-deps */
import { Table, Tag, Button, DatePicker, Input, message ,Select,Tooltip,List,Empty,Modal,Divider,} from "antd";
import {EyeOutlined}from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import {
  agent_summary_report,
  searchagentsummarylist,
  exportsearchagentsummarylist,
  getagentstatus,
  viewcommentsuseraction,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import "antd/dist/antd.min.css";
const { RangePicker } = DatePicker;
const AgentReport = (props) => {
  let location = useLocation();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [btnloading, setbtnLoading] = useState(false);
  const [startValue, setstartValue] = useState("");
  const [endValue, setendValue] = useState("");
  const [keyword, setkeyword] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [pickerKey, setPickerKey] = useState(0);
  const [agentstatus, setagentstatus] = useState([]);
  const [selectedstatus, setselectedstatus] = useState(1);
  const [managerview, setmanagerview] = useState(false);
  const [inventorymanagerview, setInventorymanagerview] = useState(false);
  const [iboinventorymanager, setIBOInventorymanagerview] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cvisible, setcvisible] = useState(false);
  const [Listdata, setListdata] = useState([]);
  const [telgooStatus, settelgooStatus] = useState([])
  const [redirect_key, setredirect_key] = useState([])




  useEffect(() => {
    const marketmanager =
    props?.auth?.role_id?.filter((word) => word === 3) ?? [];
  if (marketmanager?.length !== 0) {
    setmanagerview(true);
  }
  const inventorymanager =
    props?.auth?.role_id?.filter((word) => word === 12) ?? [];
  if (inventorymanager?.length !== 0) {
    setInventorymanagerview(true);
  }
  const iboinventorymanager =
    props?.auth?.role_id?.filter((word) => word === 11) ?? [];
  if (iboinventorymanager?.length !== 0) {
    setIBOInventorymanagerview(true);
  }
    dispatch(getagentstatus())
      .then((res) => {
        setagentstatus(res.data);
      })
      .catch((err) => {
        setagentstatus([]);
      });
    console.log(location);
    const urlString = location?.state?.urlpath ?? "";
    fetchData({
      url: urlString ?? "",
      results: 10,
      page: 1,
    });

    const urlParams = urlString
        ?.split('?')[1]
        ?.split('&')
        ?.map((v) => v?.split('='));
    const paramsobj = {};
    urlParams?.forEach((element) => {
      paramsobj[element[0]] = element[1];
    });

    console.log(paramsobj['redirect_key']);
    console.log(">>>>>>>>>>>>",paramsobj['redirect_key'])
    setredirect_key(paramsobj['redirect_key'] ?? '');
  
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = (params) => {
    console.log(params.id);
    setLoading(true);
    dispatch(viewcommentsuseraction(params.id))
      .then((res) => {
        const resp = res.data.items;
        console.log(res.data);
        setListdata(resp);
        setIsModalOpen(true);
        // message.success(res.data.message);
        setcvisible(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "RAD ID",
      dataIndex: "rad",
      key: "rad",
    },
    {
      title: "IBOs / Markets",
      dataIndex: "user_ibo_market_name",
      key: "user_ibo_market_name",
      sorter: true,
      hidden: inventorymanagerview === true ? false : true,
    },
    {
      title: "Office",
      dataIndex: "office_number",
      key: "office_number",
      sorter: true,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: true,
    },
    {
      title: "Middle Name",
      dataIndex: "middle_name",
      key: "middle_name",
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: true,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      sorter: true,
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    
    {
      title: "ETC Code",
      dataIndex: "excess_code",
      key: "excess_code",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Zip",
      dataIndex: "zip",
      key: "zip",
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
    },

    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
    },
    {
      title:"Comments Action",
      dataIndex: "caction",
      key: "caction",
      fixed: "right",
      render: (_,record)=>(

        <span>
        {record.comments_exists === 0 ? (
            <>
          <Tooltip title="View Comments">
            <Button
              type="primary"
              icon={<EyeOutlined />}
              shape="circle"
              style={{ fontSize: 11 }}
              disabled
            ></Button>
          </Tooltip>
          <Divider type="vertical" />
          </>
           ) : ( <>
            <Tooltip title="View Comments">
              <Button
                type="primary"
                icon={<EyeOutlined />}
                shape="circle"
                style={{ fontSize: 11 }}
                onClick={() => showModal(record)}
              ></Button>
            </Tooltip>
            <Divider type="vertical" />
            </>)}
        </span>
          ),
    },
    {
      title: "Status",
      dataIndex: "status_name",
      key: "status_name",
     
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <span>
          <Tag color={record.status === 1 ? "green" : "orange"}>
            {/* {record.status === 1 ? "Active" : "Inactive"} */}
            {record.status_text}
          </Tag>
        </span>
      ),
    },
    // {
    //   title: "Telgoo Status",
    //   dataIndex: "telgoo_status",
    //   key: "telgoo_status",

    //   fixed: "right",
    //   width: 100,
    //   render: (text, record) => (
    //     <span>
    //         <Tag
    //           color={record.telgoo_status_id === 1 ? "green" : "orange"}
    //         >
    //           {record.telgoo_status}
    //         </Tag>
    //       </span>
    //   ),
    // },
  ].filter((item) => !item.hidden);

  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });
  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(agent_summary_report(getRandomuserParams(params)))
      .then((res) => {
        setLoading(false);
        console.log(res.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter,redirect_key) => {
    console.log(filters);
    
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setTimeout(() => {
      searchonboardingreport("", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };
  const onChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setstartValue(dateStrings[0]);
      setendValue(dateStrings[1]);
    } else {
      console.log("Clear");
      setstartValue("");
      setendValue("");
    }
  };
  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnLoading(true);
    console.log(filteredInfo);
    var search_text = keyword;
    var status = selectedstatus;
    var telgoo_status = telgooStatus;
    var sort = params.order === undefined ? "" : params.field;
    var sort_by =
      params.order === "ascend"
        ? "ASC"
        : params.order === undefined
        ? ""
        : "DESC";
    var date_range_start = startValue;
    var date_range_end = endValue;

    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;
    //var redirect_key = redirect_key;

    dispatch(
      searchagentsummarylist({
        search_text: search_text,
        page: page,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
        status,
        telgoo_status,
        redirect_key,
      })
    )
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setbtnLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setbtnLoading(false);
      });
  };
  const clearSearch = () => {
    setPickerKey((prevKey) => prevKey + 1);
    setLoading(true);
    setstartValue(null);
    setendValue(null);
    setkeyword("");
    fetchData();
    setFilteredInfo({});
    setselectedstatus([]);
    settelgooStatus([]);
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var date_range_start = startValue;
    var date_range_end = endValue;
    var search_text = keyword;
    // var status = filteredInfo?.status_name ?? "";
    var status = selectedstatus;
    var telgoo_status = telgooStatus;
    // var redirect_key = redirect_key;
    dispatch(
      exportsearchagentsummarylist({
        search_text: search_text,
        date_range_start,
        date_range_end,
        status,
        telgoo_status,
        redirect_key,
      })
    )
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        console.log(res);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
    setselectedstatus(value);
  };
  const telgooonChangeSelect = (value) => {
    console.log(`Telhoo--selected ${value}`);
    settelgooStatus(value);
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong>Agent Summary </strong> */} Agent Summary{" "}
              {tableParams.pagination.total &&
                `(${tableParams.pagination.total})`}
            </h4>
            <div className="col-4 d-flex justify-content-end ">
              <Button
                type="primary"
                className="mr5"
                loading={loadingEPbtn}
                onClick={getexportbtn}
              >
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
            </div>
          </div>
          <div className="row mt-2" style={{ padding: "15px" }}>
            <div className="col-sm-12 col-md-4">
              <label>Select Date</label>
              <RangePicker
                key={pickerKey}
                format="MM-DD-YYYY"
                disabledDate={disabledDate}
                style={{ width: "100%" }}
                onChange={onChange}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <label>Select Status</label>
              <Select
                style={{ width: "100%" }}
                showSearch
                mode="multiple"
                placeholder="Select status"
                value={selectedstatus}
                optionFilterProp="children"
                onChange={onChangeSelect}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={(agentstatus || []).map((d) => ({
                  value: d.id,
                  label: d.name,
                }))}
              ></Select>
            </div>
            {/* <div className="col-sm-12 col-md-4">
            <label>Telgoo Status</label>
              <Select
                showSearch
                mode="multiple"
                value={telgooStatus}
                onChange={telgooonChangeSelect}
                // onChange={(e) =>
                //   this.setState({
                //     telgooStatus: e,
                //   })
                // }
                style={{ width: "100%" }}
                placeholder="Select Option"
               >
                <option value="1">Active</option>
                <option value="0">InActive</option>
                <option value="2">Others</option>
              </Select>
            </div> */}
            <div className="col-sm-12 col-md-4">
              <label>Search by keyword</label>
              <Input
                placeholder="Search by keyword"
                value={keyword}
                // ref={(node) => {
                //   this.searchInput = node;
                // }}
                onChange={(e) => setkeyword(e.target.value)}
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={btnloading}
                onClick={() => searchonboardingreport("reset", "")}
              >
                Search
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                type="default"
                onClick={clearSearch}
              >
                Clear
              </Button>
            </div>
          </div>
          <div className="card-body">
            <Table
              columns={columns}
              rowKey={(record) => record.key}
              dataSource={data}
              scroll={{ x: true }}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
          <div>
          <Modal
              title="View Comments"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <Button type="primary" onClick={handleCancel}>
                  Close
                </Button>,
              ]}
            >
              {Listdata.length === 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <>
                  {Listdata.map((items, index) => {
                    console.log(Listdata)
                    return (
                      <List.Item key={index}>
                        <List.Item.Meta
                          title={
                            <Tag color="orange">
                              {items.created_at}-{items.commented_by_name}
                            </Tag>
                          }
                          description={items.comments}
                        />
                      </List.Item>
                    );
                  })}
                </>
              )}
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
   
  };
};

export default connect(mapStateToProps)(AgentReport);

