/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table,
  Tag,
  Button,
  Select,
  Input,
  DatePicker,
  message,
  Empty,
  Spin,
  Drawer,
  List,
  Tooltip,
  Checkbox,
} from "antd";
import { UploadOutlined,HistoryOutlined,EyeOutlined, } from "@ant-design/icons";
import _, { set } from "lodash";
import { useEffect, useState } from "react";
import {
  // SalesSummaryReport,
  getofficelistname,
  // detailed_sales_report_search,
  InventoryDetailReport,
  exportinventorydetailreportlist,
  getinventorystatus,
  getdevicelogstatus,
  getdevicetype,
  getinventorylegacystatus,
} from "../../../store/actions/DeviceActions";
import { get_ibo_marketlist } from "../../../store/actions/documentActions";

import { connect, useDispatch,useSelector } from "react-redux";
import moment from "moment";
import "antd/dist/antd.min.css";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../../services/AxiosAdmin";

import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

const { RangePicker } = DatePicker;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const CheckboxGroup1 = Checkbox.Group;
const CheckboxGroup2 = Checkbox.Group;

const defaultCheckedList = [""];
const defaultCheckedList1 = [""];
const defaultCheckedList2 = [""];

const InventoryDetailedReport = (props) => {
  const user = useSelector((state) => state.auth.auth);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [btnloading, setbtnLoading] = useState(false);
  const [selectedstatus, setselectedstatus] = useState([]);
  // const [startValue, setstartValue] = useState("");
  // const [endValue, setendValue] = useState("");
  const [keyword, setkeyword] = useState("");
  const [orderid, setorderid] = useState("");
  const [enrollmentid, setenrollmentid] = useState("");
  const [cartons, setCartons] = useState("");
  const [imeis, setImeis] = useState("");
  const [rma_order_id, setrma_order_id] = useState("");
  const [onboarding, setonboarding] = useState([]);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [inventorystatus, setinventorystatus] = useState([]);
  const [devicestatus, setDevicestatus] = useState([]);
  const [device, setdevice] = useState([]);
  const [iboinventorymanager, setIBOInventorymanagerview] = useState(false);


  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [selectedItems, setselactedItems] = useState([]);
  const [AgentselectedItems, setAgentselactedItems] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fdataAgent, setfdataAgent] = useState([]);

  const [fetching, setfetching] = useState(false);
  const [fAdata, setfAdata] = useState([]);
  const [Afetching, setAfetching] = useState(false);

  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [endOpen, setEndOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndtDate] = useState(null);
  const [startValue1, setStartValue1] = useState(null);
  const [endValue1, setEndValue1] = useState(null);
  const [endOpen1, setEndOpen1] = useState(false);
  const [startDate1, setStartDate1] = useState(null);
  const [endDate1, setEndtDate1] = useState(null);
  const [logdata, setlogdata] = useState([]);
  const dispatch = useDispatch();

  const [agentstatus, setAgentStatus] = useState([]);
  const [checkedList, setCheckedList] = useState([""]);
  const [checkedList1, setCheckedList1] = useState([""]);
  const [checkedList2, setCheckedList2] = useState([""]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate1, setIndeterminate1] = useState(false);
  const [checkAll1, setCheckAll1] = useState(false);
  const [indeterminate2, setIndeterminate2] = useState(false);
  const [checkAll2, setCheckAll2] = useState(false);
  const [instockStatus, setInstockStatus] = useState([]);
  const [lostStatus, setLostStatus] = useState([]);
  const [otherStatus, setOtherStatus] = useState([]);
  const [marketer, setMarketer] = useState([]);
  const [selectediboItems, setselactediboItems] = useState([]);
  const [marketrole, setMarketRole] = useState(false);
  const [officemanagerview, setofficemanagerview] = useState(false);
  


  const [open, setOpen] = useState(false);

  const showDrawer = (record) => {
    console.log(record.id);
    setLoading(true);
    dispatch(getdevicelogstatus(record.id))
      .then((res) => {
        console.log(res);
        setlogdata(res.data.items);
        setOpen(true);
        setLoading(false);
      })
      .catch((err) => {});
  };
  
  const onClose = () => {
    setOpen(false);
  };
  const search = useLocation().search;

  const fetchSelectData = () => {
            
    dispatch(getinventorylegacystatus())
     .then((res) => {
      console.log(res)
      setAgentStatus(res.data);
      setInstockStatus(res.data.filter(item => [9, 3, 4, 5, 6, 12].includes(item.id)));
      setLostStatus(res.data.filter(item => [10, 11, 15, 16, 18, 19].includes(item.id)));
      setOtherStatus(res.data.filter(item => ![0,9, 3, 4, 5, 6, 10, 13, 11, 15, 12, 20, 21, 22, 16, 18, 19, 14].includes(item.id)));
    })
     .catch((err)=>{
        console.log(err.message)
    });
  
};
  useEffect(() => {
    // const status = new URLSearchParams(search).get("status");
    // setselectedstatus(parseInt(status) || []);
    // console.log(status);

      // if (
      //   [4, 5, 6, 9, 3, 12].includes(parseInt[status])
      // ) {
      //   onChangeCheck([parseInt[status]]);
      //   setTimeout(() => {
      //     searchonboardingreport(1);
      //   }, 200);
      // }
  
      const paramsobj = new URLSearchParams(window.location.search);
      const status = parseInt(paramsobj.get("status"));
      setselectedstatus(status ? [status] : []);
      console.log(status);
       //Instock redirect
       
      if ([4, 5, 6, 9, 3, 12].includes(status)) {
        onChangeCheck([status]);
        // setTimeout(() => searchonboardingreport(1,""),);
      }
      if (status === 13) {
        onChangeCheck([4, 5, 6, 9, 3, 12]);
        // setTimeout(() => searchonboardingreport(1,""), 200);
      }

      // if (status === 13) {
      //   onChangeCheck([3, 4, 5, 6, 9, 12]);
      // } else if ([4, 5, 6, 9, 3, 12].includes(status)) {
      //   onChangeCheck([status]);
      //   // setTimeout(() => searchonboardingreport(1),);
      // }


    //   const selectedStatuses = [3, 4, 5, 6, 9, 12];
    // if (selectedStatuses.includes(status)) {
    //   selectedStatuses.forEach(selectedStatus => {
    //     onChangeCheck([selectedStatus]);
    //   });
    // }
  
       //LOST/DEFECTIVE redirect
      if ([11, 15, 10].includes(status)) {
        onChangeCheck1([status]);
        // setTimeout(() => searchonboardingreport(1),);
      }
   

    dispatch(getdevicetype()).then((res) => {
      console.log(res.data);
      setdevice(res.data);
    });

    fetchSelectData();
    dispatch(get_ibo_marketlist())
    .then((res) => {
      console.log(res);
      setMarketer(res.data);
    })
    .catch((err) => {
      console.log(err);
    });

    // console.log(` props?.auth?.role_id?`, props?.auth?.role_id);
    // const iboinventorymanager =
    //   props?.auth?.role_id?.filter((word) => word === 11) ?? [];
    // if (iboinventorymanager?.length !== 0) {
    //   setIBOInventorymanagerview(true);
    //   console.log("ibo manager login True")
    // }

    const officemanager =
    user?.role_id?.filter((word) => word === 3) ?? [];
    if (officemanager?.length !== 0) {
      setofficemanagerview(true);
    }

    const marketer = user?.role_id?.filter((word) => word === 11) ?? [];
    if (marketer.length !== 0) {
      setMarketRole(true);
    }
    console.log("user",user)

  }, []);

  const disabledStartDate = (startValue) => {
    // if (!startValue || !endValue) {
    //   return false;
    // }
    return startValue && startValue > moment().endOf("day");
  };
  const disabledStartDate1 = (startValue) => {
    // if (!startValue || !endValue) {
    //   return false;
    // }
    return startValue && startValue > moment().endOf("day");
  };
  const disabledEndDate1 = (endValue) => {
    // if (!endValue || !startValue) {
    //   return false;
    // }
    // return endValue.valueOf() <= startValue.valueOf();
    return endValue && endValue > moment().endOf("day");
  };
  const disabledEndDate = (endValue) => {
    // if (!endValue || !startValue) {
    //   return false;
    // }
    // return endValue.valueOf() <= startValue.valueOf();
    return endValue && endValue > moment().endOf("day");
  };

  const onStartChange = (value, date) => {
    console.log(value);
    setStartValue(value);
    setStartDate(date);
  };
  const onStartChange1 = (value, date) => {
    console.log(value);
    setStartValue1(value);
    setStartDate1(date);
  };
  const onEndChange = (value, date) => {
    setEndValue(value);
    setEndtDate(date);
  };
  const onEndChange1 = (value, date) => {
    setEndValue1(value);
    setEndtDate1(date);
  };
  const handleStartOpenChange = (open) => {
    if (!open) {
      setEndOpen(true);
    }
  };
  const handleStartOpenChange1 = (open) => {
    if (!open) {
      setEndOpen1(true);
    }
  };
  const handleEndOpenChange = (open) => {
    setEndOpen(open);
  };
  const handleEndOpenChange1 = (open) => {
    setEndOpen1(open);
  };
  useEffect(() => {
    fetchData();
    dispatch(getinventorystatus())
      .then((res) => {
        console.log("drop status", res);
        setinventorystatus(res.data);
      })
      .catch((err) => {});
    // dispatch(getofficelistname())
    //   .then((res) => {
    //     console.log(res.data);
    //     setonboarding(res.data);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //   });
  }, []);

  const renderorderstatus = () => {
    return _.map(inventorystatus, (master, index) => {
      // console.log(master);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };
  const renderdevicestatus = () => {
    return _.map(device, (master, index) => {
      // console.log(master);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };


  const columns = [
    // {
    //   title: "IBO / Markets ",
    //   dataIndex: "ibo_name",
    //   key: "ibo_name",
    //   sorter: true,
    // },
   
    {
      title: "IBO Name",
      dataIndex: "ibo_name",
      key: "ibo_name",
      sorter: true,
    },

    {
      title: "Office ",
      dataIndex: "office_name",
      key: "office_name",
      sorter: true,
    },
    
    {
      title: "Agent Name",
      dataIndex: "agent_name",
      key: "agent_name",
      sorter: true,
    },

    {
      title: "Manufacturer",
      dataIndex: "make",
      key: "make",
      sorter: true,
    },
    
    {
      title: "Enrollment Number",
      dataIndex: "enroll_id",
      key: "enroll_id",
    },
    {
      title: "ETC Code",
      dataIndex: "excess_code",
      key: "excess_code",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      sorter: true,
    },
    {
      title: "Pallet",
      dataIndex: "pallet",
      key: "pallet",
      sorter: true,
    },

    {
      title: "Carton",
      dataIndex: "carton",
      key: "carton",
      sorter: true,
    },
    {
      title: "Enrollment Type",
      dataIndex: "enroll_type",
      key: "enroll_type",
    },
    {
      title: "ESN",
      dataIndex: "esn",
      key: "esn",
    },
    // {
    //   title: "MDN",
    //   dataIndex: "mdn",
    //   key: "mdn",
    // },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      sorter: true,
    },
    {
      title: "Device Type",
      dataIndex: "device_type",
      key: "device_type",
    },
    {
      title: "Status",
      dataIndex: "status_name",
      key: "status_name",
      render: (text, record) => (
        <span>
          <Tag color="orange">{record.status_name}</Tag>
        </span>
      ),
    },
    {
      title: "Allocation Date",
      dataIndex: "allocation_date",
      key: "allocation_date",
      sorter: true,
    },
    {
      title: "Age",
      dataIndex: "device_age",
      key: "device_age",
      sorter: true,
    },
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "RMA ID",
      dataIndex: "rma_order_id",
      key: "rma_order_id",
      // hidden: retailview,
    },
    {
      title: "Activation Date",
      dataIndex: "activation_date",
      key: "activation_date",
      sorter: true,
    },
   
    {
      title: "Action",
      key: "action",
      width: 100,
      fixed: "right",
      render: (text, record) => (
        <Tooltip title="View Device Log">
              <Button
              onClick={() => showDrawer(record)} 
              shape="circle"
              icon={<EyeOutlined />}
              type="primary">
              </Button>
            </Tooltip>
        
      ),
    },
  ];
  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
    status: new URLSearchParams(search).get("status"),
  });
  const fetchData = () => {
    setLoading(true);
    dispatch(InventoryDetailReport(getRandomuserParams(tableParams)))
      .then((res) => {
        if (res.status === 200) {
          console.log(res.status);
          console.log(res.data.items.data);
          setData(res.data.items.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: res.data.items.total,
              // 200 is mock data, you should read it from server
              // total: data.totalCount,
            },
          });
          setLoading(false);
          setbtnLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setbtnLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setTimeout(() => {
      searchonboardingreport(" ", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };
  const onChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      // setstartValue(dateStrings[0]);
      // setendValue(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };
  // const onChangeSelect = (value) => {
  //   setselectedstatus(value);
  // };
  const searchonboardingreport = (reset, params,) => {
    console.log(selectedstatus);
    const mergedArray =checkedList.concat(checkedList1,checkedList2 ).filter(obj => Object(obj).length !== 0);
    setLoading(true);
    setbtnLoading(true);
    // var sort_by = sortOrder;
    console.log(reset, params);
    // var status = selectedstatus;
    var status =  mergedArray.toString();
    var search_text = keyword;
    var office_id = selectedItems.key;
    var agent_id = AgentselectedItems.value;
    // var sort = params?.order === undefined ? "" : params.field;
    var sort = params === undefined ?  params.field : params.order === undefined ? "" : params.field;
    var sort_by =
      params.order === "ascend"
        ? "ASC"
        : params.order === undefined
        ? ""
        : "DESC";
    var date_range_start = startDate;
    var date_range_end = endDate;
    var activation_date_range_start = startDate1;
    var activation_date_range_end = endDate1;
    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;
    var order_id = orderid;
    var enroll_id = enrollmentid;
    var carton = cartons;
    var imei = imeis;
    var device_type = devicestatus;
    var market = selectediboItems.value;
    var rmaorderid = rma_order_id;
    console.log(reset);
    console.log(agent_id);
    const obj = {
      status: status,
      search_text: search_text,
      page: page,
      sort,
      sort_by,
      date_range_start,
      date_range_end,
      activation_date_range_start,
      activation_date_range_end,
      office_id,
      agent_id,

      order_id,
      enroll_id,
      carton,
      imei,
      device_type,
      market,
      rmaorderid,
    };
    dispatch(InventoryDetailReport(obj))
      .then((res) => {
        console.log(res.data.items.data);
        setData(res.data.items.data);
        setLoading(false);
        setbtnLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.items.total,
            current: res.data.items.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setbtnLoading(false);
      });
  };

  const clearSearch = () => {
    setLoading(true);
    setStartValue(null);
    setEndValue(null);
    setStartDate(null);
    setEndtDate(null);
    setStartValue1(null);
    setEndValue1(null);
    setStartDate1(null);
    setEndtDate1(null);
    setkeyword("");
    setorderid("");
    setCartons("");
    setenrollmentid("");
    setImeis("");
    setrma_order_id("");
    setselectedstatus([]);
    setselactedItems([]);
    setAgentselactedItems([]);
    setfdata([]);
    setDevicestatus([]);
    setCheckedList([]);
    setIndeterminate(false);
    setIndeterminate1(false);
    setIndeterminate2(false);
    setCheckedList1([]);
    setCheckedList2([]); 
    setCheckAll(false);
    setCheckAll1(false);
    setCheckAll2(false);
    setselactediboItems([]);
    fetchData();
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    setTimeout(() => {
      console.log("Start Value:", startValue);
      console.log("End Value:", endValue);
    }, 100);
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    // const mArr = [...checkedList,...checkedList1];
    const mergedArray = checkedList
                            .concat(checkedList1,checkedList2 )
                            .filter(obj => Object(obj).length !== 0);
    var status = mergedArray.toString();
    var search_text = keyword;
    var date_range_start = startDate;
    var date_range_end = endDate;
    var office_id = selectedItems.key;
    var agent_id = AgentselectedItems.value;
    var sort = sort;
    var sort_by = sort_by;
    var activation_date_range_start = startDate1;
    var activation_date_range_end = endDate1;
    var order_id = orderid;
    var enroll_id = enrollmentid;
    var carton = cartons;
    var imei = imeis;
    var device_type = devicestatus;
    var market = selectediboItems.value;
    var rmaorderid = rma_order_id;
    const obj = {
      status: status,
      search_text: search_text,
      sort,
      sort_by,
      date_range_start,
      date_range_end,
      activation_date_range_start,
      activation_date_range_end,
      office_id,
      agent_id,

      order_id,
      enroll_id,
      carton,
      imei,
      device_type,
      market,
      rmaorderid,
    };
    console.log(obj);
    dispatch(exportinventorydetailreportlist(obj))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };

  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };
  const onchangeAgentRole = (AgentselectedItems) => {
    console.log(AgentselectedItems);
    setAgentselactedItems(AgentselectedItems);
  };
  const onchangeorderfilter = (selectedstatus) => {
    setselectedstatus(selectedstatus);
  };
  const onchangedevicestatus = (devicestatusstatus) => {
    setDevicestatus(devicestatusstatus);
  };

  const onchangeIBORole = (selectediboItems) => {
      console.log(selectediboItems);
      setselactediboItems(selectediboItems);
    };

    const renderOffice = () => {
      return marketer.map((master, index) => {
        return (
          <Option value={master.id} key={index}>
            {master.name}
          </Option>
        );
      });
    };

  const fetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    setfdata([]);
    if (value.length > 1) {
      // setfdata([])
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata1 = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdata(fdata1);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const AgentfetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    if (value.length > 1) {
      setfdata([]);
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/userlist_based_on_role/agent?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdataAgent(fdata);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

 
  const onChangeCheck = (checkedList) => {
    console.log(checkedList.length === instockStatus.length);
    setCheckedList(checkedList);
    setIndeterminate(!!checkedList.length && checkedList.length < instockStatus.length);
    setCheckAll(checkedList.length === 6);
  };

  const onCheckAllChange = (e) => {
    console.log(e.target.checked);
    setCheckedList(e.target.checked ? instockStatus.map(option => option.id) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChangeCheck1 = (checkedList1) => {
    setCheckedList1(checkedList1);
    setIndeterminate1(!!checkedList1.length && checkedList1.length < lostStatus.length);
    setCheckAll1(checkedList1.length === lostStatus.length);
  };

  const onCheckAllChange1 = (e) => {
    setCheckedList1(e.target.checked ? lostStatus.map(option => option.id) : []);
    setIndeterminate1(false);
    setCheckAll1(e.target.checked);
  };

  const onChangeCheck2 = (checkedList2) => {
    setCheckedList2(checkedList2);
    setIndeterminate2(!!checkedList2.length && checkedList2.length < otherStatus.length);
    setCheckAll2(checkedList2.length === otherStatus.length);
  };

  const onCheckAllChange2 = (e) => {
    setCheckedList2(e.target.checked ? otherStatus.map(option => option.id) : []);
    setIndeterminate2(false);
    setCheckAll2(e.target.checked);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {/* <strong>Inventory Detailed Report</strong> */} Inventory
                Detailed Report{" "}
                {tableParams.pagination.total &&
                  `(${tableParams.pagination.total})`}
              </h4>
              <div className="col-sm-2 text-right">
                <Button
                  type="primary"
                  className="mr5"
                  loading={loadingEPbtn}
                  onClick={getexportbtn}
                >
                  <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
                </Button>
              </div>
            </div>
            <div className="row mt-2" style={{ padding: "15px" }}>
              <>
              {marketrole === true || officemanagerview === true ? null : ( 
              <div className="col-sm-12 col-md-3">
                <label for="exampleFormControlInput1">Select IBOs / Markets</label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  labelInValue
                  placeholder="Select Option"
                  defaultActiveFirstOption={false}
                  value={selectediboItems}
                  onChange={onchangeIBORole}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderOffice()}
                </Select>
             </div>
              )}
              {officemanagerview === true ? null : ( 

             
              <div className="col-sm-12 col-md-3">
                <label style={{ font: "caption" }}>Select Office</label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  labelInValue
                  placeholder="Type 2 characters to search..!"
                  notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                  filterOption={false}
                  onSearch={fetchUser}
                  defaultActiveFirstOption={false}
                  value={selectedItems}
                  onChange={onchangeRole}
                >
                  {fdata?.map((d) => (
                    <Option key={d.value}>{d.text}</Option>
                  ))}
                </Select>
                {/* <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="Type office"
                value={selectedstatus}
                optionFilterProp="children"
                onChange={onChangeSelect}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={(onboarding || []).map((d) => ({
                  value: d.id,
                  label: d.name,
                }))}
              ></Select> */}
              </div>
               )}
              <div className="col-sm-12 col-md-3">
                <label style={{ font: "caption" }}>Select Agent</label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  labelInValue
                  placeholder="Type 2 characters to search..!"
                  notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                  filterOption={false}
                  onSearch={AgentfetchUser}
                  defaultActiveFirstOption={false}
                  value={AgentselectedItems}
                  onChange={onchangeAgentRole}
                >
                  {fdataAgent?.map((d) => (
                    <Option key={d.value}>{d.text}</Option>
                  ))}
                </Select>
              </div>
              {/* <div className="col-sm-12 col-md-3">
                <label style={{ font: "caption" }}>Select Status</label>
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select status"
                  value={selectedstatus}
                  onChange={onchangeorderfilter}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderorderstatus()}
                </Select>
              </div> */}

              <div className="col-sm-12 col-md-3">
                <label style={{ font: "caption" }}>Search By Order ID</label>
                <Input
                  placeholder=" Input Order ID"
                  value={orderid}
                  onChange={(e) => setorderid(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              {marketrole === true || officemanagerview === true ? 
              (
                <div className="col-sm-12 col-md-3 ">
                <label style={{ font: "caption" }}>Search By Enrollment ID</label>
                <Input
                  placeholder=" Input Enrollment ID"
                  value={enrollmentid}
                  onChange={(e) => setenrollmentid(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              ):(
                <div className="col-sm-12 col-md-3 mt-4">
                <label style={{ font: "caption" }}>Search By Enrollment ID</label>
                <Input
                  placeholder=" Input Enrollment ID"
                  value={enrollmentid}
                  onChange={(e) => setenrollmentid(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              )}
              {officemanagerview === true ? (
              <div className="col-sm-12 col-md-3 ">
                <label style={{ font: "caption" }}>Search By Carton</label>
                <Input
                  placeholder="Type Carton Number"
                  value={cartons}
                  onChange={(e) => setCartons(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div> 
              ):(
              <div className="col-sm-12 col-md-3 mt-4">
                <label style={{ font: "caption" }}>Search By Carton</label>
                <Input
                  placeholder="Type Carton Number"
                  value={cartons}
                  onChange={(e) => setCartons(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
               )}
              <div className="col-sm-12 col-md-3 mt-4">
                <label style={{ font: "caption" }}>Search By IMEI</label>
                <Input
                  placeholder="Type IMEI"
                  value={imeis}
                  onChange={(e) => setImeis(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-4">
                <label style={{ font: "caption" }}>Search By RMA ID</label>
                <Input
                  placeholder="Type RMA ID"
                  value={rma_order_id}
                  onChange={(e) => setrma_order_id(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              

              <div className="col-sm-12 col-md-3 mt-4  ">
                <label style={{ font: "caption" }}>Allocation From</label>
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledStartDate}
                  value={startValue}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={onStartChange}
                  onOpenChange={handleStartOpenChange}
                />
              </div>
              <div className="col-sm-12 col-md-3  mt-4">
                <label style={{ font: "caption" }}>Allocation To</label>
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledEndDate}
                  value={endValue}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={onEndChange}
                  open={endOpen}
                  onOpenChange={handleEndOpenChange}
                />
              </div>
              <div className="col-sm-12 col-md-3  mt-4">
                <label style={{ font: "caption" }}>Activation From</label>
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledStartDate1}
                  value={startValue1}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={onStartChange1}
                  onOpenChange={handleStartOpenChange1}
                />
              </div>
              <div className="col-sm-12 col-md-3  mt-4">
                <label style={{ font: "caption" }}>Activation To</label>
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledEndDate1}
                  value={endValue1}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={onEndChange1}
                  open={endOpen1}
                  onOpenChange={handleEndOpenChange1}
                />
              </div>
              {/* <div className="col-2"> */}
              {/* <RangePicker
                format="MM-DD-YYYY"
                style={{ width: "100%" }}
                ranges={{
                  Today: [moment(), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={onChange}
                disabledDate={disabledDate}
              /> */}
              {/* </div> */}
              <div className="col-sm-12 col-md-3 mt-4">
                    <label for="exampleFormControlInput1">
                      Select Device Type
                    </label>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Device Type"
                      value={devicestatus}
                      onChange={onchangedevicestatus}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {renderdevicestatus()}
                    </Select>
                  </div>
              <div className="col-sm-12 col-md-3 mt-4">
                <label style={{ font: "caption" }}>Search By Keyword</label>
                <Input
                  placeholder="Type keyword"
                  value={keyword}
                  onChange={(e) => setkeyword(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              </>
            </div>
            <div className="row mt-2" style={{ padding: "15px" }}>
              <div className="col-4 mt-4">
                {/* <div className="card  mb-4" style={{ borderRadius: "1.00rem", height: "200px" }}> */}
                  <div className="card-body">
                    <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                      <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                      >
                        INSTOCK
                      </Checkbox>
                    </div>
                    <br />
                    <Checkbox.Group
                      value={checkedList}
                      onChange={onChangeCheck}
                    >
                      {instockStatus.map((status, index) => (
                        <Checkbox key={index} value={status.id}>
                          {status.name}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </div>
                {/* </div> */}
              </div>

              <div className="col-4 mt-4">
                {/* <div className="card  mb-4" style={{ borderRadius: "1.00rem", height: "200px" }}> */}
                  <div className="card-body">
                    <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                      <Checkbox
                        indeterminate={indeterminate1}
                        onChange={onCheckAllChange1}
                        checked={checkAll1}
                      >
                        LOST/DEFECTIVE
                      </Checkbox>
                    </div>
                    <br />
                    <Checkbox.Group
                      value={checkedList1}
                      onChange={onChangeCheck1}
                    >
                      {lostStatus.map((status, index) => (
                        <Checkbox key={index} value={status.id}>
                          {status.name}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </div>
                {/* </div> */}
              </div>

              <div className="col-4 mt-4">
                {/* <div className="card  mb-4" style={{ borderRadius: "1.00rem", height: "200px" }}> */}
                  <div className="card-body">
                    <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                      <Checkbox
                        indeterminate={indeterminate2}
                        onChange={onCheckAllChange2}
                        checked={checkAll2}
                      >
                        OTHERS
                      </Checkbox>
                    </div>
                    <br />
                    <Checkbox.Group
                      value={checkedList2}
                      onChange={onChangeCheck2}
                    >
                      {otherStatus.map((status, index) => (
                        <Checkbox key={index} value={status.id}>
                          {status.name}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </div>
                {/* </div> */}
                
              </div>

              <div className="col-sm-12 col-md-12 mt-5">
                <Button
                  type="primary"
                  className="mr5"
                  loading={btnloading}
                  onClick={() => searchonboardingreport("reset", "")}
                >
                  Search
                </Button>
                <Button
                  // style={{ marginLeft: "10px" }}
                  style={{marginRight:"20px"}}
                  type="default"
                  onClick={clearSearch}
                >
                  Clear
                </Button>
              </div>
            </div>
            <div className="card-body">
              <Table
                scroll={{ x: true }}
                columns={columns}
                rowKey={(record) => record.key}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loading}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Device Log"
        placement="right"
        onClose={onClose}
        open={open}
      >
       
        <List
          itemLayout="horizontal"
          dataSource={logdata}
          renderItem={(item) => (
            <>
            <List.Item>
             
                  <List.Item.Meta
                    title={<b>IMEI : {item.imei}</b>}
                    description={item.short_description}
                  />
               
                
                 
              
             
            </List.Item>
             <Tag>{item.created_at}</Tag>
             </>
          )}
        />
      </Drawer>
    </>
  );
};
export default InventoryDetailedReport;
