import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import reportWebVitals from "./reportWebVitals";
//import SimpleReactLightbox from "simple-react-lightbox";
import  ThemeContext  from "./context/ThemeContext"; 
// import disableDevtool from'disable-devtool';
// disableDevtool();
ReactDOM.render(
	<React.StrictMode>
		<Provider store = {store}>
			<BrowserRouter>
				<ThemeContext>
					<App />
				</ThemeContext>  
			</BrowserRouter>    
        </Provider>	
	</React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
