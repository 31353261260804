import { applyMiddleware, combineReducers, compose, createStore, } from 'redux';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import DocumentReducer from "./reducers/DocumentReducers";
import DeviceReducer from './reducers/DeviceReducers'

const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    posts: PostsReducer,
    auth: AuthReducer,
    devicelist: DeviceReducer,
    document: DocumentReducer
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
