import React, { useState, useEffect } from "react";
import {
  rmaSubmitAction,
  ConfirmSubmitRMAAction,
  Get_manager_office_list,
  exportrmaAction,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Drawer,
  Space,
  Input,
  Col,
  Form,
  Row,
  Select,
  Table,
  Button,
  Modal,
  message,
  Empty,
  Spin,
} from "antd";
import _ from "lodash";
import axiosInstance from "../../../services/AxiosAdmin";
import {short_us_states} from "../../../store/actions/documentActions";

const { Option } = Select;
const { confirm } = Modal;

const SubmitRMA = (props) => {
  //console.log(props.device);
  const [pagesize, setpagesize] = useState(10);
  const [imei, setimei] = useState([]);
  const [IMEIOpen, setIMEIOpen] = useState(false);
  const [form] = Form.useForm();
  const [imeiList, setImeiList] = useState([]);
  const [isApprove, setisApprove] = useState(false);
  const [data, setData] = useState([]);
  const [hasSelected, setHasSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnloading, setbtnLoading] = useState(false);
  const [SMTbtnloading, setSMTbtnLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [itemsrow, setItemsrow] = useState([]);
  const [itemsimei, setItemimei] = useState([]);
  const [sortField, setsortField] = useState(null);
  const [sortOrder, setsortOrder] = useState(null);
  const [selectedoffice, setselectedoffice] = useState(null);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [shortstate, setshortstate] = useState([]);
  const [selectedItems, setselactedItems] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fetching, setfetching] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.removeItem("sitems");
    localStorage.removeItem("sitemsimei");
    fetchData({
      // url: urlString ?? "",
      results: 10,
      page: 1,
    });
    dispatch(Get_manager_office_list());
    dispatch(short_us_states()).then((res) => {
      setshortstate(res.data);
    });
  }, []);
  useEffect(() => {
    // var olddata = JSON.parse(localStorage.getItem("sitems"));
    // var olddata1 = JSON.parse(localStorage.getItem("sitemsimei"));
    // localStorage.setItem("sitems", JSON.stringify(_.union(olddata, itemsrow)));
    // localStorage.setItem("sitemsimei", JSON.stringify(_.union(olddata1, imei)));
    // setSelectedRowKeys(JSON.parse(localStorage.getItem("sitems")));
    var olddata = JSON.parse(localStorage.getItem("sitems")) ?? [];
    var olddata1 = JSON.parse(localStorage.getItem("sitemsimei")) ?? [];
    olddata[pagination?.current - 1] = itemsrow;
    olddata1[pagination?.current - 1] = itemsimei;
    localStorage.setItem("sitems", JSON.stringify(olddata));
    localStorage.setItem("sitemsimei", JSON.stringify(olddata1));
    let selectedrows = [];
    let selectedimei = [];
    olddata.forEach((element) => {
      if (element && element?.length > 0) {
        console.log(element);
        selectedrows = [...selectedrows, ...element];
      }
    });
    olddata1.forEach((element) => {
      if (element && element?.length > 0) {
        console.log(element);
        selectedimei = [...selectedimei, ...element];
      }
    });
    console.log(selectedrows);
    console.log(selectedimei);
    setSelectedRowKeys(selectedrows);
    setItemimei(selectedimei);
    setimei(selectedimei);
    setImeiList(selectedrows);
  }, [itemsrow]);

  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(rmaSubmitAction(getRandomuserParams(params)))
      // .then((res) => {
      //   setLoading(false);
      //   console.log(res.data.data);
      //   setData(res.data.data.data);
      //   setLoading(false);
      .then((res) => {
        console.log(res);
        const paginate = { ...pagination };
        paginate.total = res.data.data.total;
        paginate.current = res.data.data.current_page;
        setData(res.data.data.data);
        setLoading(false);
        setbtnLoading(false);
        console.log(`paginate`, paginate);
        setPagination(paginate);
      })
      .catch((err) => {
        setLoading(false);
        setbtnLoading(false);
      });
  };

  const handleTableChange = (paginate, filter, sorter) => {
    const pager = { ...pagination };
    pager.current = paginate.current;
    // setPagination(pager);
    // fetchData({
    //   results: paginate.pageSize,
    //   page: paginate.current,
    // });
    const order =
      sorter.order === "ascend"
        ? "ASC"
        : sorter.order === undefined
        ? ""
        : "DESC";
    const fieldName = sorter.order === undefined ? "" : sorter.field;
    setPagination(pager);
    setsortField(fieldName);
    setsortOrder(order);
    searchOrders(fieldName, order, pager);
  };

  const searchOrders = (sortName, order, pager, reset) => {
    setLoading(true);
    setbtnLoading(true);
    var office_id = selectedItems.key;
    // var search_text = search_key;
    var sort = sortName ?? sortField;
    var sort_by = order ?? sortOrder;
    console.log(`office_id: ${office_id} sort: ${sort} sort_by: ${sort_by}`);
    var page = reset
      ? 1
      : pager !== ""
      ? pager?.current
      : pagination?.current ?? 1;
    fetchData({
      results: 10,
      page: page,
      office_id: office_id,
      // search_text: search_text,
      sort: sort,
      sort_by: sort_by,
    });
  };

  const clearSearch = () => {
    setLoading(true);
    setselectedoffice(undefined);
    setselactedItems([]);
    setfdata([]);
    setsortField(null);
    setsortOrder(null);
    fetchData({
      results: 10,
      page: 1,
    });
  };

  const onchangeoffice = (selectedoffice) => {
    setselectedoffice(selectedoffice);
  };

  const renderOffice = () => {
    return _.map(props.otheroffice, (dep, index) => {
      // console.log(`dep`, dep)
      // dep.map((genderData) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.office_number}
        </Option>
      );
      // });
    });
  };

  const onRowsSelectionHandler = (ids, rma_ids, selIds) => {
    var olddata1 = JSON.parse(localStorage.getItem("sitemsimei"));
    // console.log(_.union(olddata1, ids));
    // setimei(_.union(olddata1, ids));
    // setImeiList(_.union(olddata1, ids));
    setSelectedRowKeys(rma_ids);
    setHasSelected(_.union(olddata1, ids).length > 0);

    // console.log(ids);
    // setimei(ids);
    // setImeiList(rma_ids);
    // setSelectedRowKeys(rma_ids);
    // setHasSelected(ids.length > 0);
  };

  const columns = [
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-text",
      width: 200,
      // fixed: "right",
      sorter: true,
    },
    {
      title: "Office Name",
      dataIndex: "office_number",
      key: "office_number",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-text",
      width: 200,
      sorter: true,
    },
    {
      title: "Manager Name",
      dataIndex: "manager_name",
      key: "manager_name",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-text",
      width: 200,
      sorter: true,
    },
    {
      title: "Agent Name",
      dataIndex: "agent_name",
      key: "agent_name",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-text",
      width: 200,
      sorter: true,
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-text",
      width: 200,
      sorter: true,
    },
    {
      dataIndex: "status_text",
      key: "status_text",
      title: "status",
      width: 200,
      sortable: false,
      headerAlign: "center",
      headerClassName: "bold-text",
      align: "center",
      //   renderCell: (params) => {
      //     return (
      //       <span>
      //         {params.row.status === 5 ? (
      //           <Chip label={"In Stock"} color="primary" />
      //         ) : null}
      //       </span>
      //     );
      //   },
    },
    {
      dataIndex: "reason_text",
      key: "reason_text",
      title: "Reason 1",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "left",
    },
    {
      dataIndex: "reason_two_text",
      key: "reason_two_text",
      title: "Reason 2",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "left",
    },
    {
      dataIndex: "notes",
      key: "notes",
      title: "Comments",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "left",
      sorter: true,
    },
  ];

  const showDrawer = ({ isApprove }) => {
    setIMEIOpen(true);
    // setImeiList(imei);
    setisApprove(isApprove);
    // var tempRmaList = [];
    imei.map((imei_item, index) => {
      return form.setFieldsValue({
        [`imei_${index}`]: imei_item,
        [`notes_${index}`]: "",
      });
      // return tempRmaList.push({ [`imei_${index}`]: imei_item, 'reason1': "", 'reason2': "", "notes": "" })
    });
    // setRmaList(tempRmaList);
  };
  const onClose = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Are you sure you want to cancel the RMA?",
      // content: "You want to cancel  RMA?",
      onOk: () => {
        setIMEIOpen(false);
        setImeiList([]);
        // setimei([]);
        setSelectedRowKeys([]);
        setHasSelected(false);
        resetform();
        localStorage.removeItem("sitems");
        localStorage.removeItem("sitemsimei");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const resetform = () => {
    form.resetFields();
    imei.map((imei_item, index) => {
      return form.setFieldsValue({
        [`imei_${index}`]: imei_item,
        [`notes_${index}`]: "",
      });
      // return tempRmaList.push({ [`imei_${index}`]: imei_item, 'reason1': "", 'reason2': "", "notes": "" })
    });
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    setSMTbtnLoading(true);
    var imei_list = [];
    console.log("imei", imei, "imeiList", imeiList);
    imeiList.map((item, index) => {
      console.log(item);
      return imei_list.push(parseInt(imeiList[`${index}`]));
    });
    console.log("imei_list", imei_list);
    const params = {
      rma_list: imei_list,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      no_of_boxes: values.no_of_boxes,
      box_length: values.box_length,
      box_width: values.box_width,
      box_height: values.box_height,
      box_weight: values.box_weight,
    };
    console.log("params", params);
    dispatch(ConfirmSubmitRMAAction(params))
      .then((res) => {
        console.log(`response of RMA approve`, res);
        setIMEIOpen(false);
        setSMTbtnLoading(false);
        setImeiList([]);
        setimei([]);
        setSelectedRowKeys([]);
        setHasSelected(false);
        fetchData({
          // url: urlString ?? "",
          results: 10,
          page: 1,
        });
        localStorage.removeItem("sitems");
        localStorage.removeItem("sitemsimei");
        // dispatch(rmaSubmitAction());
      })
      .catch((err) => {
        setIMEIOpen(false);
        setImeiList([]);
        setimei([]);
        setSelectedRowKeys([]);
        setHasSelected(false);
        setSMTbtnLoading(false);
      });
  };

  const onFinishFailed = (values) => {
    console.log("Failed:", values);
    // setimei([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setItemsrow(newSelectedRowKeys);
    if (
      newSelectedRowKeys.length === data.length &&
      imei?.length === data.length &&
      hasSelected
    ) {
      setimei([]);
      setHasSelected(false);
      setSelectedRowKeys([]);
      setImeiList([]);
    } else {
      const tempdata = data;
      var selRow = [];
      var rma_ids = [];
      var selIds = [];
      newSelectedRowKeys.map((selectedRow) => {
        var index = tempdata.findIndex((res) => res.id === selectedRow);
        if (index >= 0) {
          console.log("tempdata", tempdata[index]);
          selRow.push(tempdata[index].imei);
          rma_ids.push(tempdata[index].id);
          selIds.push(tempdata[index].id);
        }
        return selRow;
      });
      console.log(selRow, rma_ids);
      setItemimei(selRow);
      onRowsSelectionHandler(selRow, rma_ids, selIds);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var office_id = selectedItems.key;
    const obj = {
      office_id: office_id,
    };
    console.log(obj);
    dispatch(exportrmaAction(obj))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  const fetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    if (value.length > 1) {
      setfdata([]);
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata1 = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdata(fdata1);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };
  const renderStates = () => {
    return _.map(shortstate, (master, index) => {
      return (
        <Option value={master.short_name} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  return (
    <div className="card">
      <div className="row">
        <div className="col-xl-12">
          {/* <div className="card"> */}
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong>Submit RMA</strong> */} Submit RMA{" "}
              {pagination.total && `(${pagination.total})`}
            </h4>
            <div className="d-flex align-items-center text-right">
              <div className="px-3">
                <Button
                  type="primary"
                  className="mr5"
                  loading={loadingEPbtn}
                  onClick={getexportbtn}
                >
                  <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
                </Button>
              </div>
              <div className="float-end">
                {imei.length !== 0 && (
                  <>
                    <Button
                      type="primary"
                      onClick={() => showDrawer({ isApprove: true })}
                      className="mr5"
                    >
                      Submit RMA
                    </Button>
                  </>
                )}
                {/* {imei.length === 1 && (
                <Link to={"/rmareport?imei=" + imei}>
                  <button type="submit" className="btn btn-primary">
                    Initiate RMA
                  </button>
                </Link>
              )} */}
              </div>
            </div>
          </div>
        </div>
        {/* </div>
      <div className="card"> */}
        <div className="card-body">
          <div style={{ width: "100%" }}>
            <div className="row bg-white padd0">
              <div className="col">
                <label for="exampleFormControlInput1">Select Office</label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  labelInValue
                  placeholder="Type 2 characters to search..!"
                  notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                  filterOption={false}
                  onSearch={fetchUser}
                  defaultActiveFirstOption={false}
                  value={selectedItems}
                  onChange={onchangeRole}
                >
                  {fdata?.map((d) => (
                    <Option key={d.value}>{d.text}</Option>
                  ))}
                </Select>
                {/* <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select office"
                  value={selectedoffice}
                  onChange={onchangeoffice}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderOffice()}
                </Select> */}
              </div>
              {/* <div className="col">
            <label for="exampleFormControlInput1">Search by keyword</label>
            <Input
              placeholder="Search by keyword"
              value={search_key}
              onChange={(e) =>
                setsearch_key(e.target.value)
              }
              style={{ width: "100%", display: "block" }}
            />
          </div> */}
              <div className="col mt-6">
                <Button
                  type="primary"
                  className="mr5"
                  loading={btnloading}
                  onClick={() => searchOrders("", null, null, "reset")}
                >
                  Search
                </Button>
                <Button
                  type="default"
                  style={{ marginLeft: "10px" }}
                  onClick={() => clearSearch()}
                >
                  Clear
                </Button>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-xl-12">
                {/* <div className="card-body">
            <div style={{ width: "100%" }}> */}
                {/* <div style={{ display: "flex", height: "100%" }}>
                  <div style={{ flexGrow: 1 }}> */}
                   
                      <Table
                        columns={columns}
                        dataSource={data}
                        scroll={{ x: true }}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                        rowSelection={rowSelection}
                        // size="small"
                      />
                    {/* <DataGrid
                    sx={{
                      ".MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                    }}
                    autoHeight
                    disableColumnMenu
                    getRowId={(row) => row.imei}
                    rows={props.submitRmaDevices}
                    className={classes.grid}
                    columns={columns}
                    pageSize={pagesize}
                    rowsPerPageOptions={[pagesize]}
                    disableSelectionOnClick
                    checkboxSelection
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      var rma_ids = [];
                      const selectedRows = props.submitRmaDevices.map((row) => {
                        if (selectedIDs.has(row.imei)) {
                          return rma_ids.push(row.id);
                        }
                      }
                      );
                      onRowsSelectionHandler(ids, rma_ids);
                      console.log(ids, rma_ids);
                    }}
                  /> */}
                  {/* </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        width={680}
        onClose={onClose}
        open={IMEIOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          name="basic"
          layout="vertical"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Number of boxes"
                name="no_of_boxes"
                rules={[
                  { required: true, message: "Please input number of boxes" },
                ]}
              >
                <Input placeholder="no_of_boxes" type="number" min="1" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Box Length"
                name="box_length"
                rules={[
                  { required: false, message: "Please input box length" },
                ]}
              >
                <Input placeholder="box_length" type="number" min="1"  />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Box Width"
                name="box_width"
                rules={[{ required: false, message: "Please input box width" }]}
              >
                <Input placeholder="box_width" type="number" min="1"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Box Height"
                name="box_height"
                rules={[
                  { required: false, message: "Please input box height" },
                ]}
              >
                <Input placeholder="box_height" type="number" min="1"  />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Weight"
                name="box_weight"
                rules={[
                  { required: false, message: "Please input Box weight" },
                ]}
              >
                <Input placeholder="box_weight" type="number" min="1"  />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Street Address"
                name="address"
                rules={[{ required: true, message: "Please input Address" }]}
              >
                <Input placeholder="Address" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: "Please input City" }]}
              >
                <Input placeholder="City" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="State"
                name="state"
                rules={[{ required: true, message: "Please input State" }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select State"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                   {renderStates()}
                </Select>
              </Form.Item>
            </Col>
            {/* </Row>
          <Row gutter={16}> */}
            <Col span={12}>
              <Form.Item
                label="ZIP"
                name="zip"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please input ZIP",
                  },
                ]}
              >
                <Input maxLength={5} placeholder="ZIP" />
              </Form.Item>
            </Col>
          </Row>
          <>
            {imeiList.map((item, index) => {
              return (
                <>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name={`imei_${index}`}
                        label={`IMEI ${index + 1}`}
                        key={index}
                        value={item}
                        disabled={true}
                        rules={[
                          {
                            required: true,
                            message: "Please input imei",
                          },
                        ]}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Comment" name={`notes_${index}`} rules={[
                      {
                        required: false,
                        // pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input comment",
                      },
                    ]}>
                      <Input.TextArea
                        rows={2}
                        placeholder="Enter comment"
                      />
                    </Form.Item>
                  </Col>
                </Row> */}
                </>
              );
            })}
          </>
          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 16,
            }}
          >
            <Space>
              {" "}
              <Button type="primary" htmlType="submit" loading={SMTbtnloading}>
                Submit
              </Button>
              <Button type="dashed" onClick={resetform}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    submitRmaDevices: state.devicelist.submitRmaDevices,
    otheroffice: state.devicelist.otheroffice,
  };
};

export default connect(mapStateToProps)(SubmitRMA);
