import React, { useEffect } from "react";
import { withRouter } from "./with-router";
import { message } from "antd";
import moment from "moment-timezone";

const AuthVerify = (props) => {
  let location = props.router.location;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userDetails"));

    if (user) {
        const decodedJwt = user.expiresIn;
        const utc = moment.utc().format(); // UTC date
        console.log(moment(decodedJwt).valueOf())
        console.log(moment(utc).valueOf())
        
        if (moment(decodedJwt).valueOf() < moment(utc).valueOf()) {
            props.logOut();
            console.log("if block")
            message.warning("Your session has expired. Please try login")
        }
      }
  }, [location]);

  return <div></div>;
};

export default withRouter(AuthVerify);