import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getagedinventoryoffice,
  getagedinventoryofficesearch,
} from "../../../store/actions/documentActions";
import { aged_by_office_export } from "../../../store/actions/documentActions";
import _ from "lodash";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Table,
  Tag,
  Input,
  Select,
  Divider,
  Modal,
  Icon,
  message,
  Spin,
  Empty,
} from "antd";
import { getofficelistname } from "../../../store/actions/documentActions";
import axiosInstance from "../../../services/AxiosRootInstance";
import debounce from "lodash/debounce";

const { Option } = Select;

class AgedInventory extends Component {
  constructor(props) {
    super(props);
    this.fetchUser = debounce(this.fetchUser, 800);
    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      loadingEPbtn: false,
      retailer: [],
      selectedItems: [],
      selectedfilter: [],
      sort_list: [],
      aged_inventory: [],
      fdata: [],
      fetching: false,
      retailview: false,
    };
  }

  componentWillMount() {
    this.getdesignation({
      url: this.props.location.urlpath ?? "",
      results: 10,
      page: 1,
    });
    // this.setColumns();
    // this.props.getofficelistname().then((res) => {
    //   console.log(res.data);
    //   this.setState({
    //     sort_list: res.data,
    //   });
    // });
    const Retailer =
      this.props?.user?.role_id?.filter((word) => word === 5) ?? [];
    if (Retailer?.length !== 0) {
      this.setState({
        retailview: true,
      });
    }
  }

  // setColumns() {
  //   this.setState({
  //     columns: [
  //       {
  //         title: "Office Name",
  //         dataIndex: "ofc_name",
  //         key: "ofc_name",
  //         sorter: true,
  //       },
  //       {
  //         title: "DAY 1-30",
  //         dataIndex: "days1_15",
  //         key: "days1_15",
  //         sorter: true,
  //       },
  //       {
  //         title: "DAY 45-60",
  //         dataIndex: "days16_30",
  //         key: "days16_30",
  //         sorter: true,
  //       },
  //       {
  //         title: "DAY 45-60",
  //         dataIndex: "days31_45",
  //         key: "days31_45",
  //         sorter: true,
  //       },
  //       {
  //         title: "DAY 91",
  //         dataIndex: "days91",
  //         key: "days91",
  //         sorter: true,
  //       },
  //     ]
  //   });
  // }

  getdesignation(params = {}) {
    this.props
      .getagedinventoryoffice(params)
      .then((res) => {
        console.log(res);
        this.setData(res.data);
        this.setState({
          aged_inventory: res.data.grand_totals,
        });
      })
      .catch((err) => {
        console.log(`error: `, err);
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchMarketer();
    }, 100);
  };

  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
  };
  // onchangefieldname = (selectedfieldname) => {
  //   this.setState({ selectedfieldname });
  //   console.log(this.state.selectedfieldname);
  // };
  onchangefilter = (selectedfilter) => {
    this.setState({ selectedfilter });
    console.log(this.state.selectedfilter);
  };
  renderSorter() {
    return _.map(this.state.columns, (master, index) => {
      return (
        <Option value={master.key} key={index}>
          {master.title}
        </Option>
      );
    });
  }

  renderOffice() {
    return _.map(this.state.sort_list, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  clearSearch = () => {
    this.setState({ loading: true });
    this.setState({
      selectedItems: [],
      // selectedfieldname: [],
      selectedfilter: [],
      fdata: [],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };

  searchMarketer = (reset) => {
    this.setState({ loading: true, loadingbtn: true });
    var summary = this.state.selectedItems.key;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;

    this.props
      .getagedinventoryofficesearch({
        summary: summary,
        page: reset ? 1 : this.state.pagination?.current ?? 1,
        sort,
        sort_by,
      })
      .then((res) => {
        console.log(res);
        this.setData(res.data);
        this.setState({
          aged_inventory: res.data.grand_totals,
          loadingbtn: false,
        });
      })
      .catch((err) => {
        this.setState({ loadingbtn: false });
      });
  };

  setData = (resData) => {
    const pagination = { ...this.state.pagination };
    pagination.total = resData.items.total;
    pagination.current = resData.items.current_page;

    this.setState({
      data: resData.items.data,
      loading: false,
      pagination,
    });
  };
  getexportbtn = () => {
    var office = this.state.selectedItems.key;
    this.setState({ loading: true, loadingEPbtn: true });
    this.props
      .aged_by_office_export({ summary: office })
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };
  fetchUser = (value) => {
    console.log("fetching user", value);
    if (value.length > 1) {
      this.setState({ fdata: [], fetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fdata, fetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // ======================================================

  render() {
    const { aged_inventory, fetching, fdata, retailview } = this.state;
    const columns = [
      {
        title: "Office ",
        dataIndex: "office_name",
        key: "office_name",
        sorter: true,
        // render: (ofc_name, record) => (
        //   <Link
        //     to={{
        //       pathname: "/agedbyagent",
        //       urlpath: `/api/v1/aged_inventory_summary_reports/agent?office_id=${record.office_id}`,
        //     }}
        //   >
        //     {ofc_name}
        //   </Link>
        // ),
      },
      {
        title: "Device Type",
        dataIndex: "Product_Type",
        key: "Product_Type",
        sorter: true,
        // hidden: !retailview,
      },
      {
        title: "1-15 DAYS",
        dataIndex: "days1_15",
        key: "days1_15",
        sorter: true,
      },
      {
        title: "16-30 DAYS",
        dataIndex: "days16_30",
        key: "days16_30",
        sorter: true,
      },
      {
        title: "31-45 DAYS",
        dataIndex: "days31_45",
        key: "days31_45",
        sorter: true,
      },
      {
        title: "46-90 DAYS",
        dataIndex: "days46_90",
        key: "days46_90",
        sorter: true,
      },
      {
        title: ">90 DAYS",
        dataIndex: "days91",
        key: "days91",
        sorter: true,
      },
    ].filter((item) => !item.hidden);

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                Aged by Offices{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-end">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                {/* <i className="uil uil-export" /> Export Report */}
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
            </div>
          </div>
          <Spin spinning={this.state.loading}>
            <div className="row mt-4">
              <div className="col">
                <div className="border shadow-sm mb-2">
                  <div className="bg-primary p-2">
                    <h5 className="mb-0 text-white text-center">1-15 DAYS</h5>
                  </div>
                  <div className="p-3 bg-white">
                    <h4 className="text-center mb-0">
                      {aged_inventory.days1_15}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border shadow-sm mb-2">
                  <div className="bg-primary p-2">
                    <h5 className="mb-0 text-white text-center">16-30 DAYS</h5>
                  </div>
                  <div className="p-3 bg-white">
                    <h4 className="text-center mb-0">
                      {aged_inventory.days16_30}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border shadow-sm mb-2">
                  <div className="bg-primary pt-2 pb-2">
                    <h5 className="mb-0 text-white text-center">31-45 DAYS</h5>
                  </div>
                  <div className="p-3 bg-white">
                    <h4 className="text-center mb-0">
                      {aged_inventory.days31_45}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border shadow-sm mb-2">
                  <div className="bg-primary p-2">
                    <h5 className="mb-0 text-white text-center">46-90 DAYS</h5>
                  </div>
                  <div className="p-3 bg-white">
                    <h4 className="text-center mb-0">
                      {aged_inventory.days46_90}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border shadow-sm mb-2">
                  <div className="bg-primary p-2">
                    <h5 className="mb-0 text-white text-center">
                    {"\u003E"}90 DAYS
                    </h5>
                  </div>
                  <div className="p-3 bg-white">
                    <h4 className="text-center mb-0">
                      {aged_inventory.days91}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <div className="row padd20 bg-grey mt-3">
            <div className="col-4">
              <label for="exampleFormControlInput1">Search Office</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                labelInValue
                placeholder="Type 2 characters to search..!"
                notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={this.fetchUser}
                defaultActiveFirstOption={false}
                value={this.state.selectedItems}
                onChange={this.onchangeRole}
              >
                {fdata.map((d) => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
            </div>
            {/* <div class="col">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select field name"
                value={this.state.selectedfieldname}
                onChange={this.onchangefieldname}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderSorter()}
              </Select>
            </div>
            <div class="col">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select filter order"
                value={this.state.selectedfilter}
                onChange={this.onchangefilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="ASC">ASC</Option>
                <Option value="DESC">DESC</Option>
              </Select>
            </div> */}
            <div className="col mt-6 mt-4">
              <Button
                type="primary"
                className="mx-2"
                loading={this.state.loadingbtn}
                onClick={() => this.searchMarketer("reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0 mt-4">
            <Table
              rowKey={Math.random().toString()}
              columns={columns}
              dataSource={this.state.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    ibomarket: state.data,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  getagedinventoryoffice,
  getofficelistname,
  getagedinventoryofficesearch,
  aged_by_office_export,
})(AgedInventory);
