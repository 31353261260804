import axiosInstance from "../../services/AxiosRootInstance";

export function getibomarket(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/ibo_marketers?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchibomarket({
  status,
  search_text,
  retailer,
  page,
  sort,
  sort_by,
}) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/ibo_marketers?status=${status ?? ""}&search_text=${
        search_text ?? ""
      }&retailer=${retailer ?? ""}&sort=${sort ?? ""}&sort_by=${
        sort_by ?? ""
      }&page=${page ?? 1}`;
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getretailers() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/retailers")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
// Send Agent/IBO Marketer/Office Documents
export function sendform(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`sendform`, params);
      // resolve("Success");
      axiosInstance
        .post("/api/v1/senddocument", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Download Agent/Marketer/Office Forms
export function downloadformapp(user_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`user_id`, user_id);

      axiosInstance
        .get(`/api/v1/get_document/${user_id}`)
        .then((response) => {
          console.log(`response`, response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Update Agent/Marketer/Office Forms status
export function updateformstatus(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`updateformstatus`, params);
      axiosInstance
        .post(`/api/v1/accept_reject`, params)
        .then((response) => {
          console.log(`response`, response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function ibo_market_export(search_text,status) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/ibo_market_export?search_text=${search_text ?? ""}&status=${status ?? ""}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function marketerdeleteaction(id, stat) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(id);
      var obj_arr = {
        status: stat,
      };
      axiosInstance
        .post(`/api/v1/ibo_marketers/action/${id}`, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
