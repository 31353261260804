import { GET_DOCUMENTS } from "../actions/DocumentTypes";

const initialState = {
    documents: [],
};

export default function DocumentReducer(state = initialState, actions) {
    if (actions.type === GET_DOCUMENTS) {
        const documents = [...state.documents];
        return {
            ...state,
            documents,
        };
    }
    return state;
}
