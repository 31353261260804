import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getOfficelist,
  searchofficelist,
  getibomarketlistnew,
  getsortlistoffice,
  officedeleteaction,
  sendform,
  downloadform,
  updateformstatus,
  office_export,
} from "../../../store/actions/documentActions";
import _ from "lodash";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Table,
  Tag,
  Input,
  Select,
  message,
  Divider,
  Modal,
  Tooltip,
  Form,
  Radio,
} from "antd";
import {
  EditOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
  LinkOutlined,
  QrcodeOutlined,
  ShopOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const RadioGroup = Radio.Group;

class Offices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      office: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      loadingEPbtn: false,
      market: [],
      selectedItems: [],
      superrole: "",
      selectedfieldname: [],
      selectedfilter: [],
      sort_list: [],
      marketrole: false,
      selectedRowKeys: [],
      hasSelected: false,
      retailview: false,
      downloadLoading: false,
      visible: false,
      record: null,
    };
  }

  componentWillMount() {
    const Retailer =
      this.props?.user?.role_id?.filter((role) => role === 12) ?? [];
    if (Retailer?.length !== 0) {
      this.setState({
        retailview: true,
      });
    }

    const marketer = this.props?.user?.role_id?.filter(
      (word) => word === 4 || word === 8
    );
    console.log(marketer);

    if (marketer.length !== 0) {
      this.setState({
        marketrole: true,
      });
    }
    this.getdesignation({
      results: 10,
      page: 1,
    });
    console.log("user_id", this.props.user);
    this.props.getibomarketlistnew(this.props.user?.user_id).then((res) => {
      console.log(res.data);
      this.setState({
        market: res.data,
      });
    });
  }
  getdesignation(params = {}) {
    this.setState({ loading: true });
    this.props
      .getOfficelist(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page;
        this.setState({
          office: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        message.error(err);
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(filter);
    var status = filter?.status ?? [];
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      selectedstatus: status.toString(),
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchDistributor();
    }, 100);
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };
  searchDistributor = (reset) => {
    var status = this.state.selectedstatus;
    var ibo_marketer = this.state.selectedItems;
    var search_text = this.state.agent;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var page = reset ? 1 : this.state.pagination?.current ?? 1;
    this.setState({
      loading: true,
      loadingbtn: true,
    });
    this.props
      .searchofficelist({
        status: status,
        ibo_marketer: ibo_marketer,
        search_text: search_text,
        page: page,
        sort,
        sort_by,
      })
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page;
        this.setState({
          office: res.data.items.data,
          loading: false,
          loadingbtn: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          loadingbtn: false,
        });
      });
  };

  clearSearch = () => {
    this.setState({
      status: undefined,
      name: "",
      agent: "",
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  renderMarketer() {
    return _.map(this.state.market, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
    console.log(this.state.selectedItems);
  };
  getrefreshbtn = () => {
    this.clearSearch();
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  onchangefieldname = (selectedfieldname) => {
    this.setState({ selectedfieldname });
    console.log(this.state.selectedfieldname);
  };
  onchangefilter = (selectedfilter) => {
    this.setState({ selectedfilter });
    console.log(this.state.selectedfilter);
  };
  renderSorter() {
    return _.map(this.state.sort_list, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  getexportbtn = () => {
    var search_text = this.state.agent;
    var status = this.state.selectedstatus;
    this.setState({ loading: true, loadingEPbtn: true });
    this.props
      .office_export(search_text, status)
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };

  onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    if (
      newSelectedRowKeys.length === this.state.office.length &&
      this.state.hasSelected
    ) {
      this.setState({
        selectedRowKeys: [],
        hasSelected: false,
      });
    } else {
      const tempdata = this.state.office;
      var selRow = [];
      // var tempMarketersList = [];
      newSelectedRowKeys.map((selectedRow) => {
        var index = tempdata.findIndex((res) => res.id === selectedRow);
        if (index >= 0) {
          if (
            tempdata[index].form_status === undefined ||
            tempdata[index].form_status === null
          ) {
            selRow.push(selectedRow);
            // tempMarketersList.push(tempdata[index].get_manager_info[0].user_id);
          }
        }
        return selRow;
      });
      this.setState({
        selectedRowKeys: selRow,
        // marketersList: tempMarketersList,
        hasSelected: selRow.length > 0,
      });
    }
  };

  handleSendDocument = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    var ids = this.state.selectedRowKeys?.map(
      (selectedRow, index) => selectedRow
    );
    const params = { type: "office", ids: ids };
    console.log(`params`, params, "\nids", ids);
    this.props
      .sendform(params)
      .then((res) => {
        message.success(res.data?.message ?? "Document sent successfully");
        this.setState({
          loading: false,
          selectedRowKeys: [],
          hasSelected: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        message.error(err);
      });
  };

  handleDownload = (e, record) => {
    e.preventDefault();
    console.log(`Office record`, record);
    this.setState({ downloadLoading: true });
    this.props
      .downloadform(record.get_manager_info[0].user_id)
      .then((response) => {
        console.log("download form", response);
        window.open(response?.data?.url ?? "", "_blank");
        this.setState({ downloadLoading: false });
      })
      .catch((ex) => {
        console.log(`error downloading form`, ex);
        this.setState({ downloadLoading: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields, setFields },
    } = this.props;
    console.log(`record`, this.state.record, "form.values", this.props.form);
    validateFields((err, values) => {
      if (!err) {
        const isAccept = this.props.form.getFieldValue("IsAccept");
        const reason = this.props.form.getFieldValue("reason");
        console.log(
          `reason`,
          this.props.form.getFieldValue("IsAccept"),
          this.props.form.getFieldValue("reason")
        );
        var params = {
          id: this.state.record.get_manager_info[0].user_id,
          status: isAccept ? 4 : 3,
        };
        if (!isAccept) {
          params.reason = reason;
        }
        this.props.updateformstatus(params).then((response) => {
          console.log("update form", response);
          message.success(
            response.data?.message ?? "Form updated successfully"
          );
          this.setState({
            loading: false,
            selectedRowKeys: [],
            hasSelected: false,
            visible: false,
          });
          this.clearSearch();
        });
      } else {
        setFields({
          reason: {
            value: this.state.reason,
            errors: [new Error("Enter reason for rejecting")],
          },
        });
      }
    });
  };

  showModal = ({ record }) => {
    this.setState({
      visible: true,
      record: record,
    });
    this.props.form.resetFields();
  };

  // ======================================================

  render() {
    const {
      office,
      superrole,
      marketrole,
      selectedRowKeys,
      hasSelected,
      retailview,
      downloadLoading,
    } = this.state;
    const rowSelection = retailview
      ? {
          selectedRowKeys,
          onChange: this.onSelectChange,
        }
      : {};
    const columns = [
      {
        title: "Master",
        dataIndex: "master_agent_name",
        key: "master_agent_name",
        sorter: true,
        hidden: superrole === true ? false : true,
      },
      {
        title: "Distributor",
        dataIndex: "distributor_name",
        key: "distributor_name",
        sorter: true,
        hidden: superrole === true ? false : true,
      },
      {
        title: "Retailer",
        dataIndex: "retailer_name",
        key: "retailer_name",
        sorter: true,
        hidden: superrole === true ? false : true,
      },
      {
        title: "IBOs / Markets",
        dataIndex: "ibo_marketer_name",
        key: "ibo_marketer_name",
        sorter: true,
        hidden: retailview === true ? false : true,
      },
      {
        title: "Office",
        dataIndex: "name",
        key: "name",
        sorter: true,
      },
      {
        title: "IBOs / Manager",
        dataIndex: "get_ibo_manager_info",
        key: "get_ibo_manager_info",
        sorter: true,
        render: (tags) => (
          <span>
            {tags.map((tag) => {
              return (
                <Tag color="volcano" key={tag.user_id}>
                  {tag.name}
                </Tag>
              );
            })}
          </span>
        ),
      },
      // {
      //   title: "IBO Add-on Manager",
      //   dataIndex: "get_ibo_supervisor_info",
      //   key: "get_ibo_supervisor_info",
      //   sorter: true,
      //   render: (tags) => (
      //     <span>
      //       {tags.map((tag) => {
      //         return (
      //           <Tag color="volcano" key={tag.user_id}>
      //             {tag.name}
      //           </Tag>
      //         );
      //       })}
      //     </span>
      //   ),
      // },

      {
        title: "Office Manager",
        dataIndex: "get_manager_info",
        key: "get_manager_info",
        sorter: true,
        render: (tags) => (
          <span>
            {tags.map((tag) => {
              return (
                <Tag color="volcano" key={tag.user_id}>
                  {tag.name}
                </Tag>
              );
            })}
          </span>
        ),
      },
      {
        title: "Office Add-on Manager",
        dataIndex: "get_supervisor_info",
        key: "get_supervisor_info",
        sorter: true,
        render: (tags) => (
          <span>
            {tags.map((tag) => {
              return (
                <Tag color="volcano" key={tag.user_id}>
                  {tag.name}
                </Tag>
              );
            })}
          </span>
        ),
      },
      {
        title: "Created Date",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
      },
      // {
      //   title: "Onboarding Link",
      //   dataIndex: "oaction",
      //   width: 150,
      //   key: "oaction",
      //   render: (text, record) => (
      //     <span>
      //       <Tooltip title="Download Onboarding QR Code">
      //         <Button
      //           type="default"
      //           shape="circle"
      //           className="padd5 mr5"
      //           icon={<QrcodeOutlined />}
      //           style={{ cursor: "pointer" }}
      //           onClick={() => {
      //             window.open(record.qr_code_url, "_blank");
      //           }}
      //         />
      //       </Tooltip>
      //       <Divider type="vertical" />
      //       <Tooltip title="Copy Onboarding Link">
      //         <Button
      //           type="default"
      //           shape="circle"
      //           className="padd5 mr5"
      //           icon={<LinkOutlined />}
      //           style={{ cursor: "pointer" }}
      //           onClick={() => {
      //             navigator.clipboard
      //               .writeText(record.onboarding_link)
      //               .then(() => {
      //                 message.success("COPIED");
      //               })
      //               .catch((err) => {
      //                 console.log(err.message);
      //               });
      //           }}
      //         />
      //       </Tooltip>
      //     </span>
      //   ),
      // },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        filters: [
          { text: "Active", value: 1 },
          { text: "Inactive", value: 0 },
        ],
        render: (tag) => (
          <span>
            <Tag color={tag === 1 ? "green" : "orange"}>
              {tag ? "Active" : "Inactive"}
            </Tag>
          </span>
        ),
      },
      // {
      //   title: "Form Status",
      //   dataIndex: "form_status",
      //   key: "form_status",

      //   fixed: "right",
      //   width: 100,
      //   hidden: !retailview,
      //   render: (text, record) => (
      //     <span>
      //       <Tag
      //         color={
      //           record.form_status === undefined || record.form_status === null
      //             ? "red"
      //             : record.form_status?.toLowerCase() === "completed"
      //             ? "green"
      //             : "orange"
      //         }
      //       >
      //         {record.form_status === undefined || record.form_status === null
      //           ? "Not-Started"
      //           : record.form_status}
      //       </Tag>
      //     </span>
      //   ),
      // },
      {
        title: "Action",
        key: "action",
        width: retailview ? 200 : 150,
        fixed: "right",
        hidden: superrole === true ? true : false,
        render: (text, record) => (
          <div>
            <div>
              <span>
                {this.state.superrole !== true ? (
                  <>
                    <Link
                      className="padd5 mr5"
                      style={{ marginRight: 10 }}
                      to={{ pathname: "/editoffice", office: record }}
                    >
                      <Tooltip title="Edit Office">
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<EditOutlined />}
                        />
                      </Tooltip>
                    </Link>
                    <Divider type="vertical" />
                  </>
                ) : null}

                {record.status === 1 ? (
                  <Tooltip title="InActive Office">
                    <Button
                      type="danger"
                      shape="circle"
                      className="padd5 mr5"
                      icon={<ShopOutlined />}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        console.log(record.id);
                        Modal.confirm({
                          title: "Are you sure?",
                          content: "Do you want to  inactivate office ?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: () => {
                            console.log(`Ok`);
                            this.props
                              .officedeleteaction(record.id, "0")
                              .then((res) => {
                                this.getdesignation({
                                  results: 10,
                                  page: 1,
                                });
                                message.success("Office deleted successfully");
                              })
                              .catch((err) => {
                                message.error(err);
                                console.log(err);
                              });
                          },
                        });
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Activate Office">
                    <Button
                      type="primary"
                      className="padd5 mr5"
                      shape="circle"
                      icon={<ShopOutlined />}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        console.log(record.id);
                        Modal.confirm({
                          title: "Are you sure?",
                          content: "Do you want to active office ?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: () => {
                            console.log(`Ok`);
                            this.props
                              .officedeleteaction(record.id, "1")
                              .then((res) => {
                                this.getdesignation({
                                  results: 10,
                                  page: 1,
                                });
                                message.success("Office updated successfully");
                              })
                              .catch((err) => {
                                message.error(err);
                                console.log(err);
                              });
                          },
                        });
                      }}
                    />
                  </Tooltip>
                )}
                {/* {retailview ? (
                  <>
                    <Divider type="vertical" />
                    <Link className="padd5 mr5" to="#">
                      <Tooltip title="Download form">
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<DownloadOutlined />}
                          disabled={
                            record.form_status === undefined ||
                            record.form_status === null ||
                            downloadLoading ||
                            (record.form_status?.toLowerCase() !== "rejected" &&
                              record.form_status?.toLowerCase() !==
                                "accepted" &&
                              record.form_status?.toLowerCase() !== "completed")
                          }
                          onClick={(e) => {
                            if (
                              record.form_status !== undefined &&
                              record.form_status !== null &&
                              (record.form_status?.toLowerCase() ===
                                "rejected" ||
                                record.form_status?.toLowerCase() ===
                                  "accepted" ||
                                record.form_status?.toLowerCase() ===
                                  "completed")
                            ) {
                              // if (!downloadLoading) {
                              this.handleDownload(e, record);
                            }
                          }}
                        />
                      </Tooltip>
                    </Link>
                    {record.form_status !== undefined &&
                    record.form_status !== null &&
                    record.form_status?.toLowerCase() === "completed" ? (
                      <>
                        <Divider type="vertical" />
                        <Tooltip title="Accept/Reject forms">
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<CloseCircleOutlined />}
                            disabled={
                              record.form_status === undefined ||
                              record.form_status === null
                            }
                            onClick={(e) => {
                              if (
                                record.form_status !== undefined &&
                                record.form_status !== null
                              ) {
                                // this.handleReject(e, record)
                                this.showModal({ record: record });
                                console.log(record.id);
                              }
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : null}
                  </>
                ) : null} */}
              </span>
            </div>
          </div>
        ),
      },
    ].filter((item) => !item.hidden);

    return (
      <div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="container-fluid">
                <div className="card-header">
                  <div className="col-sm-4">
                    <h4>
                      Offices{" "}
                      {this.state.pagination.total &&
                        `(${this.state.pagination.total})`}
                    </h4>
                  </div>
                  <div className="col-sm-8 text-end">
                    {/* {this.rendercsvformat(data)} */}

                    <Button
                      type="primary"
                      className="mr5"
                      loading={this.state.loadingEPbtn}
                      onClick={() => this.getexportbtn()}
                    >
                      {/* <i className="uil uil-export" /> Export Report */}
                      <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
                    </Button>
                    <Button
                      type="default"
                      className="mr5"
                      style={{ marginLeft: 10 }}
                      onClick={() => this.getrefreshbtn()}
                    >
                      <i className="uil uil-sync" /> Refresh
                    </Button>
                    {this.state.superrole !== true ? (
                      <Link to="/addoffice">
                        <Button type="primary" style={{ marginLeft: 10 }}>
                          Add Office
                        </Button>
                      </Link>
                    ) : null}
                  </div>
                </div>
                <div className="row padd20 bg-grey d-flex mb-4 mt-4">
                  <div className="row">
                    <div className="col-4 mb-2">
                      <label for="exampleFormControlInput1">
                        Search By Keyword
                      </label>
                      <Input
                        placeholder="Type Keyword"
                        value={this.state.agent}
                        onChange={(e) =>
                          this.setState({
                            agent: e.target.value,
                          })
                        }
                        style={{ width: "100%", display: "block" }}
                      />
                    </div>
                    
                  <div className="col mt-6">
                    <Button
                      type="primary"
                      className="mr5"
                      loading={this.state.loadingbtn}
                      onClick={() => this.searchDistributor("reset")}
                    >
                      Search
                    </Button>
                    <Button
                      type="default"
                      style={{ marginLeft: 15 }}
                      onClick={() => this.clearSearch()}
                    >
                      Clear
                    </Button>
                    {hasSelected ? (
                      <Button
                        type="primary"
                        className="login-form-button"
                        loading={this.state.loading}
                        onClick={this.handleSendDocument}
                      >
                        Send Document
                      </Button>
                    ) : null}
                  </div>
                  </div>

                </div>
                <div className="bg-white padd0">
                  <Table
                    columns={columns}
                    dataSource={office}
                    scroll={{ x: 1800 }}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    // rowSelection={retailview ? rowSelection : undefined}
                    // size="small"
                  />
                </div>
              </div>
              <div className="row padd20 bg-grey">
                <Modal
                  title={"Accept/Reject documents"}
                  visible={this.state.visible}
                  // onOk={this.handleSubmit}
                  onCancel={() => {
                    this.setState({
                      visible: false,
                      record: null,
                    });
                  }}
                  footer={null}
                >
                  <Form className="" onSubmit={this.handleSubmit}>
                    <Form.Item
                      label="Accept/Reject"
                      className="col-md-2 col-sm-3 col-xs-12 paddlr15"
                      name="IsAccept"
                      rules={[
                        {
                          required: true,
                          message: "Please input is accept!",
                        },
                      ]}
                    >
                      <RadioGroup>
                        <Radio value={true}>Accept</Radio>
                        <Radio value={false}>Reject</Radio>
                      </RadioGroup>
                    </Form.Item>

                    <div className="paddlr15">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        // loading={loading}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.auth.auth,
    userinfo: state.userinfo,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  getOfficelist,
  searchofficelist,
  getibomarketlistnew,
  getsortlistoffice,
  office_export,
  officedeleteaction,
  sendform,
  downloadform,
  updateformstatus,
})(Offices);
