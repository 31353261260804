import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getPostsAction } from "../../../store/actions/PostActions";

import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { forcepassword, logout } from "../../../store/actions/AuthActions";
import { Spin } from "antd";

const Dashboard = (props) => {
  const { user } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = useState(true);

  let errorsObj = { email: "" };
  const [errors, setErrors] = useState(errorsObj);
  useEffect(() => {
    dispatch(getPostsAction())
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    // const interval = setInterval(() => {
    //   dispatch(getPostsAction());
    //   dispatch(getDeviceAction());
    // }, 5000);
    // return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    console.log(user);
    if (user.password_changed === 0) {
      setOpen(true);
    }
  }, [user]);

  console.log(props);
  const handleClose = (event, reason) => {
    //console.log(event,reason)
    if (reason && reason === "backdropClick") return;
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  const onSubmit = (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (newpass !== oldpass) {
      console.log(oldpass);
      errorObj.email = "Password Mismatch";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    console.log(oldpass, newpass, user.idToken);
    dispatch(forcepassword(newpass, user.idToken))
      .then((res) => {
        console.log(res);
        setOpen(false);
        dispatch(logout(props.history));
      })
      .catch((err) => {
        console.log(err);
      });
    // history.push("/login");
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="row">
          <div className="col-xl-3 col-sm-6">
            {/* <Link to="/InventoryDetailedReport?status=13"> */}
            <Link to="/InventoryDetailedReport?status=13">
              <div className="card gradient-2 card-bx">
                <div className="card-body d-flex align-items-center">
                  <div className="me-auto text-white">
                    <h2 className="text-white">
                      {props.dashdata.Received +
                        props.dashdata.pending_manager +
                        props.dashdata.assigned +
                        props.dashdata.In_stock +
                        props.dashdata.manager_not_received +
                        props.dashdata.transfer}
                    </h2>
                    <span className="fs-18"> In Stock</span>
                  </div>
                  <i
                    style={{ color: "#fff" }}
                    className="fa fa-4x fa-cubes"
                  ></i>
                </div>
              </div>
            </Link>
          </div>
          {/* <div className="col-xl-6 col-sm-6">
            <div className="card gradient-4 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">{props.dashdata.sold}</h2>
                  <span className="fs-18">Sold </span>
                </div>
                <i
                  style={{ color: "#fff" }}
                  className="fa fa-4x fa-luggage-cart"
                ></i>
              </div>
            </div>
          </div> */}
          <div className="col-xl-3 col-sm-6">
          {/* <Link to="/InventoryDetailedReport?status=9"> */}
          <Link to="/ReceiveInventory">
              <div className="card gradient-1 card-bx">
                <div className="card-body d-flex align-items-center">
                  <div className="me-auto text-white">
                    <h2 className="text-white">
                      {props.dashdata.pending_manager}
                    </h2>
                    <span className="fs-18">Manager Pending </span>
                  </div>
                  <i style={{ color: "#fff" }} className="fa fa-4x fa-cube"></i>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-xl-3 col-sm-6">
          {/* <Link to="/InventoryDetailedReport?status=3"> */}
          <Link to="/assignagent">
              <div className="card gradient-3 card-bx">
                <div className="card-body d-flex align-items-center">
                  <div className="me-auto text-white">
                    <h2 className="text-white">{props.dashdata.Received}</h2>
                    <span className="fs-18"> Manager Received</span>
                  </div>
                  <i
                    style={{ color: "#fff" }}
                    className="fa fa-4x fa-user-friends"
                  ></i>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-xl-3 col-sm-6">
          <Link to="/InventoryDetailedReport?status=10">
            <div className="card gradient-5 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">{props.dashdata.rma}</h2>
                  <span className="fs-18">RMA</span>
                </div>
                <i style={{ color: "#fff" }} className="fa fa-4x fa-clock"></i>
              </div>
            </div>
            </Link>
          </div>
          <div className="col-xl-3 col-sm-6">
          <Link to="/InventoryDetailedReport?status=11">
            <div className="card gradient-5 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">{props.dashdata.rma_pending}</h2>
                  <span className="fs-18">Pending RMA</span>
                </div>
                <i style={{ color: "#fff" }} className="fa fa-4x fa-clock"></i>
              </div>
            </div>
            </Link>
          </div>
          <div className="col-xl-3 col-sm-6">
          <Link to="/InventoryDetailedReport?status=4">
              <div className="card gradient-6 card-bx">
                <div className="card-body d-flex align-items-center">
                  <div className="me-auto text-white">
                    <h2 className="text-white">{props.dashdata.assigned}</h2>
                    <span className="fs-18">Agent Pending</span>
                  </div>
                  <i style={{ color: "#fff" }} className="fa fa-4x fa-user"></i>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-xl-3 col-sm-6">
          <Link to="/InventoryDetailedReport?status=5">
              <div className="card gradient-7 card-bx">
                <div className="card-body d-flex align-items-center">
                  <div className="me-auto text-white">
                    <h2 className="text-white">{props.dashdata.In_stock}</h2>
                    <span className="fs-18">Agent Received</span>
                  </div>
                  <i
                    style={{ color: "#fff" }}
                    className="fa fa-4x fa-mobile-alt"
                  ></i>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-xl-3 col-sm-6">
          {/* <Link to="/InventoryDetailedReport?status=6"> */}
          <Link to="/TransferRequest">
              <div className="card gradient-8 card-bx">
                <div className="card-body d-flex align-items-center">
                  <div className="me-auto text-white">
                    <h2 className="text-white">{props.dashdata.transfer}</h2>
                    <span className="fs-18">Agent Transfer</span>
                  </div>
                  <i
                    style={{ color: "#fff" }}
                    className="fa fa-4x fa-exchange-alt"
                  ></i>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-xl-3 col-sm-6">
          <Link to="/InventoryDetailedReport?status=15">
              <div className="card gradient-8 card-bx">
                <div className="card-body d-flex align-items-center">
                  <div className="me-auto text-white">
                    <h2 className="text-white">{props.dashdata.device_swap}</h2>
                    <span className="fs-18">Device Swap</span>
                  </div>
                  <i
                    style={{ color: "#fff" }}
                    className="fa fa-4x fa-exchange-alt"
                  ></i>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-xl-3 col-sm-6">
          <Link to="/InventoryDetailedReport?status=12">
            <div className="card gradient-9 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">{props.dashdata.manager_not_received}</h2>
                  <span className="fs-18">Manager Not Received</span>
                </div>
                <i
                  style={{ color: "#fff" }}
                  className="fa fa-4x fa-user-friends"
                ></i>
              </div>
            </div>
            </Link>
          </div>
        </div>

        {/* <RequestDatatable data={props.device} /> */}
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter your new password below.
            </DialogContentText>
            <form onSubmit={(e) => onSubmit(e)}>
              <label className="text-label mt-4">New Password *</label>
              <div className="input-group transparent-append mb-2">
                <input
                  required
                  type={`${showPassword1 ? "text" : "password"}`}
                  className="form-control"
                  placeholder="Type Your Password"
                  onChange={(e) => setOldpass(e.target.value)}
                />
                <div
                  className="input-group-text"
                  onClick={() => setShowPassword1(!showPassword1)}
                >
                  <i
                    className={`${
                      showPassword1 ? "fa fa-eye" : "fa fa-eye-slash"
                    }`}
                  />
                </div>
              </div>
              <label className="text-label">Confirm Password *</label>
              <div className="input-group transparent-append mb-2">
                <input
                  required
                  type={`${showPassword2 ? "text" : "password"}`}
                  className="form-control"
                  placeholder="Type Your Password"
                  onChange={(e) => setNewpass(e.target.value)}
                />
                <div
                  className="input-group-text"
                  onClick={() => setShowPassword2(!showPassword2)}
                >
                  <i
                    className={`${
                      showPassword2 ? "fa fa-eye" : "fa fa-eye-slash"
                    }`}
                  />
                </div>
                <div
                  id="val-username1-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                >
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
              </div>

              <div className="text-center mt-5">
                <button type="submit" className="btn btn-primary btn-block">
                  SUBMIT
                </button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </Spin>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.auth,
    dashdata: state.posts.posts,
    device: state.devicelist,
  };
};

export default connect(mapStateToProps)(Dashboard);
