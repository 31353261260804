import React from "react";
const Setting = () => {
	return (
		<>
		
		</>
	);
};

export default Setting;


