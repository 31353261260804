import React, { useState, useEffect } from "react";
import {
  rmaApprovedAction,
  AcceptRejectRMAAction,
  Getibomarketlistnew,
  RmaOrderStatusAction,
  update_rma_order,
  exportRmaApprovedAction,
} from "../../../store/actions/DeviceActions";
import { EyeOutlined } from "@ant-design/icons";
import { connect, useDispatch } from "react-redux";
import {
  Drawer,
  Space,
  Input,
  Col,
  Form,
  Row,
  Tooltip,
  Button,
  Table,
  Modal,
  Select,
  message,
} from "antd";
import Chip from "@mui/material/Chip";
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import _ from "lodash";
const { confirm } = Modal;
const { Option } = Select;

const ApprovedRMA = (props) => {
  //console.log(props.device);
  // const [pagesize, setpagesize] = useState(10);
  const [imei, setimei] = useState([]);
  const [IMEIOpen, setIMEIOpen] = useState(false);
  const [form] = Form.useForm();
  const [imeiList, setImeiList] = useState([]);
  const [isApprove, setisApprove] = useState(false);
  const [data, setData] = useState([]);
  const [hasSelected, setHasSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnloading, setbtnLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [selectedImeis, setselectedImeis] = useState([]);
  const [retailerim, setretailerim] = useState(false);
  const [sortField, setsortField] = useState(null);
  const [sortOrder, setsortOrder] = useState(null);
  const [selectedorderstatus, setselectedorderstatus] = useState(null);
  const [selectedibo, setselectedibo] = useState(null);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [inventorymanagerview, setInventorymanagerview] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    fetchData({
      // url: urlString ?? "",
      results: 10,
      page: 1,
    });
    dispatch(Getibomarketlistnew(props?.auth?.user_id));
    dispatch(RmaOrderStatusAction());
    const retailerinventorymanager =
      props?.auth?.role_id?.filter((word) => word === 12) ?? [];
    if (retailerinventorymanager?.length !== 0) {
      setretailerim(true);
    }
    // dispatch(rmaApprovedAction());
    const inventorymanager =
      props?.auth?.role_id?.filter((word) => word === 11 || word === 12) ?? [];
    if (inventorymanager?.length !== 0) {
      setInventorymanagerview(true);
    }
  }, []);

  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const fetchData = (params = {}) => {
    setLoading(true);
    // setbtnLoading(true);
    dispatch(rmaApprovedAction(getRandomuserParams(params)))
      .then((res) => {
        console.log(res);
        const paginate = { ...pagination };
        paginate.total = res.data.data.total;
        paginate.current = res.data.current_page;
        setData(res.data.data.data);
        setLoading(false);
        setbtnLoading(false);
        console.log(`paginate`, paginate);
        setPagination(paginate);
      })
      .catch((err) => {
        setLoading(false);
        setbtnLoading(false);
      });
  };

  const handleTableChange = (paginate, filter, sorter) => {
    const pager = { ...pagination };
    pager.current = paginate.current;
    setPagination(pager);
    const order =
      sorter.order === "ascend"
        ? "ASC"
        : sorter.order === undefined
        ? ""
        : "DESC";
    const fieldName = sorter.order === undefined ? "" : sorter.field;
    setPagination(pager);
    setsortField(fieldName);
    setsortOrder(order);
    searchOrders(fieldName, order, pager);
  };

  const searchOrders = (sortName, order, pager, reset) => {
    setLoading(true);
    setbtnLoading(true);
    
    var orderstatus = selectedorderstatus;
    var retailer_id = selectedibo;
    var ibo_marketer_id = selectedibo;
    var sort = sortName ?? sortField;
    var sort_by = order ?? sortOrder;
    
    var page = reset === "reset" ? 1 : pager?.current ?? pagination?.current ?? 1;
    fetchData({
      results: 10,
      page: page ?? 1,
      retailer_id: retailer_id,
      status: orderstatus,
      sort: sort,
      sort_by: sort_by,
      ibo_marketer_id: ibo_marketer_id,
    });
  };

  const clearSearch = () => {
    setLoading(true);
    setselectedorderstatus(undefined);
    setselectedibo(undefined);
    setsortField(null);
    setsortOrder(null);
    setbtnLoading(false);
    fetchData({
      results: 10,
      page: 1,
    });
  };

  const onchangeorderfilter = (selectedorderstatus) => {
    setselectedorderstatus(selectedorderstatus);
  };

  const onchangeibo = (selectedibo) => {
    setselectedibo(selectedibo);
  };

  const onRowsSelectionHandler = (ids, rma_ids, selIds) => {
    console.log(ids);
    setimei(ids);
    setImeiList(rma_ids);
    setselectedImeis(rma_ids);
    setHasSelected(ids.length > 0);
  };

  const rendercanlog = (params) => {
    confirm({
      title: "Are you sure you want to change RMA Status to Shipped?",
      icon: <ExclamationCircleOutlined />,
      // content: " You  would like to  change RMA Status to Shipped?",
      onOk: () => {
        setLoading(true);

        dispatch(update_rma_order(params))
          .then((res) => {
            console.log(res.data.message);
            searchOrders()
            message.success("item shipped");
          })
          .catch((err) => {
            console.log(err);
            // message.error(err);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: "id",
      key: "id",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-text",
      width: 200,
      sorter: true,
    },
    {
      title: "IBOs / Markets",
      dataIndex: "ibo_market_name",
      key: "ibo_market_name",
      width: 150,
      sorter: true,
      hidden: inventorymanagerview === true ? false : true,
    },

    {
      key: "submitted_user_name",
      dataIndex: "submitted_user_name",
      title: "Submitted By",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
      sorter: true,
    },
    {
      key: "no_of_boxes",
      dataIndex: "no_of_boxes",
      title: "No. of boxes",
      // headerClassName: "bold-text",
      // width: 200,
      headerAlign: "center",
      align: "center",
      sorter: true,
    },
    {
      title: "No. of IMEIs",
      dataIndex: "rma_device_count",
      key: "rma_device_count",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-text",
      width: 200,
      sorter: true,
    },
    {
      title: "Approved at",
      dataIndex: "approved_at",
      key: "approved_at",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-text",
      width: 200,
      sorter: true,
    },
    {
      key: "comments",
      dataIndex: "comments",
      title: "Comments",
      headerAlign: "center",
      align: "center",
      sorter: true,
    },
    {
      key: "rma_shipping_label_url",
      title: "Shipping label",
      headerAlign: "center",
      align: "center",
      render: (text, record) => {
        // console.log(`rma_shipping_label_url`, record.rma_shipping_label_url)
        return record?.rma_shipping_label_url !== undefined &&
          record?.rma_shipping_label_url !== null &&
          record?.rma_shipping_label_url.length > 0 ? (
          <div>
            <div>
              <span>
                <Tooltip title="Download RMA shipping label">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<DownloadOutlined />}
                    onClick={(e) => {
                      window.open(
                        record?.rma_shipping_label_url ?? "",
                        "_blank"
                      );
                    }}
                  />
                </Tooltip>
              </span>
            </div>
          </div>
        ) : null;
      },
    },
    {
      key: "rma_form_url",
      dataIndex: "rma_form_url",
      title: "RMA Form",
      headerAlign: "center",
      align: "center",
      render: (text, record) => {
        return record?.rma_form_url !== undefined &&
          record?.rma_form_url !== null &&
          record?.rma_form_url.length > 0 ? (
          <div>
            <div>
              <span>
                <Tooltip title="Download RMA Form">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<DownloadOutlined />}
                    onClick={(e) => {
                      window.open(record?.rma_form_url ?? "", "_blank");
                    }}
                  />
                </Tooltip>
              </span>
            </div>
          </div>
        ) : null;
      },
    },
    {
      key: "order_status_text",
      dataIndex: "order_status_text",
      title: "RMA Status",
      headerAlign: "center",
      align: "center",
      render: (text, record) => {
        return (
          <span>
            <Chip
              label={record.order_status_text}
              sx={{
                bgcolor:
                  record.order_status_text === undefined ||
                  record.order_status_text === null
                    ? "red"
                    : record.order_status_text?.toLowerCase() === "completed"
                    ? "green"
                    : record.order_status_text?.toLowerCase() === "submitted" ||
                      record.order_status_text?.toLowerCase() === "approved"
                    ? "lightgreen"
                    : "orange",
              }}
            />
          </span>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-text",
      width: 200,
      sorter: true,
    },
    {
      title: "Action",
      key: "status_text",
      // width: 150,
      fixed: "right",
      render: (text, record_item) => (
        <div>
          <div>
            <span>
              <Tooltip title="change RMA to Shipped status">
                <Button
                  type="primary"
                  onClick={() => rendercanlog(record_item.id)}
                  disabled={record_item.status === 2 ? false : true}
                >
                  UPDATE
                </Button>
              </Tooltip>
            </span>
            {/* )} */}
          </div>
        </div>
      ),
    },
  ].filter((item) => !item.hidden);

  const showDrawer = ({ isApprove }) => {
    setIMEIOpen(true);

    setisApprove(isApprove);

    imei.map((imei_item, index) => {
      return form.setFieldsValue({
        [`imei_${index}`]: imei_item,
        [`notes_${index}`]: "",
      });
    });
  };

  const onClose = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Are you sure ?",
      content: "To cancel approving/rejecting RMA?",
      onOk: () => {
        setIMEIOpen(false);
        setImeiList([]);
        setimei([]);
        setselectedImeis([]);
        setHasSelected(false);
        resetform();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const resetform = () => {
    form.resetFields();
    imei.map((imei_item, index) => {
      return form.setFieldsValue({
        [`imei_${index}`]: imei_item,
        [`notes_${index}`]: "",
      });
      // return tempRmaList.push({ [`imei_${index}`]: imei_item, 'reason1': "", 'reason2': "", "notes": "" })
    });
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    var imei_list = [];
    console.log("imei", imei, "imeiList", imeiList);
    imeiList.map((item, index) => {
      console.log(item);
      return imei_list.push({
        rma_device_id: imeiList[`${index}`],
        comments: values[`notes_${index}`],
      });
    });
    console.log("imei_list", imei_list);
    dispatch(
      AcceptRejectRMAAction({
        rma_devices_list: imei_list,
        status: isApprove ? 2 : 3,
      })
    )
      .then((res) => {
        console.log(`response of RMA approve`, res);
        setIMEIOpen(false);
        setImeiList([]);
        setimei([]);
        setselectedImeis([]);
        setHasSelected(false);
        searchOrders()
       
      })
      .catch((err) => {
        setIMEIOpen(false);
        setImeiList([]);
        setimei([]);
        setselectedImeis([]);
        setHasSelected(false);
      });
  };

  const onFinishFailed = (values) => {
    console.log("Failed:", values);
    // setimei([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    if (newSelectedRowKeys.length === data.length && hasSelected) {
      setimei([]);
      setHasSelected(false);
      setselectedImeis([]);
      setImeiList([]);
    } else {
      const tempdata = data;
      var selRow = [];
      var rma_ids = [];
      var selIds = [];
      newSelectedRowKeys.map((selectedRow) => {
        var index = tempdata.findIndex((res) => res.id === selectedRow);
        if (index >= 0) {
          console.log("tempdata", tempdata[index]);
          selRow.push(tempdata[index].imei);
          rma_ids.push(tempdata[index].id);
          selIds.push(tempdata[index].id);
        }
        return selRow;
      });
      console.log(selRow, rma_ids);
      onRowsSelectionHandler(selRow, rma_ids, selIds);
    }
  };

  const renderibo = () => {
    return _.map(props.ibomarketers, (dep, index) => {
      // console.log(`dep`, dep)
      // dep.map((genderData) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
      // });
    });
  };

  const renderorderstatus = () => {
    return _.map(props.rmaorderstatus, (master, index) => {
      // console.log(master);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };

  const rowSelection = {
    selectedImeis,
    onChange: onSelectChange,
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var orderstatus = selectedorderstatus;
    var ibo_marketer_id = selectedibo;

    const obj = {
      status: orderstatus,
      ibo_marketer_id: ibo_marketer_id,
    };
    console.log(obj);
    dispatch(exportRmaApprovedAction(obj))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  // console.log(`props.approvedRmaDevices`, props.approvedRmaDevices);
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          {/* <div className="card"> */}
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong>RMA Status</strong> */} RMA Status{" "}
              {pagination.total && `(${pagination.total})`}
            </h4>
            <div className="d-flex align-items-center text-right">
              <div className="px-3">
                <Button
                  type="primary"
                  className="mr5"
                  loading={loadingEPbtn}
                  onClick={getexportbtn}
                >
                  <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
                </Button>
              </div>
              <div className="float-end">
                {imei.length !== 0 && (
                  <>
                    <Button
                      type="primary"
                      onClick={() => showDrawer({ isApprove: true })}
                      className="mr5"
                    >
                      Approve RMA(s)
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => showDrawer({ isApprove: false })}
                      className="mr5"
                    >
                      Reject RMA(s)
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
          <div className="col-xl-12">
            <div className="card-body">
              <div style={{ width: "100%" }}>
                <div className="row bg-white padd0">
                  {!retailerim ? null : (
                    <div className="col">
                      <label for="exampleFormControlInput1">
                        Select IBO Marketer
                      </label>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select IBO Marketer"
                        value={selectedibo}
                        onChange={onchangeibo}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value="0">Any</Option>
                        {renderibo()}
                      </Select>
                    </div>
                  )}
                  <div className="col">
                    <label for="exampleFormControlInput1">
                      Select RMA Status
                    </label>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select RMA status"
                      value={selectedorderstatus}
                      onChange={onchangeorderfilter}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {renderorderstatus()}
                    </Select>
                  </div>

                  <div className="col mt-6">
                    <Button
                      type="primary"
                      className="mr4"
                      loading={btnloading}
                      onClick={() => searchOrders("", null, null, "reset")}
                    >
                      Search
                    </Button>
                    <Button
                      type="default"
                      style={{ marginLeft: "10px" }}
                      onClick={() => clearSearch()}
                    >
                      Clear
                    </Button>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col">
                    <Table
                      columns={columns}
                      dataSource={data}
                      scroll={{ x: 1800 }}
                      pagination={pagination}
                      loading={loading}
                      onChange={handleTableChange}
                      // rowSelection={rowSelection}
                      size="middle"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        width={680}
        onClose={onClose}
        open={IMEIOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          name="basic"
          layout="vertical"
        >
          <>
            {imeiList.map((item, index) => {
              return (
                <>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name={`imei_${index}`}
                        label={`IMEI ${index + 1}`}
                        key={index}
                        value={item}
                        disabled={true}
                        rules={[
                          {
                            required: true,
                            message: "Please input imei",
                          },
                        ]}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label="Comment"
                        name={`notes_${index}`}
                        rules={[
                          {
                            required: true,
                            // pattern: new RegExp(/^[0-9]+$/),
                            message: "Please input comment",
                          },
                        ]}
                      >
                        <Input.TextArea rows={2} placeholder="Enter comment" />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            })}
          </>
          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 16,
            }}
          >
            <Space>
              {" "}
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button type="dashed" onClick={resetform}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    approvedRmaDevices: state.devicelist.approvedRmaDevices,
    auth: state.auth.auth,
    ibomarketers: state.devicelist.ibomarketers,
    rmaorderstatus: state.devicelist.rmaorderstatus,
  };
};

export default connect(mapStateToProps)(ApprovedRMA);
