import {
  getDevice,
  acceptDevice,
  formatError,
  inventoryDevice,
  transferDevice,
  scanDevice,
  pickupDevice,
  getTransaction,
  imageDevice,
  documentDevice,
  documentPostDevice,
  rmaInitiate,
  rmaPending,
  rmaSubmit,
  rmaApproved,
  rmaReasons,
  iboMarketers,
  RMADeviceSend,
  AcceptRejectRMASend,
  SubmitRMASend,
  RmaReportDevice,
  OrderApprovalStatus,
  OrderStatus,
  RmaOrderStatus,
  RmaDeviceStatus,
  AgentList,
  OfficeList,
  receiveinventoryDevice,
  transferRequest,
  receiveDeviceStatus,
  receiveDeviceStatusUpdate,
  transferRequestAccept,
  Get_other_office_list_Action,
  agent_list_for_office,
  inventoryorderlog,
  transfer_devices_to_other_office,
  agent_list,
  assigndevice,
  RMARequest,
  ApprovedRMARequest,
  rmaRequestAccept,
  RMAimageDevice,
  InventoryOrderlist,
  inventoryorder,
  Get_manager_office_list_Action,
  salessummaryaction,
  salesagentaction,
  salesofficeaction,
  exportInventoryOrder,
  exportAgentList,
  exportOfficeList,
  exportRMA,
  exportRmaApproved,
  exportRmaPending,
  exportRmaReportDevice,
  Detailsummaryaction,
} from "../../services/DeviceService";
import {
  GET_DEVICE_ACTION,
  ACCEPT_DEVICE_ACTION,
  INVENTORY_DEVICE_ACTION,
  RMA_INVENTORY_DEVICE_ACTION,
  RMA_PENDING_INVENTORY_DEVICE_ACTION,
  RMA_SUBMIT_INVENTORY_DEVICE_ACTION,
  RMA_APPROVED_INVENTORY_DEVICE_ACTION,
  TRANSFER_DEVICE_ACTION,
  PICKUP_DEVICE_ACTION,
  SCAN_DEVICE_ACTION,
  GET_DEVICE_TRANSACTION,
  GET_IMAGE_ACTION,
  DOCUMENT_GET_ACTION,
  AGENT_POST_ACTION,
  OFFICE_POST_ACTION,
  RECEIVE_INVENTORY_DEVICE_ACTION,
  GET_OFFICE_LIST,
  RMA_GET_ACTION,
  ORDER_APPROVAL_STATUS_ACTION,
  ORDER_STATUS_ACTION,
  IBO_MARKETERS_ACTION,
  RMA_ORDER_STATUS_ACTION,
  RMA_DEVICE_STATUS_ACTION,
  GET_RETAILER,
} from "./DeviceTypes";
import axiosAdmin from "../../services/AxiosAdmin";
import moment from "moment";

export function getDeviceAction() {
  return (dispatch, getState) => {
    getDevice()
      .then((response) => {
        console.log(response);
        dispatch(GetPostsAction(response.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function UploadimageAction(formData) {
  return (dispatch, getState) => {
    imageDevice(formData)
      .then((response) => {
        console.log(response);
        // dispatch(GetImageAction(response.data.data));
        formatError(response.data.message, true);
      })
      .catch((error) => {
        console.log(error);
        formatError(error, false);
      });
  };
}
export function getDeviceTransaction() {
  return (dispatch, getState) => {
    getTransaction()
      .then((response) => {
        console.log(response);
        dispatch(GetTransactionAction(response.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function acceptDeviceAction(device) {
  return (dispatch, getState) => {
    acceptDevice(device)
      .then((response) => {
        // console.log(response)
        formatError(response.data.message, true);
        dispatch(AcceptPostsAction(response.data.data));
        getDeviceAction();
      })
      .catch((error) => {
        formatError(error, false);
        console.log(error);
      });
  };
}

export function pickupDeviceAction(device) {
  return (dispatch, getState) => {
    pickupDevice(device)
      .then((response) => {
        // console.log(response)
        formatError(response.data.message, true);
        dispatch(AcceptPostsAction(response.data.data));
      })
      .catch((error) => {
        formatError(error, false);
        console.log(error);
      });
  };
}

export function scanDeviceAction(device) {
  return (dispatch, getState) => {
    scanDevice(device)
      .then((response) => {
        // console.log(response)
        formatError(response.data.message, true);
        dispatch(AcceptPostsAction(response.data.data));
      })
      .catch((error) => {
        formatError(error.response.data.error, false);
        console.log(error.response.data.error);
      });
  };
}

export function transferDeviceAction(device) {
  return (dispatch, getState) => {
    transferDevice(device)
      .then((response) => {
        // console.log(response)
        formatError(response.data.message, true);
        dispatch(TransferPostsAction(response.data.data));
      })
      .catch((error) => {
        formatError(error, false);
        console.log(error);
      });
  };
}

export function GettransferRequest(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      transferRequest(device)
        .then((response) => {
          console.log(response);
          resolve(response.data.data);
          dispatch(TransferPostsAction(response.data.data));
        })
        .catch((error) => {
          formatError(error, false);
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function GetInventoryOrderlist(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      InventoryOrderlist(device)
        .then((response) => {
          console.log(response);
          resolve(response.data);
          dispatch(TransferPostsAction(response.data.data));
        })
        .catch((error) => {
          formatError(error, false);
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportInventoryOrderlist(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      exportInventoryOrder(device)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          formatError(error, false);
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function GetRMARequest(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      RMARequest(device)
        .then((response) => {
          console.log(response);
          resolve(response.data.message);
          dispatch(TransferPostsAction(response.data.data));
        })
        .catch((error) => {
          formatError(error, false);
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function GetApprovedRMARequest(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      ApprovedRMARequest(device)
        .then((response) => {
          console.log(response);
          resolve(response);
          dispatch(TransferPostsAction(response.data.data));
        })
        .catch((error) => {
          formatError(error, false);
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function GettransferRequestAccept(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      transferRequestAccept(device)
        .then((response) => {
          formatError(response.data.message, true);
          resolve(response.data.message);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}
export function createinventoryorder(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      inventoryorder(device)
        .then((response) => {
          formatError(response.data.message, true);
          resolve(response.data.message);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function GetrmaRequestAccept(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      rmaRequestAccept(device)
        .then((response) => {
          formatError(response.data.message, true);
          resolve(response.data.message);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function inventoryDeviceAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      inventoryDevice(device)
        .then((response) => {
          console.log(response);
          resolve(response);
          dispatch(inventoryPostsAction(response.data.data));
        })
        .catch((error) => {
          formatError(error, false);
          reject(error);
          console.log(error);
        });
    });
  };
}

export function rmaInitiateAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      rmaInitiate(device)
        .then((response) => {
          console.log(response);
          resolve(response);
          dispatch(rmaInventoryPostsAction(response.data));
        })
        .catch((error) => {
          formatError(error, false);
          reject(error);
          console.log(error);
        });
    });
  };
}

export function rmaPendingAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      rmaPending(device)
        .then((response) => {
          console.log(response);
          resolve(response);
          dispatch(rmaPendingInventoryAction(response.data.data.data));
        })
        .catch((error) => {
          formatError(error, false);
          reject(error);
          console.log(error);
        });
    });
  };
}

export function exportRmaPendingAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      exportRmaPending(device)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          formatError(error, false);
          reject(error);
          console.log(error);
        });
    });
  };
}

export function rmaSubmitAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      rmaSubmit(device)
        .then((response) => {
          console.log(response);
          resolve(response);
          dispatch(rmaSubmitInventoryAction(response.data.data.data));
        })
        .catch((error) => {
          formatError(error, false);
          reject(error);
          console.log(error);
        });
    });
  };
}

export function exportrmaAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      exportRMA(device)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          formatError(error, false);
          reject(error);
          console.log(error);
        });
    });
  };
}

export function rmaApprovedAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      rmaApproved(device)
        .then((response) => {
          console.log(response);
          resolve(response);
          dispatch(rmaApprovedInventoryAction(response.data.data.data));
        })
        .catch((error) => {
          formatError(error, false);
          reject(error);
          console.log(error);
        });
    });
  };
}

export function exportRmaApprovedAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      exportRmaApproved(device)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          formatError(error, false);
          reject(error);
          console.log(error);
        });
    });
  };
}

export function GetRMAReasonsAction() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      rmaReasons()
        .then((response) => {
          //console.log(response)
          // formatError(response.data.message, true);
          resolve(response);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function Getibomarketlistnew(retailer) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      iboMarketers(retailer)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch(getIboMarketersAction(response.data));
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getIboMarketersAction(devices) {
  return {
    type: IBO_MARKETERS_ACTION,
    payload: devices,
  };
}

export function SubmitRMAAction(formData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      RMADeviceSend(formData)
        .then((response) => {
          console.log(response);
          // dispatch(GetImageAction(response.data.data));
          formatError(response.data.message, true);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}

export function AcceptRejectRMAAction(formData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      AcceptRejectRMASend(formData)
        .then((response) => {
          console.log(response);
          // dispatch(GetImageAction(response.data.data));
          formatError(response.data.message, true);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}

export function ConfirmSubmitRMAAction(formData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      SubmitRMASend(formData)
        .then((response) => {
          console.log(response);
          // dispatch(GetImageAction(response.data.data));
          formatError(response.data.message, true);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}

export function RmaReportAction(params) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      RmaReportDevice(params)
        .then((response) => {
          console.log(response);
          resolve(response.data);
          dispatch(GetRMAAction(response.data.data));
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportRmaReportAction(params) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      exportRmaReportDevice(params)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function GetRMAAction(devices) {
  return {
    type: RMA_GET_ACTION,
    payload: devices,
  };
}

export function Get_other_office_list(device) {
  return (dispatch, getState) => {
    Get_other_office_list_Action(device)
      .then((response) => {
        console.log(response);

        dispatch(Get_other_office_list_PostsAction(response.data.data));
      })
      .catch((error) => {
        formatError(error, false);
        console.log(error);
      });
  };
}

export function Get_manager_office_list(device) {
  return (dispatch, getState) => {
    Get_manager_office_list_Action(device)
      .then((response) => {
        console.log(response);

        dispatch(Get_manager_office_list_PostsAction(response.data.data));
      })
      .catch((error) => {
        formatError(error, false);
        console.log(error);
      });
  };
}

export function GetInventoryLog(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      console.log(`id`, id);
      inventoryorderlog(id)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          //formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function Get_agent_list_for_office(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      agent_list_for_office(device)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          //formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}
export function Get_agent_list() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      agent_list()
        .then((response) => {
          console.log(response);
          resolve(response?.data ?? []);
        })
        .catch((error) => {
          // formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function Get_transfer_devices_to_other_office(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      transfer_devices_to_other_office(device)
        .then((response) => {
          formatError(response.data.message, true);
          resolve(response.data.message);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function Get_assigndevice(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      assigndevice(device)
        .then((response) => {
          formatError(response.data.message, true);
          resolve(response.data.message);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function receiveinventoryDeviceAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      receiveinventoryDevice(device)
        .then((response) => {
          console.log(response);
          resolve(dispatch(receiveinventoryPostsAction(response.data.data)));
        })
        .catch((error) => {
          formatError(error, false);
          console.log(error);
          reject(error);
        });
    });
  };
}

export function receiveinventoryDeviceStatus(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      receiveDeviceStatus(device)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function receiveinventoryUpdate(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      receiveDeviceStatusUpdate(device)
        .then((response) => {
          console.log(response);
          formatError(response.data.message, true);
          resolve(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function AgentDeviceAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      AgentList(device)
        .then((response) => {
          console.log(response);
          // var agentslist = response.data.data.filter(
          //   (obj) =>
          //     obj.In_stock_count !== 0 || obj.Assigned !== 0 || obj.sold !== 0
          // );
          // const In_stock_count = agentslist.reduce(
          //   (acc, o) => acc + parseInt(o.In_stock_count),
          //   0
          // );
          // const Assigned = agentslist.reduce(
          //   (acc, o) => acc + parseInt(o.Assigned),
          //   0
          // );
          // const sold = agentslist.reduce((acc, o) => acc + parseInt(o.sold), 0);
          // var obj_arr = {
          //   In_stock_count,
          //   Assigned,
          //   sold,
          // };
          // console.log(agentslist);
          // console.log(obj_arr);
          resolve(response);
          dispatch(AgentPostsAction(response));
        })
        .catch((error) => {
          formatError(error, false);
          console.log(error);
        });
    });
  };
}

export function exportAgentDeviceAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      exportAgentList(device)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          formatError(error, false);
          console.log(error);
        });
    });
  };
}

export function OfficeDeviceAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      OfficeList(device)
        .then((response) => {
          console.log(response);
          // var agentslist = response.data.data;

          // console.log(agentslist);

          // const instock = agentslist.reduce(
          //   (acc, o) => acc + parseInt(o.instock),
          //   0
          // );
          // const manager_pending = agentslist.reduce(
          //   (acc, o) => acc + parseInt(o.manager_pending),
          //   0
          // );
          // const sold = agentslist.reduce((acc, o) => acc + parseInt(o.sold), 0);
          // var obj_arr = {
          //   instock,
          //   manager_pending,
          //   sold,
          // };
          // console.log(agentslist);
          // console.log(obj_arr);
          resolve(response);

          dispatch(OfficePostsAction(response));
        })
        .catch((error) => {
          formatError(error, false);
          console.log(error);
        });
    });
  };
}

export function exportOfficeDeviceAction(device) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      exportOfficeList(device)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          formatError(error, false);
          console.log(error);
        });
    });
  };
}
export function DocumentDeviceAction() {
  return (dispatch, getState) => {
    documentDevice()
      .then((response) => {
        //console.log(response)

        dispatch(DocumentGetAction(response.data.data));
      })
      .catch((error) => {
        formatError(error, false);
        console.log(error);
      });
  };
}

export function DocumentDevicePostAction(obj) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      documentPostDevice(obj)
        .then((response) => {
          //console.log(response)
          formatError(response.data.message, true);
          resolve(response.data.message);
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function UploadRMAAction(formData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      RMAimageDevice(formData)
        .then((response) => {
          console.log(response);
          // dispatch(GetImageAction(response.data.data));
          formatError(response.data.message, true);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}

export function SalesSummaryReport(param) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      console.log(param);
      salessummaryaction(param)
        .then((response) => {
          console.log(response);
          // dispatch(GetImageAction(response.data.data));

          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}

export function SalesSummaryAgentReport() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      salesagentaction()
        .then((response) => {
          console.log(response);
          // dispatch(GetImageAction(response.data.data));

          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}

export function SalesSummaryOfficeReport() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      salesofficeaction()
        .then((response) => {
          console.log(response);
          // dispatch(GetImageAction(response.data.data));

          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}

export function GetPostsAction(devices) {
  return {
    type: GET_DEVICE_ACTION,
    payload: devices,
  };
}
export function GetImageAction(devices) {
  return {
    type: GET_IMAGE_ACTION,
    payload: devices,
  };
}
export function GetTransactionAction(devices) {
  return {
    type: GET_DEVICE_TRANSACTION,
    payload: devices,
  };
}

export function AcceptPostsAction(devices) {
  return {
    type: ACCEPT_DEVICE_ACTION,
    payload: devices,
  };
}

export function scanPostsAction(devices) {
  return {
    type: SCAN_DEVICE_ACTION,
    payload: devices,
  };
}

export function pickupPostsAction(devices) {
  return {
    type: PICKUP_DEVICE_ACTION,
    payload: devices,
  };
}

export function TransferPostsAction(devices) {
  return {
    type: TRANSFER_DEVICE_ACTION,
    payload: devices,
  };
}

export function inventoryPostsAction(devices) {
  return {
    type: INVENTORY_DEVICE_ACTION,
    payload: devices,
  };
}

export function rmaInventoryPostsAction(devices) {
  return {
    type: RMA_INVENTORY_DEVICE_ACTION,
    payload: devices,
  };
}

export function rmaPendingInventoryAction(devices) {
  return {
    type: RMA_PENDING_INVENTORY_DEVICE_ACTION,
    payload: devices,
  };
}

export function rmaSubmitInventoryAction(devices) {
  return {
    type: RMA_SUBMIT_INVENTORY_DEVICE_ACTION,
    payload: devices,
  };
}

export function rmaApprovedInventoryAction(devices) {
  return {
    type: RMA_APPROVED_INVENTORY_DEVICE_ACTION,
    payload: devices,
  };
}

export function receiveinventoryPostsAction(devices) {
  return {
    type: RECEIVE_INVENTORY_DEVICE_ACTION,
    payload: devices,
  };
}

export function AgentPostsAction(devices) {
  return {
    type: AGENT_POST_ACTION,
    payload: devices,
  };
}

export function OrderApprovalAction() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      OrderApprovalStatus()
        .then((response) => {
          console.log(response);
          resolve(response.data.message);
          dispatch(getOrderApprovalAction(response.data));
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getOrderApprovalAction(devices) {
  return {
    type: ORDER_APPROVAL_STATUS_ACTION,
    payload: devices,
  };
}

export function OrderStatusAction() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      OrderStatus()
        .then((response) => {
          console.log(response);
          resolve(response.data.message);
          dispatch(getOrderStatusAction(response.data));
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getOrderStatusAction(devices) {
  return {
    type: ORDER_STATUS_ACTION,
    payload: devices,
  };
}

export function RmaOrderStatusAction() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      RmaOrderStatus()
        .then((response) => {
          console.log(response);
          resolve(response.data.message);
          dispatch(getRmaOrderStatusAction(response.data));
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getRmaOrderStatusAction(devices) {
  return {
    type: RMA_ORDER_STATUS_ACTION,
    payload: devices,
  };
}

export function RmaDeviceStatusAction() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      RmaDeviceStatus()
        .then((response) => {
          console.log(response);
          resolve(response.data.message);
          dispatch(getRmaDeviceStatusAction(response.data));
        })
        .catch((error) => {
          formatError(error.response.data.error, false);
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getRmaDeviceStatusAction(devices) {
  return {
    type: RMA_DEVICE_STATUS_ACTION,
    payload: devices,
  };
}

export function OfficePostsAction(devices) {
  return {
    type: OFFICE_POST_ACTION,
    payload: devices,
  };
}

export function DocumentGetAction(devices) {
  return {
    type: DOCUMENT_GET_ACTION,
    payload: devices,
  };
}

export function Get_other_office_list_PostsAction(devices) {
  return {
    type: GET_OFFICE_LIST,
    payload: devices,
  };
}
export function Get_manager_office_list_PostsAction(devices) {
  return {
    type: GET_OFFICE_LIST,
    payload: devices,
  };
}

//Agent module Actions

export function getofficelistname(params) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(`/api/v1/reference/offices/${params}`)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function sendinviteuser(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .post("/api/v1/candidate/send_invite", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function onboarding_summary_report(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.startValue) {
        var StartDate = moment(params.startValue).format("MM-DD-YYYY");
       
      }
      if (params.endValue) {
       
        var EndDate = moment(params.endValue).format("MM-DD-YYYY");
      }
      axiosAdmin
      .get(params?.url ? params?.url : `/api/v1/onboarding_summary_report?keyword=${
        params.name ?? ""
      }&office_id=${params.selectedoffice ?? ""}&status=${
        params.selectedstatus ?? ""
      }&sort=${params.sortField ?? ""}&sort_key=${
        params.sortOrder ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${
        EndDate ?? ""
      }&page=${params.page ?? 1}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function agent_summary_report(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(params?.url ? params?.url : "/api/v1/agent_summary_report")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function onboarding_status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/reference/onboarding_status_dropdown")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getagentlistname() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/userlist_based_on_role/agent")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchonboardingreportlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start) {
        var StartDate = moment(params.date_range_start
          ).format("MM-DD-YYYY");
        
      }
      if (params.date_range_end) {
       
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      var url = `/api/v1/onboarding_summary_report?keyword=${
        params.search_text ?? ""
      }&status=${params.status ?? ""}&office_id=${
        params.office_id ?? ""
      }&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${
        EndDate ?? ""
      }&page=${params.page ?? 1}&ibo_marketer_id=${params.retailer ?? ""}&onboarding_source=${
        params.source_status ?? ""
      }`;
      axiosAdmin
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportsearchonboardingreportlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start) {
        var StartDate = moment(params.date_range_start
          ).format("MM-DD-YYYY");
      
      }
      if (params.date_range_end) {
      
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }
      var url = `/api/v1/onboarding_summary_export?keyword=${
        params.search_text ?? ""
      }&status=${params.status ?? ""}&office_id=${
        params.office_id ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${
        EndDate ?? ""
      }&ibo_marketer_id=${params.retailer ?? ""}&onboarding_source=${
        params.source_status ?? ""
      }`;
      axiosAdmin
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchagentsummarylist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);

      var url = `/api/v1/agent_summary_report?keyword=${
        params.search_text ?? ""
      }&status=${params.status ?? ""}&telgoo_status=${params.telgoo_status ?? ""}&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${params.date_range_start ?? ""}&date_range_end=${
        params.date_range_end ?? ""
      }&page=${params.page ?? 1}`;

      axiosAdmin
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportsearchagentsummarylist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);

      var url = `/api/v1/agent_export?keyword=${
        params.search_text ?? ""
      }&status=${params.status ?? ""}&telgoo_status=${params.telgoo_status ?? ""}&sort=${
        params.sort ?? ""
      }&date_range_start=${params.date_range_start ?? ""}&date_range_end=${
        params.date_range_end ?? ""
      }&redirect_key=${
        params.redirect_key ?? ""
      }`;

      axiosAdmin
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_sales_report_search(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          `/api/v1/manager/manager_sales_report?sort_by=${
            params.sort_by ?? ""
          }&sort=${params.sort ?? ""}&keyword=${
            params.search_text ?? ""
          }&start_date=${params.date_range_start ?? ""}&end_date=${
            params.date_range_end ?? ""
          }&office_id=${params.status ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function resendinviteuser(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(`/api/v1/candidate/resend_invite/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function getagentstatus() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/reference/agent_status")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function inventory_dashboard() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/agent_onboarding_summary_dashboard")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function inventory_dashboard_agent_summary_count() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/agent_summary_count")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function update_rma_order(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/manager/update_rma_order_to_shipped_status/" + params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function searchagentsaleslist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      axiosAdmin
        .get(
          `/api/v1/manager/sales_by_agents?agent_id=${
            params.agent_id ?? ""
          }&page=${params.page ?? ""}&sort=${params.sort ?? ""}&sort_by=${
            params.sort_by ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function exportagentsaleslist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      axiosAdmin
        .get(
          `/api/v1/manager_sales_summary_reports_export/agent?agent_id=${
            params ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportofficesaleslist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      axiosAdmin
        .get(
          `/api/v1/manager_sales_summary_reports_export/office?office_id=${
            params ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportsalesreportlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      axiosAdmin
        .get(
          `/api/v1/detailed_sales_export?keyword=${
            params.search_text ?? ""
          }&start_date=${params.date_range_start ?? ""}&end_date=${
            params.date_range_end ?? ""
          }&office_id=${params.status ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchofficesaleslist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          `/api/v1/manager/sales_by_office?manager_id=${
            params.office_id ?? ""
          }&page=${params.page}&sort=${params.sort}&sort_by=${params.sort_by}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function manageragentslist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/manager/manager_agents_list")
        .then(function (response) {
          console.log(response.data);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}


export function searchimeilist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          `/api/v1/manager/get_device_list_to_initiate_rma_v2?keyword=${
            params.search_text ?? ""
          }&page=${params.page ?? ""
        }&carton=${params.carton ?? ""
      }&imei=${params.imei ?? ""
    }&office_id=${params.office_id ?? ""}&status=${params.status}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportImeilist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          `/api/v1/manager/device_list_to_initiate_rma_export_v2?keyword=${
            params.search_text ?? ""
          }&carton=${params.carton ?? ""
        }&imei=${params.imei ?? ""
      }&office_id=${params.office_id ?? ""}&status=${params.status}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchinventorylist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          `/api/v1/manager/managerinventorylist_optimisation?keyword=${
            params.search_text ?? ""
          }&page=${params.page ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searagentkey(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      axiosAdmin
        .get(
          `/api/v1/manager_inventory_summary_reports_export/agent?agent_id=${
            params.agent_id ?? ""
          }&page=${params.page ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searofficekey(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      axiosAdmin
        .get(
          `/api/v1/manager_inventory_summary_reports_export/office?office_id=${
            params.office_id ?? ""
          }&page=${params.page ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportMyInventoryAction(search_text) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(search_text);
      axiosAdmin
        .get(
          `/api/v1/manager/manager_inventory_list_export?keyword=${
            search_text ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportReceiveInventoryAction(search_text) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(search_text);
      axiosAdmin
        .get(`/api/v1/manager/manager_jobs_export?keyword=${search_text ?? ""}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportAssignInventoryAction(search_text) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(search_text);
      axiosAdmin
        .get(
          `/api/v1/manager/manager_inventory_list_export?keyword=${
            search_text ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportAcceptInventoryAction(search_text) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(search_text);
      axiosAdmin
        .get(
          `/api/v1/manager/accept_transfer_request_list_export?keyword=${
            search_text ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function InventoryDetailReport(param) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      console.log(param);
      Detailsummaryaction(param)
        .then((response) => {
          console.log(response);
          // dispatch(GetImageAction(response.data.data));

          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          formatError(error.response.data.error, false);
        });
    });
  };
}
export function getinventorystatus() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      //console.log(params);
      axiosAdmin
        .get("/api/v1/reference/inventory_status")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportinventorydetailreportlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      axiosAdmin
        .get(
          `/api/v1/detailed_inventory_export?keyword=${
            params.search_text ?? ""
          }&retailer_id=${params.retailer_id ?? ""}&agent_id=${
            params.agent_id ?? ""
          }&office_id=${params.office_id ?? ""}&status=${
            params.status ?? ""
          }&date_range_start=${params.date_range_start ?? ""}&date_range_end=${
            params.date_range_end ?? ""
          }&activation_date_range_start=${params.activation_date_range_start?? ""}&activation_date_range_end=${
            params.activation_date_range_end ?? ""
          }&sort=${params.sort ?? ""}&sort_by=${params.sort_by ?? ""}&page=${
            params.page ?? 1
          }&order_id=${
            params.order_id ?? ""
          }&enroll_id=${
            params.enroll_id ?? ""
          }&carton=${
            params.carton ?? ""
          }&imei=${
            params.imei ?? ""
          }&device_type=${
            params.device_type ?? ""
          }&ibo_marketer_id=${params.market ?? ""
          }&rma_order_id=${params.rmaorderid ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getapproveorder(params, obj_arr) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params, obj_arr);
      axiosAdmin
        .post(
          "/api/v1/inventory_request_order_accept_reject/" + params,
          obj_arr
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.message ?? error.response.data.error);
        });
    });
  };
}

// Get Announcement Detail

export function get_announcement(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(`/api/v1/announcements?page=${params.page ?? 1}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response);
        });
    });
  };
}

//Get Retailers

export function getretailers() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/reference/retailers")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get Employee list
export function getEmployeelist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/users?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Search Employee List
export function searchEmployeelist(url) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(url);
      axiosAdmin
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function getRolelist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/roles")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Employee Action
export function Employeeaction(id, stat) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(id);
      var obj_arr = {
        status: stat,
      };
      axiosAdmin
        .post(`/api/v1/users/action/${id}`, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Change Office User
export function changeofficeuser(val, params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(val);
      var obj_arr = {
        office_id: val.office_id,
        agent_id: params.id,
      };
      axiosAdmin
        .post(`/api/v1/change_office`, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Export User
export function user_export(search_text, rolename, status) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          `/api/v1/user_export?search_text=${search_text ?? ""}&role=${
            rolename ? rolename : ""
          }&status=${status ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function viewcomments_user_Onboarding_candidates_action(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(`/api/v1/candidate/candidate_comments_log/${params}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function viewcommentsuseraction(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(`/api/v1/agent_comments_log/${params}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function viewcommentsuserinventoryaction(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(`/api/v1/inventory_order_comments_log/${params}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function onboarding_email_status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/reference/onboarding_status_for_custom_email")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function resendinviteusernew(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(`/api/v1/candidate/send_email_remainders/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          reject(error.response.data.message);
        });
    });
  };
}
export function resendreminderuser(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(`/api/v1/candidate/resent_remainders/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          reject(error.response.data.message);
        });
    });
  };
}
export function onboardinglog(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(`/api/v1/candidate_onboarding_log/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function updatecandidateuser(obj, params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .post("/api/v1/candidate/update_candidate_info/" + params, obj)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Delete invite
export function send_invite_delete_action(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(id);
      // var obj_arr = {
      //   status: stat,
      // };
      axiosAdmin
        .delete(`/api/v1/candidate/onboarding/${id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
// export function searchonboardingreportl(val, page) {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       console.log(val, page);
//       if (val.date_range_start && val.date_range_end) {
//         var StartDate = moment(val.date_range_start).format("MM-DD-YYYY");
//         var EndDate = moment(val.date_range_end).format("MM-DD-YYYY");
//       }

//       console.log(StartDate, EndDate);
//       var url = `/api/v1/detailed_inventory_report?keyword=${
//         val.keyword ?? ""
//       }&agent_id=${val.agent_id ?? ""}&office_id=${
//         val.office_id ?? ""
//       }&status=${val.status ?? ""}&date_range_start=${
//         StartDate ?? ""
//       }&date_range_end=${EndDate ?? ""}&page=${page ?? 1}`;
//       console.log(`distributors url: ${url}`);
//       axiosAdmin
//         .get(url)
//         .then(function (response) {
//           console.log(response);
//           resolve(response);
//           dispatch({
//             payload: response.data,
//           });
//         })
//         .catch(function (error) {
//           console.log(error);
//           reject(error.response.data.error);
//         });
//     });
//   };
// }

export function rad_pii_issue_status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
     
      axiosAdmin
        .get(
          `/api/v1/rad_pii_issue_status`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function send_custom_email_user(params, agentid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .post(
          `/api/v1/candidate/send_custom_email_to_candidate/${agentid}`,
          params
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Edit Orders
export function gerteditorder(params, obj_arr) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params, obj_arr);
      axiosAdmin
        .post(
          "/api/v1/update_inventory_request_order_details/" + params,
          obj_arr
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.message ?? error.response.data.error);
        });
    });
  };
}
//SalesbyMarkets
export function detailed_sales_report_manager(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      axiosAdmin
        .get(
          `/api/v1/sales_summary_reports/manager?page=${
            params.current ?? 1
          }&manager_id=${params.manager ?? ""}&sort_by=${
            params.sort_by ?? ""
          }&sort=${params.sort ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
// sales marketer export
export function detailed_sales_export_manager(manager) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          `/api/v1/sales_summary_reports_export/manager?manager_id=${
            manager ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
// Sales IBO Markets
export function detailed_sales_report_ibo(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          `/api/v1/sales_summary_reports/ibo_marketer?page=${
            params.current ?? 1
          }&ibo_marketer_id=${params.market ?? ""}&sort_by=${
            params.sort_by ?? ""
          }&sort=${params.sort ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//IBO Market export
export function detailed_sales_export_ibo_marketer(market) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          `/api/v1/sales_summary_reports_export/ibo_marketer?ibo_marketer_id=${
            market ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
// inventory by manager
export function InventoryManager(params) {
  console.log(params);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          params?.url
            ? params?.url
            : `api/v1/inventory_summary_reports/manager?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&manager_id=${params.retailer ?? ""}&page=${params.current ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}
// inventory ibo markets
export function InventoryIbo(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          params?.url
            ? params?.url
            : `api/v1/inventory_summary_reports/ibo_marketer?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&ibo_marketer_id=${params.retailer ?? ""}&page=${
                params.current ?? 1
              }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}
//inventory manager export
export function inventory_summary_manager(val) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_summary_reports_export/manager?manager_id=${
        val?.manager_id ?? ""
      }`;
      console.log(url);
      axiosAdmin
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

//inventory ibo market export
export function inventory_summary_ibo_marketer(val) {
  return (dispatch) => {
    console.log(val)
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_summary_reports_export/ibo_marketer?ibo_marketer_id=${
        val?.retailer ?? ""
      }`;
      axiosAdmin
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Sales Office Report
export function detailed_sales_report_office_search(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          params?.url
            ? params?.url
            : `/api/v1/sales_summary_reports/office?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&office_id=${params.office ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function getdevicelogstatus(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      //console.log(params);
      axiosAdmin
        .get("/api/v1/device_log/" + params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}


export function expired_inventory_dashboard() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/expired_candidates_summary")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Legacy data Api
export function detailed_legacy_data_report(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(
          params?.url
            ? params?.url
            : "/api/v1/legacy_detailed_sales_report?page=" + params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Legacy data search
export function detailed_Legacy_data_search(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }
      if (params.date_range_start1 && params.date_range_end1) {
        var StartDate1 = moment(params.date_range_start1).format("MM-DD-YYYY");
        var EndDate1 = moment(params.date_range_end1).format("MM-DD-YYYY");
      }
      if (params.date_range_start2 && params.date_range_end2) {
        var StartDate2 = moment(params.date_range_start2).format("MM-DD-YYYY");
        var EndDate2 = moment(params.date_range_end2).format("MM-DD-YYYY");
      }
      axiosAdmin
        .get(
          params?.url
            ? params?.url
            : `/api/v1/legacy_detailed_sales_report?sort_by=${
                params.sort_by ?? ""
              }&sort=${params.sort ?? ""}&office_id=${
                params.summary ?? ""
              }&keyword=${params.search_text ?? ""}&date_range_start=${
                StartDate ?? ""
              }&date_range_end=${EndDate ?? ""}&order_date_range_start=${
                StartDate1 ?? ""
              }&order_date_range_end=${EndDate1 ?? ""}&page=${params.page ?? 1}&carton=${
                params.carton ?? ""
              }&imei=${
                params.imei ?? ""
              }&device_type=${
                params.device_type ?? ""
              }&agent_id=${params.Agent ?? ""}&status=${
                params.status.toString() ?? ""
              }&allocation_date_range_start=${
                StartDate2 ?? ""
              }&allocation_date_range_end=${
                EndDate2 ?? ""
              }&enroll_id=${
                params.enroll_id ?? ""
              }&inventory_request_order_id=${
                params.order_id ?? ""
              }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

//Legacy data export
export function detailed_legacy_data_export(
  summary,
        search_text,
        date_range_start,
        date_range_end,
        date_range_start1,
        date_range_end1,
        carton,
        imei,
        device_type,
        Agent,
        status,
        date_range_start2,
        date_range_end2,
        retailer_id,
        order_id,
        enroll_id
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (date_range_start && date_range_end) {
        var StartDate = moment(date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(date_range_end).format("MM-DD-YYYY");
      }
      if (date_range_start1 && date_range_end1) {
        var StartDate1 = moment(date_range_start1).format("MM-DD-YYYY");
        var EndDate1 = moment(date_range_end1).format("MM-DD-YYYY");
      }
      if (date_range_start2 && date_range_end2) {
        var StartDate2 = moment(date_range_start2).format("MM-DD-YYYY");
        var EndDate2 = moment(date_range_end2).format("MM-DD-YYYY");
      }
      axiosAdmin
        .get(
          `/api/v1/legacy_detailed_sales_export?summary=${summary ?? ""}&search_text=${
            search_text ?? ""
          }&date_range_start=${StartDate ?? ""}&date_range_end=${
            EndDate ?? ""
          }&order_date_range_start=${StartDate1 ?? ""}&order_date_range_end=${
            EndDate1 ?? ""
          }&carton=${
            carton ?? ""
          }&imei=${
            imei ?? ""
          }&device_type=${
            device_type ?? ""
          }&agent_id=${Agent ?? ""}&status=${
            status ?? ""
          }&allocation_date_range_start=${
            StartDate2 ?? ""
          }&allocation_date_range_end=${
            EndDate2 ?? ""
          }&inventory_request_order_id=${
            order_id ?? ""
          }&enroll_id=${
            enroll_id ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}


 export function getofficelegacylistname() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/reference/offices")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getinventorylegacystatus() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      //console.log(params);
      axiosAdmin
        .get("/api/v1/reference/inventory_status")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Leagacy Data device type
export function getdevicetype() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get(`/api/v1/reference/device_type`)
        .then(function (response) {
          console.log(response);
          resolve(response);
         
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getstatuslist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosAdmin
        .get("/api/v1/reference/inventory_status")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
});
};
}
// Re-Submit order
export function resubmit_request_order(params, obj_arr) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params, obj_arr);
      axiosAdmin
        .post(
          "/api/v1/resubmit_inventory_request_order/" + params,
          obj_arr
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.message ?? error.response.data.error);
        });
});
};
}