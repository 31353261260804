import React, { useState, useEffect } from "react";
import {
  RmaReportAction,
  RmaDeviceStatusAction,
  exportRmaReportAction,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";
import { UploadOutlined } from "@ant-design/icons";
import _ from "lodash";
import { Tooltip, Select, Button, Table, Modal, message } from "antd";
const { Option } = Select;

const AllRMAReport = (props) => {
  // const [pagesize, setpagesize] = useState(10);
  const [sortField, setsortField] = useState(null);
  const [sortOrder, setsortOrder] = useState(null);
  const [selectedorderstatus, setselectedorderstatus] = useState(null);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingbtn, setLoadingbtn] = useState(false);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(RmaDeviceStatusAction());
    fetchData();
  }, []);

  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(RmaReportAction(getRandomuserParams(params)))
      .then((res) => {
        console.log(res);
        const paginate = { ...pagination };
        paginate.current = res.data.current_page
        paginate.total =
          res.data !== undefined && res.data !== null
            ? res.data.total !== undefined && res.data.total !== null
              ? res.data.total
              : res.data?.length
            : 10;
        setData(res.data.data);
        setLoading(false);
        setLoadingbtn(false);
        console.log(`paginate`, paginate);
        setPagination(paginate);
      })
      .catch((err) => {
        setLoading(false);
        setLoadingbtn(false);
      });
  };

  const handleTableChange = (paginate, filter, sorter) => {
    const pager = { ...pagination };
    pager.current = paginate.current;
    setPagination(pager);
    const order =
      sorter.order === "ascend"
        ? "ASC"
        : sorter.order === undefined
        ? ""
        : "DESC";
    const fieldName = sorter.order === undefined ? "" : sorter.field;
    setPagination(pager);
    setsortField(fieldName);
    setsortOrder(order);
    searchOrders(fieldName, order, pager);
  };

  const searchOrders = (sortName, order, pager, reset) => {
    setLoading(true);
    setLoadingbtn(true);
    
    var orderstatus = selectedorderstatus;
   
    var sort = sortName ?? sortField;
    var sort_by = order ?? sortOrder;
   
    var page = reset === "reset" ? 1 : pager?.current ?? pagination?.current ?? 1;
    fetchData({
      status: orderstatus,
      sort: sort,
      sort_by: sort_by,
      page: page ?? 1
    });
    // fetchData({
    //   results: 10,
    //   page: page,
    //   // retailer_id: retailer_id,
    //   // approval_status: approvalstatus,
    //   status: orderstatus,
    //   // search_text: search_text,
    //   sort: sort,
    //   sort_by: sort_by,
    // });
  };

  const clearSearch = () => {
    // setLoading(true);
    setselectedorderstatus(undefined);
    setsortField(null);
    setsortOrder(null);
    searchOrders("", "", "", true);
  };

  const onchangeorderfilter = (selectedorderstatus) => {
    setselectedorderstatus(selectedorderstatus);
  };

  const renderorderstatus = () => {
    return _.map(props.rmadevicestatus, (master, index) => {
      // console.log(master);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };

  const columns = [
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-text",
      width: 200,
      sorter: true,
    },
    {
      dataIndex: "manager_name",
      key: "manager_name",
      title: "manager name",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
      sorter: true,
    },
    {
      dataIndex: "office_number",
      key: "office_number",
      title: "office number",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
      sorter: true,
    },
    {
      dataIndex: "status_text",
      key: "status_text",
      title: "status",
      width: 200,
      sortable: false,
      headerAlign: "center",
      headerClassName: "bold-text",
      align: "center",
      render: (text, record) => {
        return <Chip label={record.status_text} color="primary" />;
      },
    },
    {
      dataIndex: "reason_text",
      key: "reason_text",
      title: "Reason 1",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      dataIndex: "reason_two_text",
      key: "reason_two_text",
      title: "Reason 2",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      dataIndex: "notes",
      key: "notes",
      title: "Comments",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
      sorter: true,
    },
    {
      dataIndex: "created_date",
      key: "created_date",
      title: "created date",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
      sorter: true,
    },

    // {
    //   field: "imei",
    //   headerName: "IMEI",
    //   headerClassName: "bold-text",
    //   width: 200,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "manager_name",
    //   headerName: "manager name",
    //   headerClassName: "bold-text",
    //   width: 200,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "office_number",
    //   headerName: "office number",
    //   headerClassName: "bold-text",
    //   width: 200,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "status_text",
    //   headerName: "status",
    //   width: 200,
    //   sortable: false,
    //   headerAlign: "center",
    //   headerClassName: "bold-text",
    //   align: "center",
    //   renderCell: (params) => {
    //     return (
    //       <span>
    //         <Chip label={params.row.status_text} color="primary" />
    //       </span>
    //     );
    //   },
    // },
    // {
    //   field: "reason_text",
    //   headerName: "Reason 1",
    //   headerClassName: "bold-text",
    //   width: 200,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "reason_two_text",
    //   headerName: "Reason 2",
    //   headerClassName: "bold-text",
    //   width: 200,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "notes",
    //   headerName: "Comments",
    //   headerClassName: "bold-text",
    //   width: 200,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "created_date",
    //   headerName: "created date",
    //   headerClassName: "bold-text",
    //   width: 200,
    //   headerAlign: "center",
    //   align: "center",
    // },
  ];

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var orderstatus = selectedorderstatus;
    const obj = {
      status: orderstatus,
    };
    console.log(obj);
    dispatch(exportRmaReportAction(obj))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        console.log("export resp", res);
        if (res.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };

  return (
    <div className="card">
      <div className="row">
        <div className="col-xl-12">
          {/* <div className="card"> */}
          <div className="card-header">
            <h4 className="card-title">
              <strong>RMA Status</strong>
            </h4>
            <div className="px-3">
              <Button
                type="primary"
                className="mr5"
                loading={loadingEPbtn}
                onClick={getexportbtn}
              >
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="card-body">
          <div style={{ width: "100%" }}>
            <div className="row bg-white padd0">
              <div className="col">
                <label for="exampleFormControlInput1">Select RMA status</label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select RMA status"
                  value={selectedorderstatus}
                  onChange={onchangeorderfilter}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderorderstatus()}
                </Select>
              </div>
              <div className="col mt-4">
                <Button
                  type="primary"
                  className="mr5"
                  loading={loadingbtn}
                  onClick={() => searchOrders("", null, null, "reset")}
                >
                  Search
                </Button>
                <Button type="default" onClick={() => clearSearch()}>
                  Clear
                </Button>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-xl-12">
              {/* <div className="card-body"> */}

              <Table
                columns={columns}
                dataSource={data}
                scroll={{ x: true }}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                // rowSelection={rowSelection}
                // size="small"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    device: state.devicelist.rmareport,
    rmadevicestatus: state.devicelist.rmadevicestatus,
  };
};

export default connect(mapStateToProps)(AllRMAReport);
